<template>

  <div class="bcg" style="height: 100vh">

    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    <PopUpTableForLeagues_Mobile ref="PopUpTableLeagues" :key="componentKey"></PopUpTableForLeagues_Mobile>

    <!--   DOLE SU DESKTOP/MOBILE VERZIJE   -->

    <div v-if="isMobile" class="MOBILE">

      <b-row style="margin-bottom: -10px">
        <b-col sm="12">
          <Navbar :key="componentKeyN"></Navbar>
        </b-col>
      </b-row>

      <div class="navbar1 mt-1">

        <div class="btn-group container-fluid" role="group" aria-label="Basic example">
          <button class="btn  btn-sm" @click="pushOnHome">
            <div>
              <a class="navbar-brand hand">
                <img class="nav1" src="../assets/ball.svg"></a>
            </div>
            <div>
              <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">SPORT</a>
            </div>
          </button>
          <button class="btn  btn-sm" @click="pushOnLiveBets">
            <div>
              <a class="navbar-brand hand">
                <img class="nav1" src="../assets/live.svg"></a>
            </div>
            <div>
              <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">LIVE</a>
            </div>
          </button>
          <button class="btn  btn-sm" @click="goToCasinoPage()">
            <div>
              <a class="navbar-brand hand">
                <img class="nav1" src="../assets/casino.svg"></a>
            </div>
            <div>
              <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">CASINO</a>
            </div>
          </button>
        </div>

      </div>

      <div class="row table-wrapper-scroll-y my-custom-scrollbar">
        <div class="col-12">
          <div style="margin-bottom: 200px">
            <div class="btn-group" role="group" aria-label="Basic example">
              <div class="row">
                <div class="col text-center">

                  <button class="btn " @click="pushOnHome">
                    <img src="../assets/banner.png" style="max-width: 95vw;">
<!--                    <h5 style="color: white; background-color: red;">Das Wetten ist derzeit aufgrund von Wartungsarbeiten deaktiviert</h5>-->
                  </button>

                </div>
              </div>
            </div>

            <div class="btn-group" role="group" aria-label="Basic example" style="">

              <div class="row">

                <div class="d-inline">

                  <button class="btn" @click="pushOnHome">
                    <img src="../assets/sportcover.png" style="max-width: 43vw">
                  </button>

                </div>
                <div class="d-inline">

                  <button class="btn" @click="pushOnLiveBets">
                    <img src="../assets/livecover.png" style="max-width: 43vw">
                  </button>

                </div>

              </div>

            </div>

            <div class="btn-group" role="group" aria-label="Basic example">
              <div class="row">
                <div class="col text-center">

                  <button class="btn " @click="goToCasinoPage()">
                    <img src="../assets/casinocover.png" style="max-width: 95vw;">
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="ticketsCount()>0"
           class="navbar fixed-bottom float-left ap page-hero d-flex align-items-center justify-content-center">
        <div class="text-center p-3 " style="background-color: rgba(0, 0, 0, 0.6); border-radius: 7px;">
          <div class="btn-group" role="group" aria-label="Basic example" :key="componentKey3">
            <button v-if="ticketsCount()>0" type="button" class="btn btn-sm btn-dark"
                    style="margin-left: 2px; margin-right: 2px" @click="pushOnTicketMaker">
              Betting
              <button type="button" class="btn btn-sm "
                      style="background-color: #f5cf08; font-weight: bold; border-radius: 50%; font-size: 0.6rem">
                {{ ticketsCount() }}
              </button>
            </button>
          </div>
        </div>
      </div>


    </div>


    <!--   DOLE JE DESKTOP VERZIJA GORE JE MOBILE VERZIJA   -->

    <div v-else class="DESKTOP" style="margin-left: 12%; margin-right: 12%">

      <div class="navbar2" style="margin-bottom: -35px">

        <b-row>
          <b-col sm="12">
            <Navbar_Desktop :key="componentKeyN"></Navbar_Desktop>
          </b-col>
        </b-row>


        <!--        <b-row class="mt-4 betTypes ap page-hero d-flex align-items-center justify-content-center">-->
        <!--          <b-col sm="4">-->
        <!--            <label style="font-size: 110%;  color: black; font-weight: bold" @click="pushOnHome">Sports Betting</label>-->
        <!--          </b-col>-->
        <!--          <b-col sm="4">-->
        <!--            <label style="font-size: 110%; color: black; font-weight: bold" @click="pushOnLiveBets">Live Bets</label>-->
        <!--          </b-col>-->
        <!--        </b-row>-->


        <b-row class="mt-4 sports ap page-hero d-flex align-items-center justify-content-center sports-navbar">
          <b-col sm="6">
            <a class="navbar-brand hand" @click="loadLeagues('Football')"><img src="../assets/footballrs.png"
                                                                               class="image"></a>
            <label @click="loadLeagues('Football')"
                   style="font-size: 80%; color: white; margin-right: 20px; margin-left: -10px"><strong>Football</strong></label>

            <a class="navbar-brand hand" @click="loadLeagues('Basketball')"><img src="../assets/basketball.svg"
                                                                                 class="image"></a>
            <label @click="loadLeagues('Basketball')"
                   style="font-size: 80%; color: white; margin-right: 20px; margin-left: -10px"><strong>Basketball</strong></label>

            <a class="navbar-brand hand"><img src="../assets/tennisrs.png" class="image"></a>
            <label style="font-size: 80%; color: white; margin-right: 20px; margin-left: -10px"><strong>Tennis</strong></label>

            <a class="navbar-brand hand"><img src="../assets/volleyballrs.png" class="image"></a>
            <label style="font-size: 80%; color: white; margin-left: -10px"><strong>Volleyball</strong></label>
          </b-col>
          <!--          <b-col sm="2">-->
          <!--            <a class="navbar-brand hand" @click="loadLeagues('Basketball')"><img src="../assets/basketballrs.png" class="image"></a>-->
          <!--            <label @click="loadLeagues('Basketball')" style="font-size: 100%; color: white"><strong>Basketball</strong></label>-->
          <!--          </b-col>-->
          <!--          <b-col sm="2"-->
          <!--                 @click="showMsg('Notice','We currently do not have support for Volleyball, please try again later.')">-->
          <!--            <a class="navbar-brand hand"><img src="../assets/tennisrs.png" class="image"></a>-->
          <!--            <label style="font-size: 100%; color: white"><strong>Tennis</strong></label>-->
          <!--          </b-col>-->
          <!--          <b-col sm="2"-->
          <!--                 @click="showMsg('Notice','We currently do not have support for Volleyball, please try again later.')">-->
          <!--            <a class="navbar-brand hand"><img src="../assets/volleyballrs.png" class="image"></a>-->
          <!--            <label style="font-size: 100%; color: white"><strong>Volleyball</strong></label>-->
          <!--          </b-col>-->
          <!--          <b-col sm="2"-->
          <!--                 @click="showMsg('Notice','We currently do not have support for Ice Hockey, please try again later.')">-->
          <!--            <a class="navbar-brand hand"><img src="../assets/hockeyrs.png" class="image"></a>-->
          <!--            <label style="font-size: 100%; color: white"><strong>Ice Hockey</strong></label>-->
          <!--          </b-col>-->
        </b-row>
      </div>

      <b-row class="mt-5">
        <b-col sm="2">
          <LeaguesTable_Desktop :key="componentKey"></LeaguesTable_Desktop>
        </b-col>
        <b-col sm="7">
          <b-row class="mt-1">
            <b-col sm="12">
              <div style="background-color: yellow; border-color: white; border-radius: 2px;height: 250px">
                <h1 style="height: available; width: max-content">SLIKA 1</h1>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col sm="12">
              <LiveBetsTable_Desktop :key="componentKey2" style="height: 250px"></LiveBetsTable_Desktop>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col sm="12">
              <FutureBetsTable_Desktop :key="componentKey3"></FutureBetsTable_Desktop>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="3">
          <TicketTable_Desktop :key="componentKey1"></TicketTable_Desktop>
          <!--          <div v-else>-->
          <!--            <div class="transparent ap page-hero d-flex align-items-center justify-content-center">-->
          <!--              <h3>There are no tickets yet</h3>-->
          <!--            </div>-->
          <!--          </div>-->
          <div style="background-color: yellow; border-color: white; border-radius: 2px;height: 300px;margin-top: 10%">
            <h1>SLIKA 2</h1>
          </div>
        </b-col>
      </b-row>


    </div>


  </div>


</template>

<script>
import Navbar from "@/components/Navbar";
import LeaguesTable_Desktop from "@/components/LeaguesTable_Desktop";
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import router from "@/router/router";
import PopUpTableForLeagues_Mobile from "@/components/PopUpTableForLeagues_Mobile";
import LiveBetsTable_Desktop from "@/components/LiveBetsTable_Desktop";
import FutureBetsTable_Desktop from "@/components/FutureBetsTable_Desktop";
import TicketTable_Desktop from "@/components/TicketTable_Desktop";
import Navbar_Desktop from "@/components/Navbar_Desktop";

export default {
  name: "Welcome",
  components: {
    Navbar_Desktop,
    TicketTable_Desktop,
    FutureBetsTable_Desktop,
    LiveBetsTable_Desktop,
    Navbar, LeaguesTable_Desktop, ConfirmDialogue, PopUpTableForLeagues_Mobile
  },

  data() {
    return {
      leagues: [],
      componentKeyN: 0,
      componentKey: 0,
      componentKey1: 0,
      componentKey2: 0,
      componentKey3: 0,
      timer: '',
      isMobile: false,
      player: JSON.parse(localStorage.getItem('player'))
    }
  },

  // created() {
  //   this.timer = setInterval(this.forceUpdate, 5000);
  // },

  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted() {

    //this.clearAll()

    this.onResize()
    this.once()
    global.addEventListener('resize', this.onResize, {passive: true})

    this.$root.$on('updateNavbar', data => {
      this.componentKeyN += data;
    });

    this.$root.$on('updateLeagues', data => {
      this.componentKey += data;
    });

    this.$root.$on('updateTickets', data => {
      this.componentKey1 += data;
    });


    this.$root.$on('updateLiveTable', data => {
      this.componentKey2 += data;
    });


    this.$root.$on('updatePreMatchTable', data => {
      this.componentKey3 += data;
    });

    this.$root.$on('updateTickets', data => {
      this.componentKey2 += data;
      this.componentKey3 += data;
    });

    this.$root.$on('componentKeyChanged', data => {
      this.componentKey2 += data;
      this.componentKey3 += data;
    });

  },


  methods: {

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    once() {
      if (!this.isMobile) router.push('/home')
    },

    //...mapActions({'loadLeagues'}),

    async showMsg(title, msg) {


      await this.$refs.confirmDialogue.showNotice({
        title: title,
        message: msg,
        okButton2: 'Ok',
      })

    },

    clearAll: function () {

      let state = this.$store.state;
      let newState = {};

      Object.keys(state).forEach(key => {
        newState[key] = '';
      });

      this.$store.replaceState(newState);
      localStorage.clear();
    },

    ticketsCount: function () {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        return clickedBets.length
      }

      return 0
    },

    goToCasinoPage() {
      if(JSON.parse(localStorage.getItem('player')) != null && JSON.parse(localStorage.getItem('player')) != undefined){
        let p = JSON.parse(localStorage.getItem('player'));
        let pw = p.password;
        let password = pw.replaceAll('.','');
        location.href = 'http://casino.loca.bet/autologin/' + password.substr(8, 14) + '---' + p.username;
      }else{
        location.href = 'http://casino.loca.bet';
      }
    },


    async pushOnLiveBets() {

      if (!this.$route.fullPath.includes('LiveBetsView'))
        await router.push('/LiveBetsView')

    },

    async pushOnHome() {

      if (!this.$route.fullPath.includes('home'))
        await router.push('/home')

    },


    showTickets: async function () {

      if (this.player != null) {

        this.$axios.post('getTickets', {
          parentId: this.player.id
        }).then(response => {

          localStorage.setItem('playerTickets', JSON.stringify(response.data))


          this.pushOnTicketView()
          this.$root.$emit('updateNavbar', 1);

        }).catch(error => {
          console.log(error);
        })

      }

    },

    pushOnTicketMaker: async function () {

      if (!this.$route.fullPath.includes('TicketMakerView'))
        await router.push('/TicketMakerView')

    },

    async pushOnTicketView() {

      if (!this.$route.fullPath.includes('PlayerTicketsView'))
        await router.push('/PlayerTicketsView')

    },

    async loadLeaguesToggle(name) {

      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {

        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.$root.$emit('toggleModalLeagues', 1);

      }).catch(error => {
        console.log(error);
      })
    },

    async loadLeagues(name) {

      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {

        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.componentKey += 1;
      }).catch(error => {
        console.log(error);
      })
    },

    loadEvents: function (id) {
      this.$axios.post('getEvents', {
        parentId: id
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))
        // this.eventss = response.data;
      }).catch(error => {
        console.log(error);
      })
    },


  }

}
</script>

<style scoped>

.betTypes {
  /*background-image: linear-gradient(to bottom,#eb5757, #b82e1f);*/
}

.sports {
  /*background-image: linear-gradient(to bottom,#eb5757, #b82e1f);*/
  border-top: 1px solid black;
}

.navbar2 {
  background-color: transparent;
}

.navbar1 {
  background-image: linear-gradient(to bottom, #00102e, #04619f);
}

.navbar2 {
  background-color: #00102e;
}

.transparent {
  background-color: rgba(0, 0, 0, 0.5);
  height: 36vh;
  width: 35.5vh;
  color: lightgrey;
}

label:hover,
label:focus {
  box-shadow: 0 0.5em 0.5em -0.4em black;
  transform: translateY(-0.25em);
}

.fixed-bottom {
  font-size: 0.8rem;
}

.icon {
  background-size: cover;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.home {
  background-image: url(../assets/homebg.png);
}

.live {
  background-image: url(../assets/livebg.png);
}

.oneTicket {
  background-image: url(../assets/oneTicketbg.png);
}

.twoTickets {
  background-image: url(../assets/twoTicketsBg.png);
}

.scrolling-wrapper {
  overflow-x: auto;
}

img {
  /*width: 21px;*/
  /*height: 21px*/
}

.image {
  width: 25px;
  height: 25px;
}

.uppers {
  width: 17px;
  height: 17px
}

.nav1 {
  width: 25px;
  height: 25px;
}

.bcg {
  background-image: url(../assets/backgroundDr.jpg);;
}

.sports-navbar {
  /*background-color: lightblue; !*#07112d*!*/
  background-image: linear-gradient(to bottom, #00102e, #04619f);
  border-radius: 2px;
}

.my-custom-scrollbar {
  position: relative;
  height: 100vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}


</style>
