<template>

  <div class="modal" id="myModalForLeagues">

    <div class="modal-dialog ">
      <div class="modal-content">

        <!-- Modal body -->
        <div class="modal-body ">

          <div class="table-responsive mb-2 text-right" scope="col" style="margin-bottom: 0px">
<!--            <table class="table table-sm table-hover table-striped table-dark">-->
<!--              <thead>-->
<!--              <tr class="firstrow">-->
<!--                <th scope="col" class="mb-2 text-right">-->
                  <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal">X</button>
<!--                </th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--            </table>-->

          </div>

          <div class="row table-wrapper-scroll-y my-custom-scrollbar">
            <div class="col-12">

              <table class="table table-sm table-hover" style="background-color: #001523; color: white ">
                <thead>
                <tr class="firstrow " style="color: white">
                  <th scope="col" class="text-left" >{{$t("Leagues")}}</th>
                  <th class="mb-2 text-right">
                  </th>
                </tr>
                </thead>
                <tbody v-if="this.leagues!=null && this.leagues!=undefined && this.leagues.length>0">
                <tr v-for="league in this.leagues" :key="league.id" @click="loadEvents(league.id, league.name)"
                    style="border: 1px solid powderblue">
                  <div v-if="league.id !== 1" class="text-left ml-2" data-dismiss="modal"
                       style="margin-top: 14px; margin-bottom: 14px; font-size: 0.8rem;"><img :src="loadImg(league)" style="width: 18px; height: 12px">
                    {{ league.name }}
                  </div>
                  <div v-else class="text-left ml-2" data-dismiss="modal"
                       style="margin-top: 14px; margin-bottom: 14px; font-size: 0.8rem"><img :src="loadImg(league)" style="width: 18px; height: 12px"><a><b> {{ $t(league.name) }} </b></a>

                  </div>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <div class="row ap page-hero d-flex align-items-center justify-content-center" data-dismiss="modal"
                       style="margin-top: 14px; margin-bottom: 14px; font-size: 0.8rem">
                    There are currently no leagues for this sport
                  </div>
                </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>

      </div>
    </div>

  </div>

</template>

<script>
import router from "@/router/router";

export default {


  name: "PopUpTableForLeagues_Mobile",
  components: {},
  data() {
    return {
      leagues: '',
    }
  },


  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted() {
    this.isVisible = true
    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})
  },

  methods: {

    leaguesExhist: function () {
      var lgs = JSON.parse(localStorage.getItem('leagues'))
      if (lgs != null && lgs != undefined && lgs.length > 0)
        return true
      return false
    },

    loadImg(league) {
      const leagues = ["Sweden","Albania", "Italy", "Germany", "Austria", "England", "Spain", "France", "Switzerland", "Portugal", "Poland", "Serbia", "Slovenia", "Belgium",
        "Netherlands", "Denmark", "Russia", "Ukraine", "Turkey", "Greece", "Argentina", "Brazil", "Japan", "United States",
        "Bosnia-Herz", "Bulgaria", "Canada", "Chile", "China", "Colombia", "Croatia", "Cyprus", "Czech Republic", "Ecuador", "Egypt",
        "Estonia", "Finland", "Georgia", "Mexico", "Hungary", "Iceland", "India", "International", "Iran", "Iraq", "Ireland", "Israel", "Kuwait", "Lithuania", "Latvia", "Luxembourg","Macedonia",
        "Malta", "Moldova", "Montenegro", "Morocco", "New Zealand", "Norway","Peru", "Philippines", "Qatar", "Romania", "Saudi Arabia", "Scotland", "Slovakia", "South Africa", "South " +
        "Korea", "UAE", "Uruguay", "Wales", "Belarus", "Australia", "Algeria", "Bahrain", "Botswana", "Ecuador", "El Salvador", "Gambia", "Guatemala", "Indonesia", "Kazakhstan",
        "Malaysia", "Thailand", "Tunisia", "Uganda", "Venezuela"]
      const specialLeagues = ["UEFA Europa League","UEFA Champions League",  "UEFA Conference League", "24H"];
      if (specialLeagues.includes(league.name)) {
        return require('../assets/flags/' + league.name + ".png") || null;
      } else if (leagues.includes(league.country)) {
        return require('../assets/flags/' + league.country + ".png") || null;
      }
    },

    formatTxt: function (txt) {

      if (txt.length > 15) {
        var s = txt;

        var middle = Math.floor(s.length / 2);
        var before = s.lastIndexOf(' ', middle);
        var after = s.indexOf(' ', middle + 1);

        if (middle - before < after - middle) {
          middle = before;
        } else {
          middle = after;
        }

        var s1 = s.substr(0, middle);
        var s2 = s.substr(middle + 1);
        var arr = [s1, s2];
        return arr
      }

      return txt

    },


    startLoading() {
      this.intervalThread = setInterval(() => {
        localStorage.setItem('dummy' + Math.random(), "[]");
      }, 450);
    },

    closeLoading() {
      Object.keys(localStorage).forEach(function (key) {
        if (key.includes('dummy')) {
          localStorage.removeItem(key)
        }
      });
      clearInterval(this.intervalThread);
    },


    loadEvents: function (leagueId, league) {
      localStorage.setItem('leagueId', leagueId)
      localStorage.setItem('currentLeague', league)
      this.startLoading();
      this.$axios.post('getEvents', {
        parentId: leagueId
      }).then(response => {
        localStorage.setItem('currentSport', response.data[0].league.sport.name);
        localStorage.setItem('events', JSON.stringify(response.data))

        Object.keys(localStorage).forEach(function (key) {
          if (key.includes('markets') && /\d/.test(key)) {
            localStorage.removeItem(key)
          }
        });

        for (let i = 0; i < response.data.length; i++) {
          let str1 = 'markets';
          let str2 = response.data[i].id;
          let combo = str1.concat(str2);

          localStorage.setItem(combo, JSON.stringify(response.data[i].marketHelpers))


        }

        const data = 1;
        this.$root.$emit('updateLiveTable', data);
        this.$root.$emit('updatePreMatchTable', data);

        this.$delete

        this.closeLoading();
      }).catch(error => {
        console.log(error);
      })
      router.push("/home");
    },

    loadMarkets: function (eventId) {
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);

        localStorage.setItem(combo, JSON.stringify(response.data))

        const data = 1;
        this.$root.$emit('updateLiveTable', data);
        this.$root.$emit('updatePreMatchTable', data);

        this.$delete

      }).catch(error => {
        console.log(error);
      })
    },

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    selected: function (betId) {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId))
          return true
        else return false
      }

      return false
    },

    clickBet(eventId, marketId, betId, home, away, marketName, betName, betOdd) {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))
      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId)) {
          this.$root.$emit('remotelyDeleteBetPop', eventId);
          this.$forceUpdate()
          return;
        }
      }

      this.$root.$emit('clickBetPop', {eventId, marketId, betId, home, away, marketName, betName, betOdd});
    },

  },

  created() {
    this.$root.$on('toggleModalLeagues', obj => {
      if (obj == 1) {
        this.leagues = JSON.parse(localStorage.getItem('leagues'))
      } else {
        this.leagues = JSON.parse(localStorage.getItem('leagues'))
      }
    });

  },
}
</script>

<style scoped>

.oneMarket {
  margin: 6px;
}

.modal {
  text-align: center;
}

.modal-content {
  background: url(../assets/secondbg.jpg) center center no-repeat;
}

.modal-dialog {
  text-align: left; /* you'll likely want this */
  min-width: 95%;
  /*width: auto !important;*/
  display: inline-block;
}

.my-custom-scrollbar {
  position: relative;
  height: 90vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-color: #111e26;
}

html, body {
  overflow-x: auto;
  overflow-y: auto;
}

</style>