<template>

  <div class="modal" id="myModal" :key="componentKey">

    <FloatingAlert language="en"/>

    <div class="modal-dialogSm">
      <div class="modal-content" v-if="isVisible">

        <div class="modal-header" style="border: none">

          <div>

            <div class="table-responsive" style="margin-bottom: -30px">
              <table class="table table-sm table-hover table-striped table-dark" style="background-color: #00102e">
                <thead>
                <tr class="firstrow" style="background-color: #00102e">
                  <th scope="col"></th>
                  <th scope="col" class="mb-2 text-right">
                    <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal" @click="removeEventInOtherTables()">X</button>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td style="margin-right: 10px; width: 50vw">

                    <button class="btn  btn-sm" style="margin-left: 10px;">
                      <div style=" border-radius: 8px; margin-bottom: 4px;  font-size: 0.6rem; color: #cfb640">
                        {{ getTime(event.startTime) }}
                      </div>
                      <div style=" border-radius: 8px; margin-bottom: 4px; color: white;font-size: 0.8rem;">
                        {{ event.home }}
                      </div>
                      <div style=" border-radius: 8px; margin-bottom: 4px; color: white;font-size: 0.8rem;">
                        {{ event.away }}
                      </div>
                      <div style=" border-radius: 8px; margin-bottom: 4px; color: limegreen;font-size: 0.8rem;">
                        {{ homeResult(event.result) }} - {{ awayResult(event.result) }}
                      </div>
                    </button>

                  </td>


                  <td v-if="currentMarkets[0]!=null && currentMarkets[0]!=undefined">
                    <div class="row oneMarket ap page-hero d-flex align-items-center justify-content-center"
                         style=" width: 50vw">

                      <div v-for="bet in currentMarkets[0].bets" :key="bet.id" class="d-inline"
                           style="margin: 2px; height: 100px;">

                        <div v-if="bet.status !== 1" style="height: 100%">

                          <button
                              class="btn btn-sm h-100 d-inline-block disabled"
                              style="margin-right: 2px; height: 100%; background-color: #d0e9ff ; border: 2px solid #38596f">

                            <div style="font-size: 0.7rem; color: black">{{ bet.name }}</div>
                            <div style="font-size: 0.7rem; color: black; font-weight: bold"><img src="../assets/kvoteZ.svg" style="width: 20px; height: 20px"></div>

                          </button>

                        </div>

                        <div v-else style="height: 100%">

                          <button v-if="selected(bet.id)"
                                  @click="clickBet(event.id, currentMarkets[0].id, bet.id, event.home, event.away, currentMarkets[0].name, bet.name, bet.odd)"
                                  class="btn btn-sm h-100 d-inline-block"
                                  style="margin-right: 2px; height: 100%; background-color: #f5cf08">

                            <div style="font-size: 0.7rem; color: black">{{ bet.name }}</div>
                            <div style="font-size: 0.7rem; color: black; font-weight: bold">{{ bet.odd }}</div>

                          </button>
                          <button v-else
                                  @click="clickBet(event.id, currentMarkets[0].id, bet.id, event.home, event.away, currentMarkets[0].name, bet.name, bet.odd)"
                                  class="btn btn-sm h-100 d-inline-block"
                                  style="margin-right: 2px; height: 100%; background-color: #d0e9ff ; border: 2px solid #38596f">

                            <div style="font-size: 0.7rem; color: black">{{ bet.name }}</div>
                            <div style="font-size: 0.7rem; color: black; font-weight: bold">{{ bet.odd }}</div>

                          </button>

                        </div>

                      </div>

                    </div>
                  </td>


                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

        <!-- Modal body -->
        <div class="modal-body">

          <div class="row table-wrapper-scroll-y my-custom-scrollbarSm">
            <div class="col-12">

              <table class="table table-sm table-hover table-striped"
                     style=" font-size: 0.7rem; background-color: #222325; margin-bottom: 20vh">
                <thead>
                <tr class="firstrow" style="color: black">
                  <th scope="col" class="text-left">{{ $t('otherMarkets') }}</th>
                </tr>
                </thead>
                <tbody v-if="currentMarkets!=null && currentMarkets!=undefined">
                <tr v-for="market in currentMarkets" :key="market.id">

                  <td v-if="market.name != currentMarkets[0].name" class="align-middle">

                    <div v-if="find(market.name)" class="row "
                         style=" border-radius: 8px; margin: 4px; color: white">
                      {{ $t(spaces(market.name)) }}
                    </div>
                    <div v-else class="row " style=" border-radius: 8px; margin: 4px; color: white">
                      {{ market.name }}
                    </div>

                    <div v-if="market.name != currentMarkets[0].name" class="row oneMarket align-middle">

                      <div v-for="bet in market.bets" :key="bet.id" class="d-inline" style="margin: 2px;">

                        <div v-if="bet.status === 2">

                          <button class="btn btn-secondary btn-sm disabled"
                                  style="margin-right: 2px; height: 100%; background-color: #d0e9ff ; border: 2px solid #38596f">

                            <div v-if="find(spaces(bet.name))"
                                 style="font-size: 0.7rem; color: black">{{ $t(spaces(bet.name)) }} {{ margin(bet.name) }}</div>
                            <div v-else
                                 style="font-size: 0.7rem; color: black">{{ bet.name }}</div>
                            <div style="font-size: 0.7rem; color: black; font-weight: bold"><img src="../assets/kvoteZ.svg" style="width: 20px; height: 20px"></div>

                          </button>

                        </div>

                        <div v-else-if="bet.status === 1">

                          <div v-if="bet.flag === 1">

                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, market.id, bet.id, event.home, event.away, market.name, bet.name, bet.odd)"
                                    class="btn btn-success btn-sm blink_me_green"
                                    style="margin-right: 2px; height: 100%; background-color: #f5cf08">

                              <div v-if="find(spaces(bet.name))" style="font-size: 0.7rem; color: white">
                                {{ $t(spaces(bet.name)) }} {{ margin(bet.name) }}
                              </div>
                              <div v-else style="font-size: 0.7rem; color: white">{{ bet.name }}</div>
                              <div style="font-size: 0.7rem; color: black; font-weight: bold">
                                {{ parseFloat(bet.odd).toFixed(2) }}
                              </div>

                            </button>

                            <button v-else
                                    @click="clickBet(event.id, market.id, bet.id, event.home, event.away, market.name, bet.name, bet.odd)"
                                    class="btn btn-secondary btn-sm blink_me_green"
                                    style="margin-right: 2px; height: 100%; background-color: #d0e9ff ; border: 2px solid #38596f">

                              <div v-if="find(spaces(bet.name))" style="font-size: 0.7rem; color: black">{{ $t(spaces(bet.name)) }} {{ margin(bet.name) }}</div>
                              <div v-else style="font-size: 0.7rem; color: black">{{ bet.name }}</div>
                              <div style="font-size: 0.7rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                            </button>

                          </div>
                          
                          <div v-else-if="bet.flag === -1">

                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, market.id, bet.id, event.home, event.away, market.name, bet.name, bet.odd)"
                                    class="btn btn-success btn-sm blink_me_red"
                                    style="margin-right: 2px; height: 100%; background-color: #f5cf08">

                              <div v-if="find(spaces(bet.name))" style="font-size: 0.7rem; color: white">
                                {{ $t(spaces(bet.name)) }} {{ margin(bet.name) }}
                              </div>
                              <div v-else style="font-size: 0.7rem; color: white">{{ bet.name }}</div>
                              <div style="font-size: 0.7rem; color: black; font-weight: bold">
                                {{ parseFloat(bet.odd).toFixed(2) }}
                              </div>

                            </button>
                            <button v-else
                                    @click="clickBet(event.id, market.id, bet.id, event.home, event.away, market.name, bet.name, bet.odd)"
                                    class="btn btn-secondary btn-sm blink_me_red"
                                    style="margin-right: 2px; height: 100%; background-color: #d0e9ff ; border: 2px solid #38596f">

                              <div v-if="find(spaces(bet.name))"
                                   style="font-size: 0.7rem; color: black">{{ $t(spaces(bet.name)) }} {{ margin(bet.name) }}</div>
                              <div v-else style="font-size: 0.7rem; color: black">{{ bet.name }}</div>
                              <div style="font-size: 0.7rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                            </button>

                          </div>


                          <div v-else>

                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, market.id, bet.id, event.home, event.away, market.name, bet.name, bet.odd)"
                                    class="btn btn-success btn-sm"
                                    style="margin-right: 2px; height: 100%;  background-color: #f5cf08">

                              <div v-if="find(spaces(bet.name))" style="font-size: 0.7rem; color: white">
                                {{ $t(spaces(bet.name)) }} {{ margin(bet.name) }}
                              </div>
                              <div v-else style="font-size: 0.7rem; color: white">{{ bet.name }}</div>
                              <div style="font-size: 0.7rem; color: black; font-weight: bold">
                                {{ parseFloat(bet.odd).toFixed(2) }}
                              </div>

                            </button>
                            <button v-else
                                    @click="clickBet(event.id, market.id, bet.id, event.home, event.away, market.name, bet.name, bet.odd)"
                                    class="btn btn-secondary btn-sm"
                                    style="margin-right: 2px; height: 100%; background-color: #d0e9ff ; border: 2px solid #38596f">

                              <div v-if="find(spaces(bet.name))" style="font-size: 0.7rem; color: black">{{ $t(spaces(bet.name)) }} {{ margin(bet.name) }}</div>
                              <div v-else style="font-size: 0.7rem; color: black">{{ bet.name }}</div>
                              <div style="font-size: 0.7rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                            </button>

                          </div>



                        </div>


                      </div>

                    </div>

                  </td>

                </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>

      </div>
    </div>

    <div :key="componentKey2">

      <div v-if="ticketsCount()>0"
           class="navbar fixed-bottom float-left ap page-hero d-flex align-items-center justify-content-center">
        <div class="text-center p-3 " style="background-color: rgba(0, 0, 0, 0.6); border-radius: 7px;">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-sm btn-dark"
                    style="margin-left: 2px; margin-right: 2px" data-dismiss="modal" @click="pushOnTicketMaker">
              Betting
              <button type="button" class="btn btn-sm "
                      style="background-color: #f5cf08; font-weight: bold; border-radius: 50%; font-size: 0.7rem">
                {{ ticketsCount() }}
              </button>
            </button>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import VueFloatingAlert from 'vue-floating-alert'
import Vue from 'vue'
import router from "@/router/router";

Vue.use(VueFloatingAlert)

export default {
  name: "PopUpTableOtherBets_Mobile",
  components: {},
  data() {
    return {
      types: [],
      currentMarkets: '',
      event: '',
      componentKey: 0,
      componentKey2: 0,
      isVisible: true,
      isMobile: false,
      dummy: ''

    }
  },


  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
    localStorage.removeItem('EventInOtherBets');
  },

  mounted() {
    this.isVisible = true
    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

    this.$root.$on('updateTickets', data => {
      this.componentKey4 += data;
    });

    this.$root.$on('popUpRefreshData', data => {
      const sorted = ["Will Be An Own Goal?", "Will A Red Card Be Shown?", "Will A Penalty Be Awarded?", "Under/Over 1st Period - Away Team", "Under/Over 1st Period - Home Team", "To Win Both Halves",
        "To Score In Both Halves", "Both Teams To Score In Both Halves", "Both Teams To Score 2nd Half", "Away Team To Win From Behind", "Home Team To Win From Behind", "Away Team To Win Both Halves",
        "Home Team To Win Both Halves", "Home Team Number Of Goals In 1st Half", "Home Team Number Of Goals", "Away Team to Score 1st Half", "Home Team to Score 1st Half", "Away Team to Score", "Home Team to Score",
        "HT/FT", "First Team To Score", "1st Period Odd/Even", "1st Period Winner And Both Teams To Score", "1st Half 1X2 And Under/Over", "Under/Over And Both Teams To Score", "1X2 And Under/Over", "Under/Over - Away Team",
        "Under/Over - Home Team", "1X2 And Both Teams To Score", "Odd/Even", "Number of Goals", "Asian Handicap 1st Period", "Asian Handicap", "Double Chance 2nd Period", "Double Chance 1st Period", "2nd Period Winner",
        "Correct Score 1st Period", "Correct Score", "Under/Over 1st Period", "1st Period Winner", "Both Teams To Score 1st Half", "Both Teams To Score", "Under/Over", "Remaining 1st Half", "Next Goal 1st Period", "Next Goal",
        "Remaining Match", "Double Chance", "1X2"];
      data.sort((a, b) => (sorted.indexOf(a.name) < sorted.indexOf(b.name)) ? 1 : ((sorted.indexOf(b.name) < sorted.indexOf(a.name)) ? -1 : 0))
      this.currentMarkets = data;
      const live = JSON.parse(localStorage.getItem('liveEvents'));
      const eventId = localStorage.getItem('EventInOtherBets');
      for(let i = 0; i < live.length; i++){
        if(live[i].id == eventId){
          this.event = live[i];
        }
      }

    })

  },

  methods: {

    removeEventInOtherTables(){
      localStorage.removeItem('EventInOtherBets');
    },

    homeResult(res){
      return res?.split(':')[0];
    },
    awayResult(res){
      return res?.split(':')[1];
    },

    getTime: function (time) {
      var date = new Date(time)
      return date.toLocaleString(['en-GB'], {month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit',});
    },

    formatTxt: function (txt) {

      if (txt.length > 15) {
        var s = txt;

        var middle = Math.floor(s.length / 2);
        var before = s.lastIndexOf(' ', middle);
        var after = s.indexOf(' ', middle + 1);

        if (middle - before < after - middle) {
          middle = before;
        } else {
          middle = after;
        }

        var s1 = s.substr(0, middle);
        var s2 = s.substr(middle + 1);
        var arr = [s1, s2];
        return arr
      }

      return txt

    },

    find(betName) {
      if (betName === '1X2' || ((betName.startsWith('1') || betName.startsWith('2')) && betName.length < 3)) {
        return false;
      }
      if (this.$te(this.spaces(betName))) {
        return true;
      }
      return false;
    },

    spaces(betName) {
      // if(betName.includes('Period')){
      //   console.log('Before Method: ' + betName);
      // }
      if(betName === undefined) return '';
      if (betName.includes('Over') || betName.includes('Under')) {
        let split = betName.split(' ');
        betName = '';
        for (let i = 0; i < split.length; i++) {
          if (!split[i].includes('.')) {
            betName += split[i];
          }
        }
      }
      if(betName.includes('Period') && (this.event.league.sport.name === 'Football' || this.event.league.sport.name === 'Handball')){
        betName += 'Football';
      }
      // if(betName.includes('Period')) {
      //   console.log('After Method: ' + betName.replaceAll(' ', ''));
      // }
      return betName.replaceAll(' ', '');
    },

    margin(betName) {
      if (betName.includes('Over') || betName.includes('Under')) {
        let split = betName.split(' ');
        betName = '';
        for (let i = 0; i < split.length; i++) {
          if (split[i].includes('.')) {
            return split[i];
          }
        }
      }
      return '';
    },

    updateTicketFooter: function () {
      this.$root.$emit('updateFooter', 1);
      this.$root.$emit('updateOthers', 1);
    },

    loadEvents: function (event) {
      localStorage.setItem('leagueId', event)
      this.$axios.post('getEvents', {
        parentId: event
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))

        for (let i = 0; i < response.data.length; i++) {
          this.loadMarkets(response.data[i].id)
        }
      }).catch(error => {
        console.log(error);
      })
    },

    updateTables: function () {
      this.$root.$emit('updateTickets', 1);
    },

    loadMarkets: function (eventId) {
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);

        localStorage.setItem(combo, JSON.stringify(response.data))

        const data = 1;
        this.$root.$emit('updateLiveTable', data);
        this.$root.$emit('updatePreMatchTable', data);

      }).catch(error => {
        console.log(error);
      })
    },

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    ticketsCount: function () {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        return clickedBets.length
      }

      return 0
    },

    pushOnTicketMaker: async function () {

      if (!this.$route.fullPath.includes('TicketMakerView'))
        await router.push('/TicketMakerView')

    },

    selected: function (betId) {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId))
          return true
        else return false
      }

      return false
    },

    clickBet(eventId, marketId, betId, home, away, marketName, betName, betOdd) {

      var player = JSON.parse(localStorage.getItem('player'))
      var cnt = 0;
      if (player == null || player == undefined && cnt == 0) {
        cnt = 1
        setTimeout(() => {
          this.$floatingAlert.info({
            title: this.$t('Note'),
            message: this.$t('betClick') + '<i class="fa fa-fw fa-grin-wink"></i>',
            timeVisible: 3000,
          })
        }, 1)
        return;
      }

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))
      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId)) {
          this.$root.$emit('remotelyDeleteBetPop', eventId);
          this.$forceUpdate()
          return;
        }
      }

      this.$root.$emit('clickBetPop', {eventId, marketId, betId, home, away, marketName, betName, betOdd});
    },

  },

  created() {
    this.$root.$on('toggleModal', obj => {
      const markets = obj.currMarks;
      const sorted = ["Will Be An Own Goal?", "Will A Red Card Be Shown?", "Will A Penalty Be Awarded?", "Under/Over 1st Period - Away Team", "Under/Over 1st Period - Home Team", "To Win Both Halves",
        "To Score In Both Halves", "Both Teams To Score In Both Halves", "Both Teams To Score 2nd Half", "Away Team To Win From Behind", "Home Team To Win From Behind", "Away Team To Win Both Halves",
        "Home Team To Win Both Halves", "Home Team Number Of Goals In 1st Half", "Home Team Number Of Goals", "Away Team to Score 1st Half", "Home Team to Score 1st Half", "Away Team to Score", "Home Team to Score",
        "HT/FT", "First Team To Score", "1st Period Odd/Even", "1st Period Winner And Both Teams To Score", "1st Half 1X2 And Under/Over", "Under/Over And Both Teams To Score", "1X2 And Under/Over", "Under/Over - Away Team",
        "Under/Over - Home Team", "1X2 And Both Teams To Score", "Odd/Even", "Number of Goals", "Asian Handicap 1st Period", "Asian Handicap", "Double Chance 2nd Period", "Double Chance 1st Period", "2nd Period Winner",
        "Correct Score 1st Period", "Correct Score", "Under/Over 1st Period", "1st Period Winner", "Both Teams To Score 1st Half", "Both Teams To Score", "Under/Over", "Remaining 1st Half", "Next Goal 1st Period", "Next Goal",
        "Remaining Match", "Double Chance", "1X2"];
      markets.sort((a, b) => (sorted.indexOf(a.name) < sorted.indexOf(b.name)) ? 1 : ((sorted.indexOf(b.name) < sorted.indexOf(a.name)) ? -1 : 0))
      this.currentMarkets = markets;
      this.event = obj.event;
    });



  },

}
</script>

<style scoped>

.oneMarket {
  margin: 6px;
}

.modal {
  text-align: center;
}

.modal-content {
  background: url(../assets/secondbg.jpg) center center no-repeat;
}

.modal-dialog {
  text-align: left; /* you'll likely want this */
  max-width: 90%;
  /*width: auto !important;*/
  display: inline-block;
}

.modal-dialogSm {
  text-align: left; /* you'll likely want this */
  max-width: 100%;
  /*width: auto !important;*/
  display: inline-block;
}

.my-custom-scrollbarSm {
  position: relative;
  height: 80vh;
  overflow: auto;
}

.my-custom-scrollbar {
  position: relative;
  height: 60vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-color: dimgrey;
  z-index: 1000;
}

html, body {
  overflow-x: auto;
  overflow-y: auto;
}

.blink_me_green {
  animation: blinker_green 1s linear;
}

@keyframes blinker_green {
  50% {
    /*opacity: 0;*/
    background-color: green;
  }
}

.blink_me_red {
  animation: blinker_red 1s linear;
}

@keyframes blinker_red {
  50% {
    /*opacity: 0;*/
    background-color: red;
  }
}

</style>
