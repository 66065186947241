<template>

  <div class="modal" id="myModal" :key="componentKey">

    <FloatingAlert language="en"/>

    <div class="modal-dialog">
      <div class="modal-content" v-if="isVisible">

        <!-- Modal Header -->
        <div class="modal-header" style="border: none">
          <h4 class="modal-title" style="color: white">{{$t('otherMarkets')}}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">

<!--          <div class="table-responsive" style="margin-bottom: 0px">-->
<!--            <table class="table table-sm table-hover table-striped table-dark">-->
<!--              <thead>-->
<!--              <tr class="firstrow">-->
<!--                <th scope="col" class="mb-2 text-right">-->
<!--                  <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal" @click="updateTicketFooter">X</button>-->
<!--                </th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--            </table>-->
<!--          </div>-->

          <div class="row table-wrapper-scroll-y my-custom-scrollbar">
            <div class="col-12">

              <table class="table table-sm table-hover table-dark" style="background-color: transparent">
                <thead>
                <tr class="firstrow ">
                  <th scope="col" class="text-center">Markets</th>
                  <th scope="col" class="text-center">{{$t('bets')}}</th>
                </tr>
                </thead>
                <tbody v-if="currentMarkets!=null && currentMarkets!=undefined">
                <tr v-for="market in currentMarkets" :key="market.id">

                  <td v-if="market.name != currentMarkets[0].name" class="align-middle">
                    <div v-if="find(spaces(market.name))" class="row ap page-hero d-flex align-items-center justify-content-center"
                         style=" border-radius: 8px; margin: 4px; color: white ">
                      {{ $t(spaces(market.name)) }}
                    </div>
                    <div v-else class="row ap page-hero d-flex align-items-center justify-content-center"
                         style=" border-radius: 8px; margin: 4px; color: white ">
                      {{ market.name }}
                    </div>
                  </td>
                  <td v-if="market.name != currentMarkets[0].name" class="align-middle">

                    <div class="row oneMarket">

                      <div v-for="bet in market.bets" :key="bet.id">

                        <button v-if="selected(bet.id)"
                                @click="clickBet(event.id, market.id, bet.id, event.home, event.away, market.name, bet.name, bet.odd)"
                                class="btn btn-sm h-100 d-inline-block"
                                style="margin-right: 5px; background-color: #f5cf08">

                          <div v-if="find(spaces(bet.name))">{{ $t(spaces(bet.name)) }} {{ margin(bet.name) }}</div>
                          <div v-else>{{ bet.name }}</div>
                          <div>{{ parseFloat(bet.odd).toFixed(2) }}</div>

                        </button>
                        <button v-else
                                @click="clickBet(event.id, market.id, bet.id, event.home, event.away, market.name, bet.name, bet.odd)"
                                class="btn btn-secondary btn-sm"
                                style="margin: 5px">

                          <div v-if="find(spaces(bet.name))">{{ $t(spaces(bet.name)) }} {{ margin(bet.name) }}</div>
                          <div v-else>{{ bet.name }}</div>
                          <div>{{ parseFloat(bet.odd).toFixed(2) }}</div>

                        </button>

                      </div>

                    </div>
                  </td>

                </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>

        <!-- Modal footer -->
        <div class="modal-footer" style="border: none">
          <button type="button" class="btn btn-light" data-dismiss="modal">{{ $t('close') }}</button>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import VueFloatingAlert from 'vue-floating-alert'
import Vue from 'vue'
import router from "@/router/router";

Vue.use(VueFloatingAlert)

export default {
  name: "PopUpTableOtherBets_Desktop",
  components: {},
  data() {
    return {
      types: [],
      currentMarkets: '',
      event: '',
      componentKey: 0,
      componentKey2: 0,
      isVisible: true,
      isMobile: false

    }
  },


  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted() {
    this.isVisible = true
    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

    this.$root.$on('updateTickets', data => {
      this.componentKey4 += data;
    });

  },

  methods: {

    formatTxt: function (txt) {

      if (txt.length > 15) {
        var s = txt;

        var middle = Math.floor(s.length / 2);
        var before = s.lastIndexOf(' ', middle);
        var after = s.indexOf(' ', middle + 1);

        if (middle - before < after - middle) {
          middle = before;
        } else {
          middle = after;
        }

        var s1 = s.substr(0, middle);
        var s2 = s.substr(middle + 1);
        var arr = [s1, s2];
        return arr
      }

      return txt

    },

    find(betName){
      if(betName === '1X2' || ((betName.startsWith('1') || betName.startsWith('2')) && betName.length < 3)){
        return false;
      }
      if(this.$te(this.spaces(betName))){
        return true;
      }
      return false;
    },

    spaces(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(!split[i].includes('.')){
            betName += split[i];
          }
        }
      }
      return betName.replaceAll(' ', '');
    },

    margin(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(split[i].includes('.')){
            return split[i];
          }
        }
      }
      return '';
    },

    updateTicketFooter: function (){
      this.$root.$emit('updateFooter', 1);
    },

    loadEvents: function (event) {
      localStorage.setItem('leagueId', event)
      this.$axios.post('getEvents', {
        parentId: event
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))

        for (let i = 0; i < response.data.length; i++) {
          this.loadMarkets(response.data[i].id)
        }
      }).catch(error => {
        console.log(error);
      })
    },

    updateTables: function () {
      this.$root.$emit('updateTickets', 1);
    },

    loadMarkets: function (eventId) {
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);

        localStorage.setItem(combo, JSON.stringify(response.data))

        const data = 1;
        this.$root.$emit('updateLiveTable', data);
        this.$root.$emit('updatePreMatchTable', data);

      }).catch(error => {
        console.log(error);
      })
    },

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    ticketsCount: function () {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        return clickedBets.length
      }

      return 0
    },

    pushOnTicketMaker: async function () {

      if (!this.$route.fullPath.includes('TicketMakerView'))
        await router.push('/TicketMakerView')

    },

    selected: function (betId) {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId))
          return true
        else return false
      }

      return false
    },

    clickBet(eventId, marketId, betId, home, away, marketName, betName, betOdd) {

      var player = JSON.parse(localStorage.getItem('player'))
      var cnt = 0;
      if (player == null || player == undefined && cnt == 0) {
        cnt = 1
        setTimeout(() => {
          this.$floatingAlert.info({
            title: this.$t('Note'),
            message: this.$t('betClick') + '<i class="fa fa-fw fa-grin-wink"></i>',
            timeVisible: 3000,
          })
        }, 1)
        return;
      }

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))
      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId)) {
          this.$root.$emit('remotelyDeleteBetPop', eventId);
          this.$forceUpdate()
          return;
        }
      }

      this.$root.$emit('clickBetPop', {eventId, marketId, betId, home, away, marketName, betName, betOdd});
      this.$root.$emit('clickBet', {eventId, marketId, betId, home, away, marketName, betName, betOdd});
      this.$root.$emit('updateTickets', 1);
    },

  },

  created() {
    this.$root.$on('toggleModal', obj => {
      const markets = JSON.parse(localStorage.getItem('markets' + obj.event.id));
      const sorted = ["Odd/Even", "Correct Score", "HT/FT", "Under/Over 1st Period", "1st Period Winner", "Both Teams to Score",
        "Asian Handicap", "Under/Over", "Double Chance", "1X2"];
      markets.sort((a,b) => (sorted.indexOf(a.name) < sorted.indexOf(b.name) ) ? 1 : ((sorted.indexOf(b.name)  < sorted.indexOf(a.name) ) ? -1 : 0))
      this.currentMarkets = markets;
      this.event = obj.event;
    });

  },

}
</script>

<style scoped>

.oneMarket {
  margin: 6px;
}

.modal {
  text-align: center;
}

.modal-content {
  background: url(../assets/secondbg.jpg) center center no-repeat;
}

.modal-dialog {
  text-align: left; /* you'll likely want this */
  max-width: 90%;
  /*width: auto !important;*/
  display: inline-block;
}

.modal-dialogSm {
  text-align: left; /* you'll likely want this */
  max-width: 100%;
  /*width: auto !important;*/
  display: inline-block;
}

.my-custom-scrollbarSm {
  position: relative;
  height: 80vh;
  overflow: auto;
}

.my-custom-scrollbar {
  position: relative;
  height: 60vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-color: #001523;
}

html, body {
  overflow-x: auto;
  overflow-y: auto;
}

</style>