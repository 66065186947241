<template>

  <div>


    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    <PopUpTableForLeagues_Mobile ref="PopUpTableLeagues" :key="componentKey"></PopUpTableForLeagues_Mobile>


    <div v-if="isMobile" class="MOBILE">

      <b-row style="margin-bottom: -10px">
        <b-col sm="12">
          <Navbar :key="componentKeyN"></Navbar>
        </b-col>
      </b-row>

      <div class="navbar1" style="margin-bottom: -12px">

        <!--          <div class="row mt-1" style="margin-bottom: -10px">-->

        <!--            <div class="col-2">-->
        <!--              <div>-->
        <!--                <a class="navbar-brand hand" style="color: white; font-size: 0.9rem; margin-left: 10px">today:</a>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="col-4"></div>-->

        <!--            <div class="col-2">-->
        <!--              <div>-->
        <!--                <a class="navbar-brand hand "><img class="uppers" src="../assets/search.png"></a>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="col-2">-->
        <!--              <div>-->
        <!--                <a class="navbar-brand hand "><img class="uppers" src="../assets/trophy.png"></a>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="col-2">-->
        <!--              <div>-->
        <!--                <a class="navbar-brand hand "><img class="uppers" src="../assets/timedate.png"></a>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--          </div>-->

        <div class="btn-group mt-1 container-fluid" role="group" aria-label="Basic example">
          <button class="btn  btn-sm" data-toggle="modal" data-target="#myModalForLeagues" @click="loadLeaguesToggle('Football')">
            <div>
              <a class="navbar-brand hand">
                <img class="nav1" src="../assets/ball.svg"></a>
            </div>
            <div>
              <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">SPORT</a>
            </div>
          </button>
          <button class="btn  btn-sm" @click="pushOnLiveBets">
            <div>
              <a class="navbar-brand hand">
                <img class="nav1" src="../assets/live.svg"></a>
            </div>
            <div>
              <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">LIVE</a>
            </div>
          </button>
          <button class="btn  btn-sm" @click="goToCasinoPage()">
            <div>
              <a class="navbar-brand hand">
                <img class="nav1" src="../assets/casino.svg"></a>
            </div>
            <div>
              <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">CASINO</a>
            </div>
          </button>
        </div>

      </div>

      <b-row class="mt-4 ap page-hero d-flex align-items-center justify-content-center" style="font-size: 0.6rem;">
        <b-col sm="12">
          <b-row class="mt-4 ap page-hero d-flex align-items-center justify-content-center">
            <b-col sm="12">
              <PlayerTicketsTable_Mobile :key="componentKey2"></PlayerTicketsTable_Mobile>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div v-if="ticketsCount()>0"
           class="navbar fixed-bottom float-left ap page-hero d-flex align-items-center justify-content-center">
        <div class="text-center p-3 " style="background-color: rgba(0, 0, 0, 0.6); border-radius: 7px;">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-sm btn-dark"
                    style="margin-left: 2px; margin-right: 2px" @click="pushOnTicketMaker">
              Betting
              <button type="button" class="btn btn-sm "
                      style="background-color: #f5cf08; font-weight: bold; border-radius: 50%; font-size: 0.6rem">
                {{ ticketsCount() }}
              </button>
            </button>
          </div>
        </div>
      </div>

    </div>


    <div v-else class="DESKTOP" style="margin-left: 6%; margin-right: 6%">

      <div class="navbar2" style="padding-bottom: 5px">

        <b-row>
          <b-col sm="12">
            <Navbar_Desktop :key="componentKeyN"></Navbar_Desktop>
          </b-col>
        </b-row>

        <!--          <b-row class="mt-4 betTypes ap page-hero d-flex align-items-center justify-content-center">-->
        <!--            <b-col sm="4" >-->
        <!--              <label  style="font-size: 110%;  color: black; font-weight: bold" @click="pushOnHome">Sports Betting</label>-->
        <!--            </b-col>-->
        <!--            <b-col sm="4" >-->
        <!--              <label  style="font-size: 110%; color: black; font-weight: bold" @click="pushOnLiveBets">Live Bets</label>-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <b-row class="mt-0 sports ap page-hero d-flex align-items-center justify-content-center sports-navbar" >

          <b-col sm="1" v-if="currentSport === 'Football'" style="background-image: linear-gradient(to bottom, #00102e, #04619f, #04619f)" >
            <a class="navbar-brand hand" @click="goToHome('Football')"><img src="../assets/footballrs.png" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('football')}}</strong></a>
          </b-col>

          <b-col sm="1" v-else class="">
            <a class="navbar-brand hand" @click="goToHome('Football')"><img src="../assets/footballrs.png" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('football')}}</strong></a>
          </b-col>

          <b-col sm="1" v-if="currentSport === 'Basketball'" class="" style="background-image: linear-gradient(to bottom, #00102e, #04619f, #04619f)" >
            <a class="navbar-brand hand" @click="goToHome('Basketball')"><img src="../assets/basketball.svg" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('basketball')}}</strong></a>
          </b-col>

          <b-col sm="1" v-else class="" >
            <a class="navbar-brand hand" @click="goToHome('Basketball')"><img src="../assets/basketball.svg" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('basketball')}}</strong></a>
          </b-col>

          <b-col sm="1" v-if="currentSport === 'Ice Hockey'" class="" style="background-image: linear-gradient(to bottom, #00102e, #04619f, #04619f)" >
            <a class="navbar-brand hand" @click="goToHome('Ice Hockey')"><img src="../assets/icehockey.svg" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('tennis')}}</strong></a>
          </b-col>

          <b-col sm="1" v-else class="" >
            <a class="navbar-brand hand" @click="goToHome('Ice Hockey')"><img src="../assets/icehockey.svg" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('tennis')}}</strong></a>
          </b-col>

          <b-col sm="1" v-if="currentSport === 'Handball'" class="" style="background-image: linear-gradient(to bottom, #00102e, #04619f, #04619f)" >
            <a class="navbar-brand hand" @click="goToHome('Handball')"><img src="../assets/handball.png" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('volleyball')}}</strong></a>
          </b-col>

          <b-col sm="1" v-else class="" >
            <a class="navbar-brand hand" @click="goToHome('Handball')"><img src="../assets/handball.png" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('volleyball')}}</strong></a>
          </b-col>
        </b-row>


      </div>

      <b-row class="mt-0 ap page-hero d-flex align-items-center justify-content-center">
        <b-col sm="10">
          <b-row class="mt-0 ap page-hero d-flex align-items-center justify-content-center">
            <b-col sm="10">
              <PlayerTicketsTable_Desktop :key="componentKey2"></PlayerTicketsTable_Desktop>
            </b-col>
          </b-row>
        </b-col>
      </b-row>


    </div>


  </div>

</template>

<script>
import Navbar from "@/components/Navbar";
import PlayerTicketsTable_Mobile from "@/components/PlayerTicketsTable_Mobile";
import PlayerTicketsTable_Desktop from "@/components/PlayerTicketsTable_Desktop";
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import router from "@/router/router";
import Navbar_Desktop from "@/components/Navbar_Desktop";
import PopUpTableForLeagues_Mobile from "@/components/PopUpTableForLeagues_Mobile";


export default {
  name: "PlayerTicketsView",
  components: {Navbar_Desktop, Navbar, PlayerTicketsTable_Mobile, PlayerTicketsTable_Desktop, ConfirmDialogue,PopUpTableForLeagues_Mobile},

  data() {
    return {
      componentKeyN: 0,
      componentKey2: 0,
      isMobile: false,
      componentKey: 0,
    }
  },

  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },


  mounted() {


    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})
    this.showTickets()

    this.$root.$on('updateNavbar', data => {

      this.componentKeyN += data;

    });

    this.$root.$on('updateLeagues', data => {
      this.componentKey += data;
    });

  },

  methods: {

    onResize() {
      this.isMobile = global.innerWidth < 1024
    },

    goToHome(name){
      localStorage.setItem('currentSport', name);
      router.push('/home');
    },

    async pushOnTicketView() {

      if (!this.$route.fullPath.includes('PlayerTicketsView'))
        await router.push('/PlayerTicketsView')

    },

    goToCasinoPage() {
      if(JSON.parse(localStorage.getItem('player')) != null && JSON.parse(localStorage.getItem('player')) != undefined){
        let p = JSON.parse(localStorage.getItem('player'));
        let pw = p.password;
        let password = pw.replaceAll('.','');
        location.href = 'http://casino.loca.bet/autologin/' + password.substr(8, 14) + '---' + p.username;
      }else{
        location.href = 'http://casino.loca.bet';
      }
    },


    async loadLeaguesToggle(name) {

      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {

        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.$root.$emit('toggleModalLeagues', 1);

      }).catch(error => {
        console.log(error);
      })
    },

    ticketsCount: function () {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        return clickedBets.length
      }

      return 0
    },

    getCurrentSportName: function (){
      const currentSport =(localStorage.getItem('currentSport'));



        return currentSport;
    },

    async loadLeagues(name) {

      if (!this.$route.fullPath.includes('home'))
        await router.push('/home')

      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {

        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.componentKey += 1;
      }).catch(error => {
        console.log(error);
      })
    },

    async showMsg(title, msg) {

      await this.$refs.confirmDialogue.showNotice({
        title: title,
        message: msg,
        okButton2: 'Ok',
      })

    },

    async pushOnLiveBets() {

      if (!this.$route.fullPath.includes('LiveBetsView'))
        await router.push('/LiveBetsView')

    },

    async pushOnHome() {

      if (!this.$route.fullPath.includes('home'))
        await router.push('/home')

    },

    showTickets: async function () {

      if (this.player != null) {

        var checker = 0

        this.$axios.post('getTickets', {
          parentId: this.player.id
        }).then(response => {

          localStorage.setItem('playerTickets', JSON.stringify(response.data))
          this.componentKey2 += 1

          // if(!this.$route.fullPath.includes('PlayerTicketsView'))
          //   router.push('/PlayerTicketsView')
          checker = 1

        }).catch(error => {
          console.log(error);
        })

        if (checker == 1 && !this.$route.fullPath.includes('PlayerTicketsView'))
          await router.push('/PlayerTicketsView')

      }

    },

    pushOnTicketMaker: async function () {

      if (!this.$route.fullPath.includes('TicketMakerView'))
        await router.push('/TicketMakerView')

    },

    // -------ovo dole je za inicijalno stvaranje eventova da ne bude prazno prvi put
    loadEventsInitial: function (leagueId, league){
      localStorage.setItem('leagueId', leagueId)
      localStorage.setItem('currentLeague', league)
      this.$axios.post('getEvents', {
        parentId: leagueId
      }).then(response => {
        localStorage.setItem('events', JSON.stringify(response.data))
        this.$root.$emit('updateNavbar', 1);
        for (let i = 0; i < response.data.length; i++) {
          this.loadMarketsInitial(response.data[i].id)
        }
      }).catch(error => {
        console.log(error);
      })
    },
    loadMarketsInitial: function (eventId){
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {
        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);
        localStorage.setItem(combo , JSON.stringify(response.data))
        const data = 1;
        this.$root.$emit('updateLiveTable', data);
        this.$root.$emit('updatePreMatchTable', data);
      }).catch(error => {
        console.log(error);
      })
    },
    async loadLeaguesInitially(name) {
      this.currentSport = name;
      localStorage.setItem('currentSport',name);
      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {
        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.componentKey += 1;
        let leagues = JSON.parse(localStorage.getItem('leagues'))
        if(leagues!=null && leagues!=undefined && leagues.length>0){
          let leagueId = leagues[0].id
          let league = leagues[0].name
          this.loadEventsInitial(leagueId, league)
        }else{
          this.loadLeaguesInitially('Basketball')
          let leagues = JSON.parse(localStorage.getItem('leagues'))
          if(leagues!=null && leagues!=undefined && leagues.length>0){
            let leagueId = leagues[0].id
            let league = leagues[0].name
            this.loadEventsInitial(leagueId, league)
          }else{
            this.loadLeaguesInitially('Tennis')
            let leagues = JSON.parse(localStorage.getItem('leagues'))
            if(leagues!=null && leagues!=undefined && leagues.length>0){
              let leagueId = leagues[0].id
              let league = leagues[0].name
              this.loadEventsInitial(leagueId, league)
            }else{
              this.loadLeaguesInitially('Volleyball')
              let leagues = JSON.parse(localStorage.getItem('leagues'))
              if(leagues!=null && leagues!=undefined && leagues.length>0){
                let leagueId = leagues[0].id
                let league = leagues[0].name
                this.loadEventsInitial(leagueId, league)
              }else{
                this.showMsg('Notice','We currently do not have any leagues, please try again later.')
              }
            }
          }
        }
      }).catch(error => {
        console.log(error);
      })
    },

  }

}
</script>

<style scoped>

.betTypes {
  /*background-image: linear-gradient(to bottom,#eb5757, #b82e1f);*/
}

.sports {
  /*background-image: linear-gradient(to bottom,#eb5757, #b82e1f);*/
  border-top: 1px solid black;
}

.navbar2 {
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  /*background-image: linear-gradient(to right, rgba(19, 78, 94, 0.7), rgba(113, 178, 128, 0.7));*/
}


.sports-navbar{
  /*background-color: lightblue; !*#07112d*!*/
  background-image: linear-gradient(to bottom, #00102e, #04619f);
  border-radius: 2px;
}

.navbar1 {
  background-image: linear-gradient(to bottom, #00102e, #04619f);
}

.navbar2 {
  /*background-color: #00102e;*/
}

label:hover,
label:focus {
  box-shadow: 0 0.5em 0.5em -0.4em black;
  transform: translateY(-0.25em);
}

img {
  width: 21px;
  height: 21px
}

.uppers {
  width: 17px;
  height: 17px
}

.nav1 {
  width: 25px;
  height: 25px;
}

</style>
