<template>
  <div>


    <div class="tableList">

      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
      <FloatingAlert language="en"/>

      <div class="row table-wrapper-scroll-y my-custom-scrollbar">
        <div class="col-12">

          <table class="table table-sm table-hover" style="background-color: #222325">

            <tbody class="">
            <tr v-for="eventId in this.eventIds" :key="eventId">
              <td>

                <div class="text-left" style="margin-left: 25px">
                  <button class="btn  btn-sm " style="font-size: 0.8rem; font-weight: bold; color: white">
                    <div class="row text-left" style=" border-radius: 8px; margin-bottom: 4px">
                      {{ eventsHomes[getIndex(eventId)] }} - {{ eventsAways[getIndex(eventId)] }}
                    </div>
                  </button>
                </div>

                <div class="btn-group container-fluid" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-sm btn-dark text-left">
                    <div v-if="find(spaces(marketsNames[getIndex(eventId)]))" style="font-size: 0.7rem">
                      {{ $t(spaces(marketsNames[getIndex(eventId)])) }}
                    </div>
                    <div v-else style="font-size: 0.7rem">
                      {{ marketsNames[getIndex(eventId)] }}
                    </div>
                  </button>
                  <button type="button" class="btn btn-sm btn-dark text-right">
                    <div v-if="find(spaces(betNames[getIndex(eventId)]))" style="font-size: 0.7rem">{{ $t(spaces(betNames[getIndex(eventId)])) }}</div>
                    <div v-else style="font-size: 0.7rem">{{ betNames[getIndex(eventId)] }}</div>
                  </button>
                  <button type="button" class="btn btn-sm btn-secondary" style="background-color: #001523">
                    <div style="font-size: 0.7rem;">{{ betOdds[getIndex(eventId)] }}</div>
                  </button>
                  <button class="btn btn-danger btn-sm" type="submit" v-on:click="deleteBet(getIndex(eventId))"
                          style=" max-width: 30px; font-size: 0.7rem">
                    X
                  </button>
                </div>

              </td>
            </tr>
            </tbody>
            <!--            <tfoot v-if="this.eventIds!=null && this.eventIds!=undefined && this.eventIds.length > 0" class="amount">-->
            <!--            <tr>-->
            <!--              <td>-->

            <!--                <div class="btn-group container-fluid" role="group" aria-label="Basic example">-->

            <!--                  <button type="button" class="btn btn-sm text-left" style="font-weight: bold; color: white">-->
            <!--                    <div style="font-size: 1rem;"> Bets: <a style="color: #cfb640; font-weight: bold"> {{-->
            <!--                        ticketsCount()-->
            <!--                      }} </a></div>-->
            <!--                  </button>-->
            <!--                  <button type="button" class="btn btn-sm text-right" style="font-weight: bold; color: white">-->
            <!--                    <div style="font-size: 1rem;">Rate: <a style="color: #cfb640; font-weight: bold"> {{-->
            <!--                        getType()-->
            <!--                      }} </a>-->
            <!--                    </div>-->
            <!--                  </button>-->

            <!--                </div>-->

            <!--              </td>-->
            <!--            </tr>-->

            <!--            <tr>-->
            <!--              <td>-->

            <!--                <div class="sports ap page-hero d-flex align-items-center justify-content-center">-->

            <!--                  <div class="btn-group container-fluid" role="group" aria-label="Basic example">-->

            <!--                    <button type="button" class="btn btn-sm btn-dark " style="background-color: #001523">-->
            <!--                      <div style="font-size: 1rem;">-->
            <!--                        Amount-->
            <!--                      </div>-->
            <!--                    </button>-->
            <!--                    <button type="button" class="btn btn-sm  ">-->
            <!--                      <input class="form-control mr-sm-1" type="number" min="0" id="amnt1 font-size: 0.5rem"-->
            <!--                             v-model="amount" placeholder="amount" aria-label="Amount">-->
            <!--                    </button>-->
            <!--                    <button type="button" class="btn btn-sm btn-secondary" style="background-color: #001523">-->
            <!--                      CHF-->
            <!--                    </button>-->

            <!--                  </div>-->

            <!--                </div>-->

            <!--              </td>-->
            <!--            </tr>-->

            <!--            <tr>-->
            <!--              <td>-->

            <!--                <div class="btn-group container-fluid" role="group" aria-label="Basic example">-->
            <!--                  <button type="button" class="btn text-left" style=" font-size: 1rem; font-weight: bold; color: white">-->
            <!--                    Possible Winning-->
            <!--                  </button>-->
            <!--                  <button type="button" class="btn text-right" style="font-size: 1rem; font-weight: bold; color: white">-->
            <!--                    {{ (this.amount * getType()).toFixed(2) }}-->
            <!--                  </button>-->
            <!--                </div>-->

            <!--              </td>-->
            <!--            </tr>-->

            <!--            <tr>-->
            <!--              <td>-->

            <!--                <button class="btn btn-success btn-sm my-2 my-sm-0" type="submit" @click="placeBet"-->
            <!--                        style="margin-right: 15px; background-color: #f5cf08; color: black">Place Bet-->
            <!--                </button>-->

            <!--              </td>-->
            <!--            </tr>-->

            <!--            </tfoot>-->
          </table>

        </div>
      </div>

      <div v-if="this.eventIds!=null && this.eventIds!=undefined && this.eventIds.length > 0" class="amount">

        <table class="table table-sm table-hover" style="background-color: #373737; ">

          <tbody>
          <tr>
            <td>

              <div class="btn-group container-fluid" role="group" aria-label="Basic example">

                <button type="button" class="btn btn-sm text-left" style="font-weight: bold; color: white">
                  <div style="font-size: 1rem;"> {{$t('bets')}}: <a style="color: #cfb640; font-weight: bold"> {{
                      ticketsCount()
                    }} </a></div>
                </button>
                <button type="button" class="btn btn-sm text-right" style="font-weight: bold; color: white">
                  <div style="font-size: 1rem;">{{$t('rate')}}: <a style="color: #cfb640; font-weight: bold"> {{ getType() }} </a>
                  </div>
                </button>

              </div>

            </td>
          </tr>

          <tr>
            <td>

              <div class="sports ap page-hero d-flex align-items-center justify-content-center">

                <div class="btn-group container-fluid" role="group" aria-label="Basic example">

                  <button type="button" class="btn btn-sm btn-dark " style="background-color: #001523">
                    <div style="font-size: 1rem;">
                      Amount
                    </div>
                  </button>
                  <button type="button" class="btn btn-sm  ">
                    <input class="form-control mr-sm-1" type="number" min="0" id="amnt1 font-size: 0.5rem"
                           v-model="amount" placeholder="amount" aria-label="Amount">
                  </button>
                  <button type="button" class="btn btn-sm btn-secondary" style="background-color: #001523">
                    {{player.currency}}
                  </button>

                </div>

              </div>

            </td>
          </tr>

          <tr>
            <td>

              <div class="btn-group container-fluid" role="group" aria-label="Basic example">
                <button type="button" class="btn text-left" style=" font-size: 1rem; font-weight: bold; color: white">
                  {{$t('pWin')}}
                </button>
                <button v-if="(this.amount * getType()) < this.possibleWin" type="button" class="btn text-right"
                        style="font-size: 1rem; font-weight: bold; color: white">
                  {{ (this.amount * getType()).toFixed(2) }}
                </button>
                <button v-else type="button" class="btn text-right"
                        style="font-size: 1rem; font-weight: bold; color: white">
                  {{ this.possibleWin.toLocaleString('en-US') }}
                </button>
              </div>

            </td>
          </tr>

          <tr>
            <td>

              <button class="btn btn-success btn-sm my-2 my-sm-0" type="submit" @click="placeBet"
                      style="margin-right: 15px; background-color: #f5cf08; color: black">{{$t('placeBet')}}
              </button>

            </td>
          </tr>

          </tbody>
        </table>

      </div>

    </div>

  </div>
</template>

<script>
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import Vue from 'vue'
import VueFloatingAlert from 'vue-floating-alert'

Vue.use(VueFloatingAlert)

export default {
  name: "TicketTable_Mobile",
  components: {ConfirmDialogue},

  data() {
    return {
      amount: "",
      type: 0,
      eventIds: JSON.parse(localStorage.getItem('eventIds')),
      marketIds: JSON.parse(localStorage.getItem('marketIds')),
      betIds: JSON.parse(localStorage.getItem('betIds')),
      eventsHomes: JSON.parse(localStorage.getItem('eventsHomes')),
      eventsAways: JSON.parse(localStorage.getItem('eventsAways')),
      marketsNames: JSON.parse(localStorage.getItem('marketsNames')),
      betNames: JSON.parse(localStorage.getItem('betNames')),
      betOdds: JSON.parse(localStorage.getItem('betOdds')),
      player: JSON.parse(localStorage.getItem('player')),
      possibleWin: JSON.parse(localStorage.getItem('player')) ? JSON.parse(localStorage.getItem('player')).maximumPrize : '',
      isMobile: false,
      dummy: ''
    }
  },

  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted() {

    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

    const self = this;

    this.$root.$on('clickBet', function (obj) {

      this.$root.$emit('updateTickets', 1);

      if (self.eventIds == null || self.marketIds == null || self.betIds == null || self.eventsHomes == null
          || self.eventsAways == null || self.marketsNames == null || self.betNames == null || self.betOdds == null ||
          self.eventIds == undefined || self.marketIds == undefined || self.betIds == undefined || self.eventsHomes == undefined
          || self.eventsAways == undefined || self.marketsNames == undefined || self.betNames == undefined || self.betOdds == undefined) {
        localStorage.setItem('eventIds', JSON.stringify([]))
        localStorage.setItem('marketIds', JSON.stringify([]))
        localStorage.setItem('betIds', JSON.stringify([]))
        localStorage.setItem('eventsHomes', JSON.stringify([]))
        localStorage.setItem('eventsAways', JSON.stringify([]))
        localStorage.setItem('marketsNames', JSON.stringify([]))
        localStorage.setItem('betNames', JSON.stringify([]))
        localStorage.setItem('betOdds', JSON.stringify([]))
        self.eventIds = []
        self.marketIds = []
        self.betIds = []
        self.eventsHomes = []
        self.eventsAways = []
        self.marketsNames = []
        self.betNames = []
        self.betOdds = []
      }

      if (!self.eventIds.includes(obj.eventId)) {
        self.eventIds.push(obj.eventId);
        self.marketIds.push(obj.marketId);
        self.betIds.push(obj.betId);
        self.eventsHomes.push(obj.home);
        self.eventsAways.push(obj.away);
        self.marketsNames.push(obj.marketName);
        self.betNames.push(obj.betName);
        self.betOdds.push(obj.betOdd);
        localStorage.setItem('eventIds', JSON.stringify(self.eventIds))
        localStorage.setItem('marketIds', JSON.stringify(self.marketIds))
        localStorage.setItem('betIds', JSON.stringify(self.betIds))
        localStorage.setItem('eventsHomes', JSON.stringify(self.eventsHomes))
        localStorage.setItem('eventsAways', JSON.stringify(self.eventsAways))
        localStorage.setItem('marketsNames', JSON.stringify(self.marketsNames))
        localStorage.setItem('betNames', JSON.stringify(self.betNames))
        localStorage.setItem('betOdds', JSON.stringify(self.betOdds))
      }
    });

    this.$root.$on('clickBetPop', function (obj) {

      this.$root.$emit('updateTicketsPop', 1);

      if (self.eventIds == null || self.marketIds == null || self.betIds == null || self.eventsHomes == null
          || self.eventsAways == null || self.marketsNames == null || self.betNames == null || self.betOdds == null ||
          self.eventIds == undefined || self.marketIds == undefined || self.betIds == undefined || self.eventsHomes == undefined
          || self.eventsAways == undefined || self.marketsNames == undefined || self.betNames == undefined || self.betOdds == undefined) {
        localStorage.setItem('eventIds', JSON.stringify([]))
        localStorage.setItem('marketIds', JSON.stringify([]))
        localStorage.setItem('betIds', JSON.stringify([]))
        localStorage.setItem('eventsHomes', JSON.stringify([]))
        localStorage.setItem('eventsAways', JSON.stringify([]))
        localStorage.setItem('marketsNames', JSON.stringify([]))
        localStorage.setItem('betNames', JSON.stringify([]))
        localStorage.setItem('betOdds', JSON.stringify([]))
        self.eventIds = []
        self.marketIds = []
        self.betIds = []
        self.eventsHomes = []
        self.eventsAways = []
        self.marketsNames = []
        self.betNames = []
        self.betOdds = []
      }

      if (!self.eventIds.includes(obj.eventId)) {
        self.eventIds.push(obj.eventId);
        self.marketIds.push(obj.marketId);
        self.betIds.push(obj.betId);
        self.eventsHomes.push(obj.home);
        self.eventsAways.push(obj.away);
        self.marketsNames.push(obj.marketName);
        self.betNames.push(obj.betName);
        self.betOdds.push(obj.betOdd);
        localStorage.setItem('eventIds', JSON.stringify(self.eventIds))
        localStorage.setItem('marketIds', JSON.stringify(self.marketIds))
        localStorage.setItem('betIds', JSON.stringify(self.betIds))
        localStorage.setItem('eventsHomes', JSON.stringify(self.eventsHomes))
        localStorage.setItem('eventsAways', JSON.stringify(self.eventsAways))
        localStorage.setItem('marketsNames', JSON.stringify(self.marketsNames))
        localStorage.setItem('betNames', JSON.stringify(self.betNames))
        localStorage.setItem('betOdds', JSON.stringify(self.betOdds))
        //this.$root.$emit('updateTicketsPop', 1);
      }
    });

    this.$root.$on('remotelyDeleteBet', eventId => {
      this.remoteDel(this.getIndex(eventId))
      this.$root.$emit('updateTickets', 1);
      this.$root.$emit('componentKeyChanged', 1);
    });

    this.$root.$on('remotelyDeleteBetPop', eventId => {
      this.remoteDel(this.getIndex(eventId))
    });

  },

  methods: {

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    find(betName){
      if(betName === '1X2' || ((betName.startsWith('1') || betName.startsWith('2')) && betName.length < 3)){
        return false;
      }
      if(this.$te(this.spaces(betName))){
        return true;
      }
      return false;
    },

    spaces(betName){
      return betName.replaceAll(' ', '');
    },

    getType: function () {
      if (this.betOdds != null && this.betOdds != undefined) {

        var typ = 1;
        this.betOdds.forEach(odd => typ *= odd)
        //this.type = this.type/this.betOdds.length;
        return typ.toFixed(2);

      } else return 0;
    },

    ticketsCount: function () {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        return clickedBets.length
      }

      return 0
    },

    getIndex: function (eventId) {
      return this.eventIds.indexOf(eventId);
    },

    deleteAmount: async function () {

      // const ok = await this.$refs.confirmDialogue.showWarning({
      //   title: 'Remove Bet',
      //   message: 'Are you sure you want remove your current bet?',
      //   okButton: 'Remove',
      // })
      // if (ok) {

      this.amount = 0;

      // }

    },

    amount10: async function () {

      // const ok = await this.$refs.confirmDialogue.showNotice({
      //   title: 'Place Bet',
      //   message: 'Are you sure you want to place bet with 10 credits?',
      //   okButton2: 'Yes',
      // })
      // if (ok) {

      this.amount = 10;

      // }

    },

    amount20: async function () {

      // const ok = await this.$refs.confirmDialogue.showNotice({
      //   title: 'Place Bet',
      //   message: 'Are you sure you want to place bet with 20 credits?',
      //   okButton2: 'Yes',
      // })
      // if (ok) {

      this.amount = 20;

      // }

    },

    amount50: async function () {

      // const ok = await this.$refs.confirmDialogue.showNotice({
      //   title: 'Place Bet',
      //   message: 'Are you sure you want to place bet with 50 credits?',
      //   okButton2: 'Yes',
      // })
      // if (ok) {

      this.amount = 50;

      // }

    },

    amount100: async function () {

      // const ok = await this.$refs.confirmDialogue.showNotice({
      //   title: 'Place Bet',
      //   message: 'Are you sure you want to place bet with 100 credits?',
      //   okButton2: 'Yes',
      // })
      // if (ok) {

      this.amount = 100;

      // }

    },

    showMess: async function (type) {
      if (type == 1) {
        await this.$refs.confirmDialogue.showNotice({
          title: this.$t('ticketPlacing'),
          message: this.$t('ticketPlacingSucc'),
          okButton2: 'Ok',
          cancelButton: this.$t('back')
        })
      } else {
        await this.$refs.confirmDialogue.showWarning({
          title: this.$t('ticketPlacing'),
          message: this.$t('ticketPlacingFailed'),
          okButton: 'Ok',
          cancelButton: this.$t('back')
        })
      }
    },

    placeBet: async function () {

      const ok = await this.$refs.confirmDialogue.showNotice({
        title: this.$t('placeBetHead'),
        message: this.$t('placeBetQ'),
        okButton2: this.$t('yes'),
        cancelButton: this.$t('back')
      })
      if (ok) {

        var typ = this.getType();

        if (this.player != null
            && this.amount != null && this.amount != 0 && (this.player.balance - this.amount) >= 0
            && typ != null && typ > 0
            && this.eventIds != null && this.eventIds != undefined && this.eventIds.length > 0
            && this.marketIds != null && this.marketIds != undefined && this.marketIds.length > 0
            && this.betIds != null && this.betIds != undefined && this.betIds.length > 0) {

          this.$axios.post('makeTicket', {
            username: this.player.username,
            password: this.player.password,
            deposit: this.amount,
            multiplier: typ,
            eventIds: this.eventIds,
            marketIds: this.marketIds,
            betIds: this.betIds,
          }).then(response => {
                if (response.data.startsWith('YourMinimum')) {
                  setTimeout(() => {
                    this.$floatingAlert.error({
                      title: this.$t('failed'),
                      message: this.$t('minB') + response.data.split(' ')[1] + '<i class="fa fa-fw fa-grin-wink"></i>',
                      timeVisible: 3000,
                      btnCloseName: this.$t('close')
                    })
                  }, 1000)
                  return;
                }
                if (response.data.startsWith('YourMaximum')) {
                  setTimeout(() => {
                    this.$floatingAlert.error({
                      title: this.$t('failed'),
                      message: this.$t('maxB') + response.data.split(' ')[1] + '<i class="fa fa-fw fa-grin-wink"></i>',
                      timeVisible: 3000,
                      btnCloseName: this.$t('close')
                    })
                  }, 1000)
                  return;
                }
                if (response.data.startsWith('You don\'t')) {
                  setTimeout(() => {
                    this.$floatingAlert.error({
                      title: this.$t('failed'),
                      message: response.data + '<i class="fa fa-fw fa-grin-wink"></i>',
                      timeVisible: 3000,
                      btnCloseName: this.$t('close')
                    })
                  }, 1000)
                  return;
                }
                if (response.data.startsWith('Event')) {
                  setTimeout(() => {
                    this.$floatingAlert.error({
                      title: this.$t('failed'),
                      message: response.data.substring(6) + ' ' + this.$t('eventStarted') + '<i class="fa fa-fw fa-grin-wink"></i>',
                      timeVisible: 3000,
                      btnCloseName: this.$t('close')
                    })
                  }, 1000)
                  return;
                }

                this.player.balance -= this.amount
                localStorage.setItem('player', JSON.stringify(this.player))
                this.$root.$emit('updateNavbar', 1);

                this.removeAll()
                this.$root.$emit('updateTickets', 1);
                this.$root.$emit('componentKeyChanged', 1);
                setTimeout(() => {
                  this.$floatingAlert.success({
                    title: this.$t('success'),
                    message: this.$t('ticketPlacingSucc') + '<i class="fa fa-fw fa-grin-wink"></i>',
                  })
                }, 1000)
                // // this.showMess(1);
                // if (response.data.includes("success")) {
                //   console.log("success")
                //   if (response.data.includes("approved")) {
                //     console.log("approved")
                //     this.player.balance -= this.amount
                //     localStorage.setItem('player', JSON.stringify(this.player))
                //     this.$root.$emit('updateNavbar', 1);
                //
                //     this.removeAll()
                //     this.$root.$emit('updateTickets', 1);
                //     this.$root.$emit('componentKeyChanged', 1);
                //     setTimeout(() => {
                //       this.$floatingAlert.success({
                //         title: 'Success!',
                //         message: 'Ticket is successfully added! <i class="fa fa-fw fa-grin-wink"></i>',
                //       })
                //     }, 1000)
                //   } else {
                //     const id = response.data.split(" ")[1];
                //     console.log(id)
                //     const routerView = document.getElementById("router-view");
                //     routerView.hidden = true;
                //     localStorage.setItem("approved", "false")
                //     var result = 0;
                //     while (result == 0) {
                // setTimeout( () =>{
                //   this.$axios.post('checkApprovalForTicket', {
                //     id: id,
                //   }).then(response => {
                //     if(response.data === "approved"){
                //       result = 1
                //       localStorage.setItem("approved", "true")
                //       routerView.hidden = false;
                //       this.player.balance -= this.amount
                //       localStorage.setItem('player', JSON.stringify(this.player))
                //       this.$root.$emit('updateNavbar', 1);
                //
                //       this.removeAll()
                //       this.$root.$emit('updateTickets', 1);
                //       this.$root.$emit('componentKeyChanged', 1);
                //       setTimeout(() => {
                //         this.$floatingAlert.success({
                //           title: 'Success!',
                //           message: 'Ticket is successfully added! <i class="fa fa-fw fa-grin-wink"></i>',
                //         })
                //       }, 1000)
                //     }else if(response.data === "notApproved"){
                //       result = 2
                //       localStorage.setItem("approved", "true")
                //       routerView.hidden = false;
                //       setTimeout(() => {
                //         this.$floatingAlert.error({
                //           title: 'Failed!',
                //           message: 'Your ticket was not approved! <i class="fa fa-fw fa-grin-wink"></i>',
                //         })
                //       }, 1000)
                //     }
                // })
                //
                // },5000)
              }


              //var i = 0;
              // while(i++ < 200000){
              //   localStorage.setItem("loadder123", i);
              // }
              // setTimeout(() => {
              //   this.$floatingAlert.warn({
              //     title: 'Awaiting confirmation..!',
              //     //message: 'Confirming ticket...! <i class="fa fa-fw fa-grin-wink"></i>',
              //   })
              // }, 1000)
              //     }
              //   } else {
              //     setTimeout(() => {
              //       this.$floatingAlert.error({
              //         title: 'Failed!',
              //         message: response.data,
              //       })
              //     }, 1000)
              //   }
              //
              // }
          ).catch(error => {
            this.dummy = error;
            // this.showMess(2);
          })

        } else {
          this.showMess(2);
        }


      }

    },

    async deleteBet(index) {

      // const ok = await this.$refs.confirmDialogue.showWarning({
      //   title: 'Bet Delete',
      //   message: 'Are you sure you want to delete this bet?',
      //   okButton: 'Delete',
      // })
      // if (ok) {

      //this.removeBet(index)

      this.eventIds.splice(index, 1)
      this.marketIds.splice(index, 1)
      this.betIds.splice(index, 1)
      this.eventsHomes.splice(index, 1)
      this.eventsAways.splice(index, 1)
      this.marketsNames.splice(index, 1)
      this.betNames.splice(index, 1)
      this.betOdds.splice(index, 1)
      // localStorage.setItem('eventIds', JSON.stringify(this.eventIds.slice(index, 1)))
      // localStorage.setItem('marketIds', JSON.stringify(this.marketIds.slice(index, 1)))
      // localStorage.setItem('betIds', JSON.stringify(this.betIds.slice(index, 1)))
      // localStorage.setItem('eventsHomes', JSON.stringify(this.eventsHomes.slice(index, 1)))
      // localStorage.setItem('eventsAways', JSON.stringify(this.eventsAways.slice(index, 1)))
      // localStorage.setItem('marketsNames', JSON.stringify(this.marketsNames.slice(index, 1)))
      // localStorage.setItem('betNames', JSON.stringify(this.betNames.slice(index, 1)))
      // localStorage.setItem('betOdds', JSON.stringify(this.betOdds.slice(index, 1)))
      localStorage.setItem('eventIds', JSON.stringify(this.eventIds))
      localStorage.setItem('marketIds', JSON.stringify(this.marketIds))
      localStorage.setItem('betIds', JSON.stringify(this.betIds))
      localStorage.setItem('eventsHomes', JSON.stringify(this.eventsHomes))
      localStorage.setItem('eventsAways', JSON.stringify(this.eventsAways))
      localStorage.setItem('marketsNames', JSON.stringify(this.marketsNames))
      localStorage.setItem('betNames', JSON.stringify(this.betNames))
      localStorage.setItem('betOdds', JSON.stringify(this.betOdds))
      this.$root.$emit('componentKeyChanged', 1);
      this.$root.$emit('updateTickets', 1);


      // }

    }
    ,

    remoteDel: function (index) {
      this.eventIds.splice(index, 1)
      this.marketIds.splice(index, 1)
      this.betIds.splice(index, 1)
      this.eventsHomes.splice(index, 1)
      this.eventsAways.splice(index, 1)
      this.marketsNames.splice(index, 1)
      this.betNames.splice(index, 1)
      this.betOdds.splice(index, 1)
      localStorage.setItem('eventIds', JSON.stringify(this.eventIds))
      localStorage.setItem('marketIds', JSON.stringify(this.marketIds))
      localStorage.setItem('betIds', JSON.stringify(this.betIds))
      localStorage.setItem('eventsHomes', JSON.stringify(this.eventsHomes))
      localStorage.setItem('eventsAways', JSON.stringify(this.eventsAways))
      localStorage.setItem('marketsNames', JSON.stringify(this.marketsNames))
      localStorage.setItem('betNames', JSON.stringify(this.betNames))
      localStorage.setItem('betOdds', JSON.stringify(this.betOdds))
    }
    ,

    removeAll: function () {
      this.eventIds.splice(0, this.eventIds.length)
      this.marketIds.splice(0, this.marketIds.length)
      this.betIds.splice(0, this.betIds.length)
      this.eventsHomes.splice(0, this.eventsHomes.length)
      this.eventsAways.splice(0, this.eventsAways.length)
      this.marketsNames.splice(0, this.marketsNames.length)
      this.betNames.splice(0, this.betNames.length)
      this.betOdds.splice(0, this.betOdds.length)
      localStorage.setItem('eventIds', JSON.stringify(this.eventIds))
      localStorage.setItem('marketIds', JSON.stringify(this.marketIds))
      localStorage.setItem('betIds', JSON.stringify(this.betIds))
      localStorage.setItem('eventsHomes', JSON.stringify(this.eventsHomes))
      localStorage.setItem('eventsAways', JSON.stringify(this.eventsAways))
      localStorage.setItem('marketsNames', JSON.stringify(this.marketsNames))
      localStorage.setItem('betNames', JSON.stringify(this.betNames))
      localStorage.setItem('betOdds', JSON.stringify(this.betOdds))
    }

  }
}
</script>

<style scoped>

.my-custom-scrollbar {
  position: relative;
  height: 250px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

label:hover,
label:focus {
  box-shadow: 0 0.5em 0.5em -0.4em black;
  transform: translateY(-0.25em);
}

.badge {
  width: 50px;
}

.transparent {
  background-color: rgba(0, 0, 0, 0.5);
  height: 36vh;
  width: 35.5vh;
  color: lightgrey;
}

.dst {
  background-image: linear-gradient(to bottom right, #eecda3, yellow);
}

.dvd {
  background-image: linear-gradient(to bottom right, #f29492, #dc2430);
}

.pds {
  background-image: linear-gradient(to bottom right, lightgreen, green);
}

.st {
  background-image: linear-gradient(to bottom right, #85D8CE, dodgerblue);
}

.bills {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: linear-gradient(30deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(150deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(30deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(150deg, #444cf7 12%, transparent 12.5%, transparent 87%, #444cf7 87.5%, #444cf7), linear-gradient(60deg, #444cf777 25%, transparent 25.5%, transparent 75%, #444cf777 75%, #444cf777), linear-gradient(60deg, #444cf777 25%, transparent 25.5%, transparent 75%, #444cf777 75%, #444cf777);
  background-size: 20px 35px;
  background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
}

</style>
