<template>
  <div class="tableList" style="margin-top: -35px">

    <pop-up-table ref="popUpTable"></pop-up-table>
    <PopUpTableForPairs_Mobile ref="PopUpTablePairs"></PopUpTableForPairs_Mobile>

    <div class="row table-wrapper-scroll-y my-custom-scrollbar" style="height: 100vh">
      <div class="col-12">

        <table class="table table-sm table-hover" style="background-color: #222325; font-size: 0.6rem; margin-bottom: 40vh">
          <tbody>
          <tr v-for="ticket in tickets" :key="ticket.id" data-toggle="modal" data-target="#myModalForPairs"
              @click="clickBet(ticket)">
            <td class="align-middle">

              <div class="text-left">

                <button class="btn  btn-sm " style="margin-left: 10px; font-size: 0.6rem; color: #cfb640">
                  <div>
                    #{{ ticket.id }}
                    {{ getTime(ticket.creation) }}
                  </div>
                </button>

              </div>

              <div class="btn-group container-fluid" role="group" aria-label="Basic example">

                <button class="btn  btn-sm" style=" font-size: 0.7rem; font-weight: bold; color: white">
                  <div class="text-left">
                    {{$t('deposit')}}: {{ ticket.deposit }}
                  </div>
                  <div class="text-left">
                    {{$t('rate')}}: {{ (ticket.multiplier).toFixed(2) }}
                  </div>
                  <div v-if="ticket.potentialWin!=null && ticket.potentialWin!=undefined" class="text-left">
                    {{$t('pWin')}}: {{ (ticket.potentialWin).toFixed(2) }}
                  </div>
                  <div v-else class="text-left">
                    {{$t('pWin')}}: {{ (ticket.deposit * ticket.multiplier).toFixed(2) }}
                  </div>
                  <div v-if="ticket.status==1 && ticket.success == 0 && ticket.withdraw != null && ticket.withdraw == 0" class="text-left">
                    <a style="color: white">{{$t('status')}}: </a>
                    <a style="color: red">{{$t('fail')}}</a>
                  </div>
                  <div v-else-if="ticket.status==1" class="text-left">
                    <a style="color: white">{{$t('status')}}: </a>
                    <a style="color: orange">{{$t('notFinished')}}</a>
                  </div>
                  <div v-else-if="ticket.success==0" class="text-left">
                    <a style="color: white">{{$t('status')}}: </a>
                    <a style="color: red">{{$t('fail')}}</a>
                  </div>
                  <div v-else class="text-left">
                    <a style="color: white">{{$t('status')}}: </a>
                    <a style="color: green">{{$t('win')}}</a>
                  </div>
<!--                  <div v-if="ticket.approved==0" class="text-left">-->
<!--                    <a style="color: white">Approved: </a>-->
<!--                    <a style="color: red">Not yet...</a>-->
<!--                  </div>-->
<!--                  <div v-else class="text-left">-->
<!--                    <a style="color: white">Approved: </a>-->
<!--                    <a style="color: green">Yes</a>-->
<!--                  </div>-->
                </button>
                <button class="btn  btn-sm" style="font-size: 0.7rem; font-weight: bold; color: white">
                  {{$t('withdraw')}}
                  <div v-if="ticket.withdraw!=null && ticket.withdraw!=undefined"
                       class="ap page-hero d-flex align-items-center justify-content-center">
                    {{ (ticket.withdraw).toFixed(2) }}
                  </div>
                  <div v-else class="ap page-hero d-flex align-items-center justify-content-center">
                    0
                  </div>
                </button>
              </div>


            </td>

          </tr>
          </tbody>
        </table>

        <div class="text-center container" style="background-color: #373737">

          <div style="">
            <a><img class="" src="../assets/locabetlogo.svg" style="height: 10vh; width: 50vw" align="center"></a>
          </div>
          <h3 style="color: red;">18+</h3>
          <div style="">
            <a><img class="" src="../assets/lbcasinologooo.svg" style="height: 10vh; width: 50vw" align="center"></a>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import PopUpTable from "@/components/PopUpTableOtherBets_Desktop";
import PopUpTableForPairs_Mobile from "@/components/PopUpTableForPairs_Mobile";

export default {
  name: "PlayerTicketsTable_Mobile",
  components: {PopUpTable, PopUpTableForPairs_Mobile},
  data() {
    return {
      tickets: JSON.parse(localStorage.getItem('playerTickets')),
      isMobile: false,
    }
  },

  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted() {
    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})
    this.tickets = this.tickets.reverse()
  },

  methods: {

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    getTime: function (time){
      var date = new Date(time)
      return date.toLocaleString(['en-GB'], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit', second: '2-digit' });
    },

    clickBet: function (ticket) {
      // this.$root.$emit('clickTicket', ticket);
      this.$root.$emit('toggleModalPairs', ticket);
    },
  }
}
</script>

<style scoped>

.my-custom-scrollbar {
  position: relative;
  /*height: 35vh;*/
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

</style>
