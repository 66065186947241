<template>

  <div class="modal" id="myModalForPairs">

    <pop-up-table ref="popUpTable"></pop-up-table>

    <div class="modal-dialog ">
      <div class="modal-content">

        <!-- Modal body -->
        <div class="modal-body ">

          <div class="table-responsive" style="margin-bottom: 0px">
            <table class="table table-sm table-hover table-striped table-dark">
              <thead>
              <tr class="firstrow">
                <th scope="col" class="mb-2 text-right">
                  <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal">X</button>
                </th>
              </tr>
              </thead>
            </table>
          </div>

          <div class="row table-wrapper-scroll-y my-custom-scrollbar">
            <div class="col-12">

              <table class="table table-sm table-hover" style="background-color: #222325; font-size: 0.6rem;">
                <tbody v-if="ticket!=null && ticket!=undefined">
                <tr v-for="pair in ticket.pairs" :key="pair.id">

                  <td class="align-middle">


                    <div class="text-left" style="margin-left: 6px">
                      <button class="btn  btn-sm " style="font-size: 0.8rem; font-weight: bold; color: dodgerblue">
                        <div class="text-left">
                          {{ pair.home }} - {{ pair.away }}
                        </div>
                      </button>
                    </div>

                    <div class="btn-group container-fluid" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-sm btn-dark text-left">
                        <div v-if="find(spaces(pair.marketName))" style="font-size: 0.7rem">
                          {{ $t(spaces(pair.marketName)) }}
                        </div>
                        <div v-else style="font-size: 0.7rem">
                          {{ pair.marketName }}
                        </div>
                      </button>

                      <button type="button" class="btn btn-sm btn-dark text-right">
                        <div v-if="find(spaces(pair.betName)) && pair.settlement == 0" style="font-size: 0.7rem">{{ $t(spaces(pair.betName)) }} {{ margin(pair.betName)}}</div>
                        <div v-else style="font-size: 0.7rem">
                          {{ pair.betName }}
                        </div>
                      </button>

                      <button v-if="pair.settlement == 0" type="button" class="btn btn-sm btn-secondary" style="background-color: #001523">
                        <div style="font-size: 0.7rem;">{{ pair.betOdd }}</div>
                      </button>
                      <button v-if="pair.settlement == 1" type="button" class="btn btn-sm btn-danger" style="background-color: #001523">
                        <div style="font-size: 0.7rem; color: red">{{ pair.betOdd }}</div>
                      </button>
                      <button v-if="pair.settlement == 2 || pair.settlement == 4 || pair.settlement == 5" type="button" class="btn btn-sm btn-success" style="background-color: #001523">
                        <div style="font-size: 0.7rem; color: green">{{ pair.betOdd }}</div>
                      </button>
                      <button v-if="pair.settlement == -1 || pair.settlement == 3" type="button" class="btn btn-sm btn-warning" style="background-color: #001523">
                        <div style="font-size: 0.7rem; color: orange">{{ pair.betOdd }}</div>
                      </button>
                    </div>


                  </td>

                </tr>
                </tbody>
                <tfoot>
                <tr class="footer">
                  <th scope="col">

                    <div class="btn-group container-fluid" role="group" aria-label="Basic example" style="z-index: 999">

                      <button class="btn  btn-sm btn" style="font-size: 0.7rem; font-weight: bold; color: white">
                        <div style="border: 1px solid white">
                          <div class="text-center">
                            {{$t('deposit')}}
                          </div>
                          <div class="text-center">
                            {{ ticket.deposit }}
                          </div>
                        </div>

                        <div class="mt-2" style="border: 1px solid white">
                          <div class="text-center">
                            {{$t('rate')}}
                          </div>
                          <div v-if="ticket.multiplier!=null && ticket.multiplier!=undefined" class="text-center">
                            {{ (ticket.multiplier).toFixed(2) }}
                          </div>
                        </div>
                      </button>

                      <button class="btn  btn-sm" style="font-size: 0.7rem; font-weight: bold; color: white">
                        <div style="border: 1px solid white">
                          <div class="text-center">
                            {{$t('pWin')}}
                          </div>
                          <div v-if="ticket.potentialWin!=null && ticket.potentialWin!=undefined" class="text-center">
                            {{ (ticket.potentialWin).toFixed(2) }}
                          </div>
                          <div v-else class="text-center">
                            {{ (ticket.deposit * ticket.multiplier).toFixed(2) }}
                          </div>
                        </div>

                        <div class="mt-2" style="border: 1px solid white">
                          <div class="text-center">
                            {{$t('withdraw')}}
                          </div>
                          <div v-if="ticket.withdraw!=null && ticket.withdraw!=undefined" class="text-center">
                            {{ (ticket.withdraw).toFixed(2) }}
                          </div>
                          <div v-else class="text-center">
                            0
                          </div>
                        </div>
                      </button>

                      <button class="btn  btn-sm btn" style="font-size: 0.7rem; font-weight: bold; color: white">
                        <div style="border: 1px solid white">
                          <div v-if="ticket.status==1 && ticket.success == 0 && ticket.withdraw != null && ticket.withdraw == 0" class="text-left">
                            <a style="color: white">{{$t('status')}}: </a>
                            <a style="color: red">{{$t('fail')}}</a>
                          </div>
                          <div class="text-center" v-if="ticket.status==1">
                            <a style="color: white">{{$t('status')}}: </a>
                            <a style="color: orange">{{$t('notFinished')}}</a>
                          </div>
                          <div class="text-center" v-else-if="ticket.success==0">
                            <a style="color: white">{{$t('status')}}: </a>
                            <a style="color: red">{{$t('fail')}}</a>
                          </div>
                          <div class="text-center" v-else>
                            <a style="color: white">{{$t('status')}}: </a>
                            <a style="color: green">{{$t('win')}}</a>
                          </div>
                        </div>

<!--                        <div class="mt-2" style="border: 1px solid white">-->
<!--                          <div class="text-center" v-if="ticket.approved==0">-->
<!--                            <a style="color: white">Approved: </a>-->
<!--                            <a style="color: green">Yes</a>-->
<!--                          </div>-->
<!--                          <div class="text-center" v-else>-->
<!--                            <a style="color: white">Approved: </a>-->
<!--                            <a style="color: red">Not yet...</a>-->
<!--                          </div>-->
<!--                        </div>-->
                      </button>

                    </div>

                  </th>
                </tr>
                </tfoot>
              </table>


            </div>
          </div>

        </div>

      </div>
    </div>

  </div>

</template>

<script>

import PopUpTable from "@/components/PopUpTableOtherBets_Desktop";
import router from "@/router/router";

export default {
  name: "PopUpTableForPairs_Mobiles",
  components: {PopUpTable},
  data() {
    return {
      ticket: '',
      isMobile: false,
    }
  },


  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted() {
    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})
  },

  methods: {

    getTimeFormat: function (time) {

      if (time != null && time != 'undefined') {
        const formattedTime = time.split(",");
        return formattedTime
      }

    },

    find(betName){
      if(betName === '1X2' || ((betName.startsWith('1') || betName.startsWith('2')) && betName.length < 3)){
        return false;
      }
      if(this.$te(this.spaces(betName))){
        return true;
      }
      return false;
    },

    spaces(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(!split[i].includes('.')){
            betName += split[i];
          }
        }
      }
      return betName.replaceAll(' ', '');
    },

    margin(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(split[i].includes('.')){
            return split[i];
          }
        }
      }
      return '';
    },

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    pushOnTicketMaker: async function () {

      if (!this.$route.fullPath.includes('TicketMakerView'))
        await router.push('/TicketMakerView')

    },

  },

  created() {

    const self = this;

    this.$root.$on('toggleModalPairs', data => {

      if (data != null && data != undefined) {
        self.ticket = data;
      }

    });

  },

}
</script>

<style scoped>

.oneMarket {
  margin: 6px;
}

.footer {
  position: sticky;
  bottom: 0;
  background-color: #111e26;
}

.modal {
  text-align: center;
}

.modal-content {
  background: url(../assets/secondbg.jpg) center center no-repeat;
}

.modal-dialog {
  text-align: left; /* you'll likely want this */
  min-width: 95%;
  /*width: auto !important;*/
  display: inline-block;
}

.my-custom-scrollbar {
  position: relative;
  height: 85vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  background-color: #222325
}

html, body {
  overflow-x: auto;
  overflow-y: auto;
}

</style>
