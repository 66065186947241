<template>
  <div class="tableList">

    <PopUpTableOtherBets_Desktop ref="popUpTable" :key="componentKey"></PopUpTableOtherBets_Desktop>
    <FloatingAlert language="en"/>

    <div style="height: 50vh;" class="row table-wrapper-scroll-y my-custom-scrollbar">
      <div class="col-12">
        <table class="table table-striped table-sm table-hover table-dark" style="background-color: #2c3e50">

          <thead>
          <tr class="firstrow">
            <th scope="col">Teams</th>
            <th scope="col">Time</th>
            <th scope="col">Result</th>
            <th scope="col">Market</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="event in events" :key="event.id">

            <td class="align-middle" style="padding-left: 10px; padding-right: 10px; font-size: 0.8rem">

              <div class="row ap page-hero d-flex align-items-center justify-content-center"
                   style=" border-radius: 8px; margin-bottom: 4px;">
                {{ event.home }}
              </div>
              <div class="row ap page-hero d-flex align-items-center justify-content-center"
                   style=" border-radius: 8px; margin-bottom: 4px">
                {{ event.away }}
              </div>

            </td>

            <td class="align-middle" style="padding-left: 10px; padding-right: 10px; color: limegreen; font-size: 0.8rem">{{ event.time }}</td>

            <td class="align-middle justify-content-start text-left" style="margin-right: 5px; margin-left: 5px; color: yellow; font-size: 0.8rem; white-space: nowrap">{{ event.result }}</td>

            <td v-if="loadCurrentMarkets(event.id)!=null && loadCurrentMarkets(event.id)!=undefined && loadCurrentMarkets(event.id)[0]!=undefined && loadCurrentMarkets(event.id).length>0"
                class="">
              <div class="ap page-hero d-flex align-items-center justify-content-center"
                   style=" border-radius: 8px; margin-bottom: 4px ">
              </div>
              <div class="oneMarket ap page-hero d-flex align-items-center justify-content-center">

                <div style="margin-right: 10%; font-size: 0.8rem">
                  {{ loadCurrentMarkets(event.id)[0].name }}

                  <b-row>

                    <div style="" v-for="bet in loadCurrentMarkets(event.id)[0].bets.slice(0, 3)" :key="bet.id">


                      <button v-if="selected(bet.id)"
                              @click="clickBet(event.id, loadCurrentMarkets(event.id)[0].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                              class="btn btn-sm h-100 d-inline-block"
                              style="margin-right: 2px; background-color: #f5cf08">

                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.name }}</div>
                        <div v-else style="font-size: 0.7rem">{{ bet.name }}</div>
                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.odd }}</div>
                        <div v-else style="font-size: 0.7rem">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                      </button>
                      <button v-else
                              @click="clickBet(event.id, loadCurrentMarkets(event.id)[0].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                              class="btn btn-secondary btn-sm"
                              style="margin-right: 2px">

                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.name }}</div>
                        <div v-else style="font-size: 0.7rem">{{ bet.name }}</div>
                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.odd }}</div>
                        <div v-else style="font-size: 0.7rem">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                      </button>

                    </div>
                  </b-row>
                </div>
              </div>
            </td>

            <td v-else class="row ap page-hero d-flex align-items-center justify-content-center"
                style=" border-radius: 8px; margin-bottom: 4px; margin-right: 10px; margin-left: 10px">no markets for
              this event
            </td>

          </tr>
          </tbody>
        </table>


      </div>
    </div>

  </div>
</template>

<script>
import PopUpTableOtherBets_Desktop from "@/components/PopUpTableOtherBets_Desktop";
import VueFloatingAlert from 'vue-floating-alert'
import Vue from 'vue'

Vue.use(VueFloatingAlert)

export default {
  name: "LiveBetsTable_Desktop",
  components: {PopUpTableOtherBets_Desktop},
  data() {
    return {
      events: JSON.parse(localStorage.getItem('liveEvents')),
      currentMarkets: '',
      componentKey: 0,
      isMobile: false,
      dummy: ''
    }
  },

  beforeMount() {

    this.loadLiveEvents(localStorage.getItem('currentSport'));

  },


  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  methods: {

    async loadLiveEvents(name) {

      // this.$axios.post('getLiveEvents', {
      //   parentId: name
      // }).then(response => {
      //
      //   localStorage.setItem('liveEvents', JSON.stringify(response.data))
      //
      // }).catch(error => {
      //   console.log(error);
      // })
      localStorage.setItem("testtt", name);
    },

    getBetNumber: function (markets) {

      let count = 0;

      for (let i = 1; i < markets.length; i++) {
        for (let j = 0; j < markets[i].bets.length; j++) {
          count++;
        }
      }

      return count;

    },

    find(betName){
      if(betName === '1X2' || ((betName.startsWith('1') || betName.startsWith('2')) && betName.length < 3)){
        return false;
      }
      if(this.$te(this.spaces(betName))){
        return true;
      }
      return false;
    },

    spaces(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(!split[i].includes('.')){
            betName += split[i];
          }
        }
      }
      return betName.replaceAll(' ', '');
    },

    margin(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(split[i].includes('.')){
            return split[i];
          }
        }
      }
      return '';
    },

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    selected: function (betId) {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId))
          return true
        else return false
      }

      return false
    },

    clickBet(eventId, marketId, betId, home, away, marketName, betName, betOdd) {

      var player = JSON.parse(localStorage.getItem('player'))
      if (player == null || player == undefined) {
        setTimeout(() => {
          this.$floatingAlert.info({
            title: this.$t('Note'),
            message: this.$t('betClick') + '<i class="fa fa-fw fa-grin-wink"></i>',
            timeVisible: 3000,
          })
        }, 1)
        return;
      }

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))
      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId)) {
          this.$root.$emit('remotelyDeleteBet', eventId);
          return;
        }
      }

      this.$root.$emit('clickBet', {eventId, marketId, betId, home, away, marketName, betName, betOdd});
    },

    loadCurrentMarkets: function (eventId) {
      const markets = JSON.parse(localStorage.getItem('markets' + eventId));
      const sorted = ["Odd/Even", "Correct Score", "HT/FT", "Under/Over 1st Period", "1st Period Winner", "Both Teams To Score",
        "Asian Handicap", "Under/Over", "Double Chance", "1X2"];
      markets.sort((a,b) => (sorted.indexOf(a.name) < sorted.indexOf(b.name) ) ? 1 : ((sorted.indexOf(b.name)  < sorted.indexOf(a.name) ) ? -1 : 0))
      return markets;
    },

    loadWantedEvents: function () {
      return this.events.filter(event => event.status == 2)
    },

    toggleModal(eventId, event) {
      const currMarks = JSON.parse(localStorage.getItem('markets' + eventId))
      this.$root.$emit('toggleModal', {currMarks, event});
    },

    loadEvents: function (id) {
      this.$axios.post('getEvents', {
        parentId: id
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))
        this.eventss = response.data;
      }).catch(error => {
        console.log(error);
      })
    },
    loadMarkets: function (eventId) {
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = "markets";
        let str2 = eventId;
        let combo = str1.concat(str2);

        localStorage.setItem(combo, JSON.stringify(response.data))

      }).catch(error => {
        console.log(error);
      })
    }
    ,
  },

  mounted() {

    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

    this.$root.$on('updateTicketsPop2', data => {
      this.dummy = data
      if (this.$refs.popUpTable != undefined && this.$refs.popUpTable != null) {
        this.$refs.popUpTable.$forceUpdate()
      }
    });

  }


}
</script>

<style scoped>

.my-custom-scrollbar {
  position: relative;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-color: #001523;
  font-size: 0.8rem;

}

.scrmob {
  height: 100vh;
}

/*th {*/
/*  color: black;*/
/*}*/

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #001523;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #111e26;
}

.dugme{
  background-image: linear-gradient(to bottom, #00102e, #04619f);
  color: white;
  border-radius: 8px;
  font-size: 0.7rem;
}

</style>
