<template>
  <div>
  <FloatingAlert language="en"/>
    <b-row >
      <b-col sm="12">
        <Navbar></Navbar>
      </b-col>
    </b-row>
  <div class="container center">
  <input class="form-control form-control-sm" type="password" v-model="oldPassword" :placeholder="$t('oldPw')" style="margin-bottom: 15px">
    <input class="form-control form-control-sm" type="password" v-model="newPassword" :placeholder="$t('newPw')" style="margin-bottom: 15px">
    <button class="btn btn-sm btn-success " @click="changePassword">{{$t('save')}}</button>
  </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
name: "ChangePasswordView",
  components: {Navbar},
  data(){
    return{
      player: JSON.parse(localStorage.getItem('player')),
      isMobile: false,
      oldPassword: '',
      newPassword: '',
    }
  },
  mounted() {
    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})
  },

  methods:{
    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    changePassword(){
      if (this.newPassword !== null && this.newPassword !== undefined && this.oldPassword !== null && this.oldPassword !== undefined && this.oldPassword !== '' && this.newPassword !== '') {

        this.$axios.post('updatePassword', {
          playerUsername: this.player.username,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        }).then(response => {
          if((response.data.startsWith("Wrong"))){
            setTimeout(() => {
              this.$floatingAlert.error({
                title: this.$t('failed'),
                message: this.$t('badOldPw') + '<i class="fa fa-fw fa-grin-wink"></i>',
                timeVisible: 2000,
                btnCloseName: this.$t('close')
              })
            }, 1000)
            return;
          }else if(response.data.startsWith("There")){
            setTimeout(() => {
              this.$floatingAlert.failed({
                title: this.$t('failed'),
                message: this.$t('noSuchUser') + '<i class="fa fa-fw fa-grin-wink"></i>',
                timeVisible: 2000,
                btnCloseName: this.$t('close')
              })
            }, 1000)
          }else{
            setTimeout(() => {
              this.$floatingAlert.success({
                title: this.$t('success'),
                message: this.$t('updatedPwSucc') + '<i class="fa fa-fw fa-grin-wink"></i>',
                timeVisible: 2000,
                btnCloseName: this.$t('close')
              })
            }, 1000)
          }

          // localStorage.setItem('player', JSON.stringify(response.data))
          this.$root.$emit('updateNavbar', 1);
          this.$router.push('/home');

        }).catch(error => {
          // if((error.data.startsWith("Wrong"))){
            setTimeout(() => {
              this.$floatingAlert.error({
                title: this.$t('failed'),
                message: this.$t('badOldPw') + '<i class="fa fa-fw fa-grin-wink"></i>',
                timeVisible: 2000,
                btnCloseName: this.$t('close')
              })
            }, 1000)
          console.log(error);
          return;
          // }else if(error.data.startsWith("There")){
          //   setTimeout(() => {
          //     this.$floatingAlert.failed({
          //       title: this.$t('failed'),
          //       message: this.$t('noSuchUser') + '<i class="fa fa-fw fa-grin-wink"></i>',
          //       timeVisible: 2000,
          //       btnCloseName: this.$t('close')
          //     })
          //   }, 1000)
          // }
        })

      }
    }
  },

}
</script>

<style scoped>

.center{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

</style>