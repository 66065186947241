<template>
  <div class="tableList">

    <PopUpTableOtherBets_Desktop ref="popUpTable" :key="componentKey"></PopUpTableOtherBets_Desktop>
    <FloatingAlert language="en"/>

    <div class="table-wrapper-scroll-y my-custom-scrollbar" style="height: 50vh;">
      <div class="col-12">

        <table class="table table-striped table-sm table-hover table-dark" style="background-color: #2c3e50">
<!--          <thead>-->
<!--          <tr class="firstrow" style="font-size: 0.8rem">-->
<!--            <th scope="col">-->
<!--              <button class="btn dugme" style="margin-right:45% ;width: max-content; background-image: linear-gradient(to bottom, #00102e, #04619f);">-->
<!--                <img src="../assets/sport.png" alt="live" style="width: 20px; height: 20px">    24H-->
<!--              </button>-->
<!--              <br>-->
<!--              Time-->
<!--            </th>-->
<!--            <th scope="col"></th>-->
<!--            <th scope="col">1 X 2</th>-->
<!--            <th scope="col">Next Goal?</th>-->
<!--            <th scope="col">Double chance</th>-->
<!--            <th scope="col"></th>-->
<!--          </tr>-->
<!--          </thead>-->
          <tbody>
          <tr v-for="event in preMatches" :key="event.id">

            <td v-if="loadCurrentMarkets(event.id).length > 2" class="align-middle" style="max-width: 100px">

              <div class="ap page-hero d-flex align-items-center" style="font-size: 0.8rem; color: #cfb640">
                <a style="color: dodgerblue; margin-right: 4px;margin-left: 5%; margin-top: 10%;">
                  {{  $t(event.league.country)  }}</a>
              </div>

              <div class="ap page-hero d-flex align-items-start text-left" style="font-size: 0.8rem; color: #cfb640">
                <a style="color: greenyellow; margin-right: 4px;margin-left: 5%;">{{ event.league.name }}</a>
              </div>

              <div class="ap page-hero d-flex align-items-center"
                   style=" border-radius: 8px; margin-bottom: 4px; margin-left: 5%; color: yellow; font-size: 0.8rem;">
<!--                {{ getTimeFormat(event.startTime)[0] }} {{ getTimeFormat(event.startTime)[2] }}-->
                {{ getTime(event.startTime) }}
              </div>

            </td>
            <td v-if="loadCurrentMarkets(event.id).length > 2" class="align-middle" style="margin-right: 10px; margin-left: 10px; font-size: 0.8rem;">

              <div class="ap page-hero d-flex text-left"
                   style=" border-radius: 8px; margin-bottom: 4px;margin-top: 14% ">
                {{ event.home }}
              </div>
              <div class="ap page-hero d-flex text-left"
                   style=" border-radius: 8px; margin-bottom: 4px; ">
                {{ event.away }}
              </div>

            </td>

            <td v-if="loadCurrentMarkets(event.id)!=null && loadCurrentMarkets(event.id)!=undefined && loadCurrentMarkets(event.id)[0]!=undefined && loadCurrentMarkets(event.id).length>2"
                class="align-middle" style="">
              <div class="ap page-hero d-flex align-items-center justify-content-center"
                   style=" border-radius: 8px; margin-bottom: 4px ">
              </div>
              <div class="oneMarket ap page-hero d-flex align-items-center justify-content-center">

                <div style="font-size: 0.8rem; margin-bottom: 15px">
                                    {{ $t(spaces(loadCurrentMarkets(event.id)[0].name)) }}

                  <b-row>

                    <div style="white-space: nowrap" v-for="bet in loadCurrentMarkets(event.id)[0].bets.slice(0, 3)" :key="bet.id">


                      <button v-if="selected(bet.id)"
                              @click="clickBet(event.id, loadCurrentMarkets(event.id)[0].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                              class="btn btn-sm h-100 d-inline-block"
                              style="margin-right: 2px; background-color: #f5cf08">

                        <div v-if="find(bet.name)" style="font-size: 0.7rem">{{ $t(spaces(bet.name)) }} {{ margin(bet.name)}}</div>
                        <div v-else style="font-size: 0.7rem">{{ bet.name }}</div>
                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.odd }}</div>
                        <div v-else style="font-size: 0.7rem">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                      </button>
                      <button v-else
                              @click="clickBet(event.id, loadCurrentMarkets(event.id)[0].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                              class="btn btn-secondary btn-sm"
                              style="margin-right: 2px">

                        <div v-if="find(bet.name)" style="font-size: 0.7rem">{{ $t(spaces(bet.name)) }} {{ margin(bet.name)}}</div>
                        <div v-else style="font-size: 0.7rem">{{ bet.name }}</div>
                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.odd }}</div>
                        <div v-else style="font-size: 0.7rem">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                      </button>

                    </div>
                  </b-row>
                </div>
              </div>
            </td>

            <td v-if="loadCurrentMarkets(event.id)!=null && loadCurrentMarkets(event.id)!=undefined && loadCurrentMarkets(event.id)[0]!=undefined && loadCurrentMarkets(event.id).length>2"
                class="align-middle" style="">
              <div class="ap page-hero d-flex align-items-center justify-content-center"
                   style=" border-radius: 8px; margin-bottom: 4px ">
              </div>
              <div class="oneMarket ap page-hero d-flex align-items-center justify-content-center">


                <div style=" font-size: 0.8rem; margin-bottom: 15px">
                  {{ $t(spaces(loadCurrentMarkets(event.id)[1].name)) }}


                  <b-row>

                    <div style="white-space: nowrap" v-for="bet in loadCurrentMarkets(event.id)[1].bets.slice(0, 3)" :key="bet.id">


                      <button v-if="selected(bet.id)"
                              @click="clickBet(event.id, loadCurrentMarkets(event.id)[1].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                              class="btn btn-sm h-100 d-inline-block"
                              style="margin-right: 2px; background-color: #f5cf08">

                        <div v-if="find(bet.name)" style="font-size: 0.7rem">{{ $t(spaces(bet.name)) }} {{ margin(bet.name)}}</div>
                        <div v-else style="font-size: 0.7rem">{{ bet.name }}</div>
                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.odd }}</div>
                        <div v-else style="font-size: 0.7rem">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                      </button>
                      <button v-else
                              @click="clickBet(event.id, loadCurrentMarkets(event.id)[1].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                              class="btn btn-secondary btn-sm"
                              style="margin-right: 2px">

                        <div v-if="find(bet.name)" style="font-size: 0.7rem">{{ $t(spaces(bet.name)) }} {{ margin(bet.name)}}</div>
                        <div v-else style="font-size: 0.7rem">{{ bet.name }}</div>
                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.odd }}</div>
                        <div v-else style="font-size: 0.7rem">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                      </button>

                    </div>
                  </b-row>
                </div>
              </div>
            </td>

            <td v-if="loadCurrentMarkets(event.id)!=null && loadCurrentMarkets(event.id)!=undefined && loadCurrentMarkets(event.id)[0]!=undefined && loadCurrentMarkets(event.id).length>2"
                class="align-middle" style="">
              <div class="ap page-hero d-flex align-items-center justify-content-center"
                   style=" border-radius: 8px; margin-bottom: 4px ">
              </div>
              <div class="oneMarket ap page-hero d-flex align-items-center justify-content-center">


                <div style="font-size: 0.8rem; margin-bottom: 15px">
                  {{ $t(spaces(loadCurrentMarkets(event.id)[4].name)) }}


                  <b-row>

                    <div style="white-space: nowrap" v-for="bet in loadCurrentMarkets(event.id)[4].bets.slice(0, 3)" :key="bet.id">


                      <button v-if="selected(bet.id)"
                              @click="clickBet(event.id, loadCurrentMarkets(event.id)[4].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                              class="btn btn-sm h-100 d-inline-block"
                              style="margin-right: 2px; background-color: #f5cf08">

<!--                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.name }}</div>-->
                        <div v-if="find(bet.name)" style="font-size: 0.7rem">{{ $t(spaces(bet.name)) }} {{ margin(bet.name)}}</div>
                        <div v-else style="font-size: 0.7rem">{{ bet.name }}</div>
<!--                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.odd }}</div>-->
                        <div  style="font-size: 0.7rem">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                      </button>
                      <button v-else
                              @click="clickBet(event.id, loadCurrentMarkets(event.id)[4].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                              class="btn btn-secondary btn-sm"
                              style="margin-right: 2px">

<!--                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.name }}</div>-->
                        <div v-if="find(bet.name)" style="font-size: 0.7rem">{{ $t(spaces(bet.name)) }} {{ margin(bet.name)}}</div>
                        <div v-else style="font-size: 0.7rem">{{ bet.name }}</div>
<!--                        <div v-if="isMobile" style="font-size: 0.5rem">{{ bet.odd }}</div>-->
                        <div style="font-size: 0.7rem">{{ parseFloat(bet.odd).toFixed(2) }}</div>

                      </button>

                    </div>
                  </b-row>
                </div>
              </div>
            </td>

            <td v-if="loadCurrentMarkets(event.id)!=null && loadCurrentMarkets(event.id)!=undefined && loadCurrentMarkets(event.id).length>2"
                class="align-middle"
                style="margin-right: 10px;">
              <button v-if="isMobile" style="font-size: 0.6rem" data-toggle="modal" data-target="#myModal"
                      class="btn btn-dark my-2 my-sm-0"
                      type="submit" @click.prevent="toggleModal(event.id, event)">...
              </button>
              <button v-else data-toggle="modal" data-target="#myModal" class="btn btn-dark " type="submit"
                      @click.prevent="toggleModal(event.id, event)" style="font-size: 0.7rem; color: #04619f; margin-top: 20%">
                +{{ getBetNumber(loadCurrentMarkets(event.id)) }}
              </button>
            </td>

<!--            <td v-else class="ap page-hero d-flex align-items-center justify-content-center"-->
<!--                style=" border-radius: 8px; margin-right: 10px; margin-left: 10px; column-span: 3">no markets for-->
<!--              this event-->
<!--            </td>-->

          </tr>
          </tbody>
        </table>


      </div>
    </div>

  </div>
</template>

<script>
import PopUpTableOtherBets_Desktop from "@/components/PopUpTableOtherBets_Desktop";
import VueFloatingAlert from 'vue-floating-alert'
import Vue from 'vue'

Vue.use(VueFloatingAlert)

export default {
  name: "FutureBetsTable_Desktop",
  components: {PopUpTableOtherBets_Desktop},
  data() {
    return {
      events: JSON.parse(localStorage.getItem('events')),
      currentMarkets: '',
      componentKey: 0,
      isMobile: false,
      dummy: ''
    }
  },

  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },


  computed: {

    preMatches: function () {
      let eventsToBeFiltered = []
      if (this.events) {
        return this.events.filter(e => e.status == 1)
      }
      return eventsToBeFiltered;
    },

    // preMatches: function () {
    //   let eventsToBeFiltered = []
    //   if(this.events) {
    //     eventsToBeFiltered = this.events
    //     return eventsToBeFiltered.filter(e => e.status == 1 || e.status == 5 || e.status == 6 || e.status == 7 || e.status == 8 || e.status == 9)
    //   }
    //   return eventsToBeFiltered;
    // }

  },

  methods: {

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    find(betName){
      if(betName === '1X2' || ((betName.startsWith('1') || betName.startsWith('2')) && betName.length < 3)){
        return false;
      }
      if(this.$te(this.spaces(betName))){
        return true;
      }
      return false;
    },

    spaces(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(!split[i].includes('.')){
            betName += split[i];
          }
        }
      }
      return betName.replaceAll(' ', '');
    },

    margin(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(split[i].includes('.')){
            return split[i];
          }
        }
      }
      return '';
    },

    getTimeFormat: function (time) {

      if (time != null && time != undefined) {
        const formattedTime = time.split(",");
        return formattedTime
      }

    },

    getTime: function (time){
      var date = new Date(time)
      return date.toLocaleString(['en-GB'], {month: 'short', day: '2-digit', hour: '2-digit', minute:'2-digit', });
    },

    getCurrLeague: function () {
      return JSON.parse(localStorage.getItem('currentLeague'))
    },

    selected: function (betId) {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId))
          return true
        else return false
      }

      return false
    },

    getEventId: function (event) {
      if (event.event == null || event.event == undefined) {
        return event.id;
      }
      return event.event.id;
    },

    getEventHome: function (event) {
      if (event.event == null || event.event == undefined) {
        return event.home;
      }
      return event.event.home;
    },

    getEventAway: function (event) {
      if (event.event == null || event.event == undefined) {
        return event.away;
      }
      return event.event.away;
    },

    getEventStartTime: function (event) {
      if (event.event == null || event.event == undefined) {
        return event.startTime;
      }
      return event.event.startTime;
    },

    getEventEvent: function (event) {
      if (event.event == null || event.event == undefined) {
        return event;
      }
      return event.event;
    },


    getBetNumber: function (markets) {

      let count = 0;

      for (let i = 1; i < markets.length; i++) {
        for (let j = 0; j < markets[i].bets.length; j++) {
          count++;
        }
      }

      return count;

    },

    loadCurrentMarkets: function (eventId) {
      const markets = JSON.parse(localStorage.getItem('markets' + eventId));
      const sorted = ["Odd/Even", "Correct Score", "HT/FT", "Under/Over 1st Period", "1st Period Winner", "Both Teams To Score",
        "Asian Handicap", "Under/Over", "Double Chance", "1X2"];
      markets.sort((a,b) => (sorted.indexOf(a.name) < sorted.indexOf(b.name) ) ? 1 : ((sorted.indexOf(b.name)  < sorted.indexOf(a.name) ) ? -1 : 0))
      return markets;
    },

    clickBet(eventId, marketId, betId, home, away, marketName, betName, betOdd) {

      var player = JSON.parse(localStorage.getItem('player'))
      var cnt = 0;
      if (player == null || player == undefined && cnt == 0) {
        cnt = 1
        setTimeout(() => {
          this.$floatingAlert.info({
            title: this.$t('Note'),
            message: this.$t('betClick') + '<i class="fa fa-fw fa-grin-wink"></i>',
            timeVisible: 3000,
          })
        }, 1)
        return;
      }

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))
      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId)) {
          this.$root.$emit('remotelyDeleteBet', eventId);
          return;
        }
      }

      this.$root.$emit('clickBet', {eventId, marketId, betId, home, away, marketName, betName, betOdd});
      this.$root.$emit('updateTickets', 1);
    },

    toggleModal(eventId, event) {
      const currMarks = JSON.parse(localStorage.getItem('markets' + eventId))
      this.$root.$emit('toggleModal', {currMarks, event});
    },

    loadEvents: function (id) {
      this.$axios.post('getEvents', {
        parentId: id
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))
        this.dummy = response.data;
      }).catch(error => {
        console.log(error);
      })
    },
    loadMarkets: function (eventId) {
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = "markets";
        let str2 = eventId;
        let combo = str1.concat(str2);

        localStorage.setItem(combo, JSON.stringify(response.data))

      }).catch(error => {
        console.log(error);
      })
    }
    ,
  },
  mounted() {

    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

    this.$root.$on('updateTicketsPop', data => {
      this.dummy = data
      if (this.$refs.popUpTable != undefined && this.$refs.popUpTable != null) {
        this.$refs.popUpTable.$forceUpdate()
      }
      this.$root.$emit('updateTicketsPop2', 1);
    });

  }
}
</script>

<style scoped>

.oneMarket {
  margin-right: 6px;
  margin-left: 6px;
}

.my-custom-scrollbar {
  position: relative;
  height: 30vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-color: #001523;
}

.scrmob {
  height: 100vh;
}

/*th {*/
/*  color: black;*/
/*}*/

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #001523;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #111e26;
}

.dugme{
  background-image: linear-gradient(to bottom, #00102e, #04619f);
  color: white;
  border-radius: 8px;
  font-size: 0.7rem;
}


</style>
