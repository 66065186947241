<template>
  <popup-modal ref="popup">
    <h2 class="h2title" style="margin-top: 0">{{ title }}</h2>
    <p class="pmessage">{{ message }}</p>
    <div class="btns">
      <button class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
      <span v-if="warning==1" class="ok-btn" @click="_confirm">{{ okButton }}</span>
      <span v-if="warning==0" class="ok-btn2" @click="_confirm">{{ okButton2 }}</span>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue'

export default {
  name: 'ConfirmDialogue',

  components: { PopupModal },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    okButton2: undefined,
    cancelButton: undefined, // text for cancel button
    warning: 1,

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    showNotice: function (opts = {}) {
      this.warning = 0
      this.title = opts.title
      this.message = opts.message
      this.okButton2 = opts.okButton2
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    back(){
      return this.$t('back');
    },

    showWarning: function (opts = {}) {
      this.warning = 1
      this.title = opts.title
      this.message = opts.message
      this.okButton = opts.okButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm: function() {
      this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel: function() {
      this.$refs.popup.close()
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
}
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  padding: 0.5em 1em;
  background-color: lightcoral;
  color: red;
  border: 2px solid red;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.ok-btn2 {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #111e26;
  border: 2px solid dodgerblue;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #111e26;
  border: 2px solid dodgerblue;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.h2title{
  color: white;
}

.pmessage{
  color: white;
}

</style>