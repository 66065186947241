<template>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a v-if="isMobile" class="navbar-brand" @click="pushOnWelcome"><img class="imgMob" src="../assets/locabetlogo.svg"
                                                                     alt="LocaBet"></a>
    <a v-else class="navbar-brand" @click="pushOnHome"><img class="imgDes" src="../assets/logors.png" alt="LocaBet"></a>

    <div v-if="player==null || player==undefined">
      <LanguageChoser></LanguageChoser>
    </div>
    <div v-else>
      <button class="btn btn-sm btn-outline-light" @click="reloadPlayer"> {{ parseFloat(player.balance).toFixed(2) }}
        {{ player.currency }}</button>
    </div>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">

      <div v-if="player==null || player==undefined" class="form-inline ">
        <input class="form-control form-control-sm" type="text" v-model="username" placeholder="username">
        <input class="form-control form-control-sm" type="password" v-model="password" placeholder="password">
        <button class="btn btn-sm btn-outline-success " @click="login">Log in</button>
      </div>
      <div v-else>
        <button class="btn btn-sm btn-outline-warning" type="submit" @click="showTickets">{{ $t('myTickets') }}</button>
        <a style="margin-right: 10px; margin-left: 10px; color: white">
          <LanguageChoser></LanguageChoser>
        </a>
        <button class="btn btn-sm btn-outline-warning" style="margin-right: 10px;" type="submit" @click="changePassword"><img src="../assets/kljuc.svg" style="width: 18px; height: 18px"></button>
        <button class="btn btn-sm btn-outline-danger" type="submit" @click="logOut">{{ $t('logout') }}</button>
      </div>

    </div>
  </nav>

</template>

<script>
import {mapState} from "vuex";
import router from "@/router/router";
import LanguageChoser from "@/components/LanguageChooser";

export default {
  name: "Navbar",
  components: {LanguageChoser},
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      username: '',
      password: '',
      player: JSON.parse(localStorage.getItem('player')),
      componentKey: 0,
      isMobile: false,
    }
  },

  mounted() {
    if (JSON.parse(localStorage.getItem('player')) != null && JSON.parse(localStorage.getItem('player')) != undefined) {
      this.player = JSON.parse(localStorage.getItem('player'))
    }

    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

  },



  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  methods: {

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    async changePassword(){
      if (!this.$route.fullPath.includes('changePassword'))
        await router.push('/changePassword')
    },


    login: function () {

      if (this.username != null && this.username != undefined && this.password != null && this.password != undefined) {

        this.$axios.post('loginPlayer', {
          username: this.username,
          password: this.password
        }).then(response => {
          if(response.data.balance == undefined && (response.data.startsWith("Wrong")  || response.data.startsWith("Please"))){
            setTimeout(() => {
              this.$floatingAlert.error({
                title: this.$t('failed'),
                message: this.$t('badUorP') + '<i class="fa fa-fw fa-grin-wink"></i>',
                timeVisible: 2000,
                btnCloseName: this.$t('close')
              })
            }, 1000)
            return;
          }

          localStorage.setItem('player', JSON.stringify(response.data))
          this.$root.$emit('updateNavbar', 1);

        }).catch(error => {
          console.log(error);
        })

      }

    },

    logOut: function () {

      localStorage.setItem('player', null)
      this.$root.$emit('updateNavbar', 1);

    },

    reloadPlayer() {
      this.$axios.post('getPlayer', {
        username: this.player.username,
        password: this.player.password
      }).then(response => {

        localStorage.setItem('player', JSON.stringify(response.data))
        this.$root.$emit('updateNavbar', 1);

      }).catch(error => {
        console.log(error);
      })
    },

    showTickets: function () {

      if (this.player != null) {

        this.$axios.post('getTickets', {
          parentId: this.player.id
        }).then(response => {

          localStorage.setItem('playerTickets', JSON.stringify(response.data))

          this.pushOnTicketView()
          this.$root.$emit('updateNavbar', 1);
          // if(!this.$route.fullPath.includes('PlayerTicketsView'))
          //   router.push('/PlayerTicketsView')

        }).catch(error => {
          console.log(error);
        })

        //ovo dole je da bi se osvezio navbar ako je neki tiket prosao, jer player to vidi kada klikne ovo dugme myTickets
        this.$axios.post('getPlayer', {
          username: this.player.username,
          password: this.player.password
        }).then(response => {

          localStorage.setItem('player', JSON.stringify(response.data))
          this.$root.$emit('updateNavbar', 1);

        }).catch(error => {
          console.log(error);
        })

      }

    },

    async pushOnHome() {

      if (!this.$route.fullPath.includes('home'))
        await router.push('/home')

    },

    async pushOnWelcome(){
      if (!this.$route.fullPath.includes('welcome'))
        await router.push('/welcome')
    },

    async pushOnTicketView() {

      if (!this.$route.fullPath.includes('PlayerTicketsView'))
        await router.push('/PlayerTicketsView')

    },


  },
}
</script>

<style scoped>

.navbar {
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-image: linear-gradient(to bottom, #00102e, #04619f);
  border: 1px solid black;
  /*border-radius: 100px;*/
}

.hand {
  cursor: pointer;
}

.imgMob {
  width: 45vw;
  height: 40px;
}

.imgDes {
  height: 55px;
}


</style>