<template>
  <span class="">
    <b-dropdown variant="outline-light" size="sm">
      <template #button-content>
              <span v-if="selected">
                <img :src="selected.src" style="height: 18px; width: 22px"> {{selected.text}}
              </span>
        <span v-else><img :src="getFlag('Germany')" style="height: 18px; width: 22px"> DE</span>
      </template>
      <b-dropdown-item
          :disabled="option.disabled"
          @click="switchLocale(option)"
          v-for="option in options"
          :key="option.value"
      >
        <div>
          <img :src="option.src" style="height: 18px; width: 22px">
          {{option.text}}
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </span>
</template>

<script>

export default {
name: "LanguageChoser",
  data(){
    return{
      selected: JSON.parse(localStorage.getItem('lang')),
      options: [{value: 'ge', text: 'DE', src: require("../assets/flags/Germany.png")},
        {value: 'en', text: 'EN', src: require("../assets/flags/gb.png")},
        {value: 'it', text: 'IT', src: require("../assets/flags/Italy.png")},
        {value: 'fr', text: 'FR', src: require("../assets/flags/France.png")},
        {value: 'sr', text: 'SR', src: require("../assets/flags/Serbia.png")},
        {value: 'cr', text: 'HR', src: require("../assets/flags/Croatia.png")},
        {value: 'al', text: 'AL', src: require("../assets/flags/Albania.png")},
        {value: 'tr', text: 'TR', src: require("../assets/flags/Turkey.png")},
        {value: 'es', text: 'ES', src: require("../assets/flags/Spain.png")},
        ]
    }
  },

  methods:{
    switchLocale(option){
      if(this.$i18n.locale !== option.value){
        this.$i18n.locale = option.value;
        localStorage.setItem('lang', JSON.stringify(option));
        this.selected = option;
      }
    },

    getFlag(flag){
      return require('../assets/flags/' + flag + ".png");
    },
  }
}
</script>

<style scoped>

</style>