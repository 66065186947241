<template>
  <div id="app">
    <div v-if="loading()" class="ap page-hero d-flex align-items-center justify-content-center" >
      <div class="loader"></div>
    </div>
    <router-view v-if="loading()" style="opacity:0.5;"/>
    <router-view v-else style="opacity:1;"/>
<!--    <div v-if="approving()">-->
<!--        <h1>TESTSTSTAT</h1>-->
<!--    </div>-->
  </div>

</template>

<script>

import {mapState} from "vuex";
// import Navbar from "@/components/Navbar";


export default {
  name: 'App',
  components: {
    // Navbar
    //HelloWorld
  },
  computed: {
    ...mapState[('user')],
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      observer: 0,
      shouldSpin: false,
      dummy: '',
      approved: true,
    }
  },

  mounted() {
    this.startInterval()
    this.startApproval()
  },

  methods: {

    loading: function (){
      return this.shouldSpin
    },

    approving: function (){
      return this.approved
    },

    startInterval: function () {
      const self = this
      setInterval(function () {
        var sizeLS = 0;
        Object.keys(localStorage).forEach(function (key) {
          sizeLS++;
          self.dummy = key
        });
        if(self.observer!=sizeLS){
          self.observer = sizeLS
          self.shouldSpin = true
        }
        else self.shouldSpin = false
      }, 500);
    },

    startApproval: function () {
      const self = this
      setInterval(function () {
        const approved1 = localStorage.getItem("approved")
        if(approved1 === "false"){
          self.approved = true
        }else{
          self.approved = false
        }
      }, 500);
    }

  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 97.5%;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-attachment: fixed;
  overflow: hidden;
  /*background: url(assets/backgroundDr.jpg) center center no-repeat;*/
  background-image: radial-gradient(#04619f, #000000);
}

.loader {
  background: url("assets/footballrs.png") center center no-repeat;
  border-radius: 100%;
  width: 69px;
  height: 69px;
  animation: spin 1.5s linear infinite;
  position:absolute;
  top: 50vh;
  z-index: 1000;
  position: absolute;
  opacity:1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


</style>
