<template>

  <div>


      <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    <PopUpTableForLeagues_Mobile ref="PopUpTableLeagues" :key="componentKey"></PopUpTableForLeagues_Mobile>

    <div v-if="isMobile" class="MOBILE">

        <b-row style="margin-bottom: -10px">
          <b-col sm="12">
            <Navbar :key="componentKeyN" ></Navbar>
          </b-col>
        </b-row>

        <div class="navbar1" style="margin-bottom: -40px">

<!--          <div class="row mt-1" style="margin-bottom: -10px">-->

<!--            <div class="col-2">-->
<!--              <div>-->
<!--                <a class="navbar-brand hand" style="color: white; font-size: 0.9rem; margin-left: 10px">today:</a>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="col-4"></div>-->

<!--            <div class="col-2">-->
<!--              <div>-->
<!--                <a class="navbar-brand hand "><img class="uppers" src="../assets/search.png"></a>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="col-2">-->
<!--              <div>-->
<!--                <a class="navbar-brand hand "><img class="uppers" src="../assets/trophy.png"></a>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="col-2">-->
<!--              <div>-->
<!--                <a class="navbar-brand hand "><img class="uppers" src="../assets/timedate.png"></a>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->

          <div class="btn-group mt-1 container-fluid" role="group" aria-label="Basic example">
            <button class="btn  btn-sm" data-toggle="modal" data-target="#myModalForLeagues"
                    @click="loadLeaguesToggle('Football')">
              <div>
                <a class="navbar-brand hand">
                  <img class="nav1" src="../assets/ball.svg"></a>
              </div>
              <div>
                <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">SPORT</a>
              </div>
            </button>
            <button class="btn  btn-sm" @click="pushOnLiveBets">
              <div>
                <a class="navbar-brand hand">
                  <img class="nav1" src="../assets/live.svg"></a>
              </div>
              <div>
                <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">LIVE</a>
              </div>
            </button>
            <button class="btn  btn-sm" @click="pushToCasinoPage()">
              <div>
                <a class="navbar-brand hand">
                  <img class="nav1" src="../assets/casino.svg"></a>
              </div>
              <div>
                <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">CASINO</a>
              </div>
            </button>
          </div>

        </div>

        <b-row class="mt-3 ap page-hero d-flex align-items-center justify-content-center" >
          <b-row class="mt-4 ap page-hero d-flex align-items-center justify-content-center">
            <!--            <b-col sm="12">-->
            <div class="row table-wrapper-scroll-y my-custom-scrollbar">
              <div class="col-12">
                <TicketTable_Mobile :key="componentKey1" ></TicketTable_Mobile>
              </div>
            </div>
            <!--            </b-col>-->
          </b-row>
        </b-row>

      </div>


  </div>

</template>

<script>
import Navbar from "@/components/Navbar";
import TicketTable_Mobile from "@/components/TicketTable_Mobile";
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import router from "@/router/router";
import PopUpTableForLeagues_Mobile from "@/components/PopUpTableForLeagues_Mobile";

export default {
  name: "TicketMakerView",
  components: {PopUpTableForLeagues_Mobile,Navbar, TicketTable_Mobile, ConfirmDialogue },

  data(){
    return{
      componentKeyN: 0,
      componentKey1: 0,
      isMobile: false,
      componentKey: 0,
    }
  },

  beforeDestroy () {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, { passive: true })
  },

  mounted() {

    this.onResize()
    global.addEventListener('resize', this.onResize, { passive: true })

    this.$root.$on('updateNavbar', data => {
      this.componentKeyN += data;
    });

    this.$root.$on('updateLeagues', data => {
      this.componentKey += data;
    });

  },

  methods: {

    onResize () {
      this.isMobile = global.innerWidth < 600
    },

    goToCasinoPage() {
      if(JSON.parse(localStorage.getItem('player')) != null && JSON.parse(localStorage.getItem('player')) != undefined){
        let p = JSON.parse(localStorage.getItem('player'));
        let pw = p.password;
        let password = pw.replaceAll('.','');
        location.href = 'http://casino.loca.bet/autologin/' + password.substr(8, 14) + '---' + p.username;
      }else{
        location.href = 'http://casino.loca.bet';
      }
    },

    async loadLeaguesToggle(name) {

      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {

        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.$root.$emit('toggleModalLeagues', 1);

      }).catch(error => {
        console.log(error);
      })
    },

    async loadLeagues(name){

      if(!this.$route.fullPath.includes('home'))
        await router.push('/home')

      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {

        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.componentKey += 1;
      }).catch(error => {
        console.log(error);
      })
    },



    ticketsCount: function () {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if(clickedBets!=null && clickedBets!=undefined){
        return clickedBets.length
      }

      return 0
    },

    async showMsg(title, msg) {

      await this.$refs.confirmDialogue.showNotice({
        title: title,
        message: msg,
        okButton2: 'Ok',
      })

    },

    async pushOnLiveBets() {

      if(!this.$route.fullPath.includes('LiveBetsView'))
        await router.push('/LiveBetsView')

    },

    async pushOnHome() {

      if(!this.$route.fullPath.includes('home'))
        await router.push('/home')

    },

    async pushOnTicketView() {

      if(!this.$route.fullPath.includes('PlayerTicketsView'))
        await router.push('/PlayerTicketsView')

    },

    showTickets: async function (){

      if(this.player!=null){

        var checker = 0

        this.$axios.post('getTickets', {
          parentId: this.player.id
        }).then(response => {

          localStorage.setItem('playerTickets', JSON.stringify(response.data))

          // if(!this.$route.fullPath.includes('PlayerTicketsView'))
          //   router.push('/PlayerTicketsView')
          checker = 1

        }).catch(error => {
          console.log(error);
        })

        if(checker == 1 && !this.$route.fullPath.includes('PlayerTicketsView'))
          await router.push('/PlayerTicketsView')

      }

    },

    getCurrentSportName: function () {
      const currentSport = (localStorage.getItem('currentSport'))

      return currentSport;

    },

    pushOnTicketMaker: async function (){

      if(!this.$route.fullPath.includes('TicketMakerView'))
        await router.push('/TicketMakerView')

    },

  }

}
</script>

<style scoped>

.betTypes{
  /*background-image: linear-gradient(to bottom,#eb5757, #b82e1f);*/
}

.sports{
  /*background-image: linear-gradient(to bottom,#eb5757, #b82e1f);*/
  border-top: 1px solid black;
}

label:hover,
label:focus {
  box-shadow: 0 0.5em 0.5em -0.4em black;
  transform: translateY(-0.25em);
}

img {
  width: 21px;
  height: 21px
}

.uppers {
  width: 17px;
  height: 17px
}

.navbar1{
  background-image: linear-gradient(to bottom, #00102e ,#04619f);
}

.nav1 {
  width: 25px;
  height: 25px;
}

.my-custom-scrollbar {
  position: relative;
  height: 100vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

</style>
