<template>
  <div>
    <b-row>
      <b-col class="col-sm-3">
        <div style="margin-top:2%; left: 0px; position: absolute;">
          <a v-if="isMobile" class="navbar-brand" @click="pushOnHome"><img class="imgMob" src="../assets/locabetlogo.svg"
                                                                           alt="LocaBet"></a>
          <a v-else class="navbar-brand" @click="pushOnHome"><img class="imgDes" src="../assets/logors.png"
                                                                  alt="LocaBet"></a>
        </div>
      </b-col>

      <b-col class="col-sm-3">

      </b-col>

      <b-col class="col-sm-1">
        <div style="margin-top: 20%; padding-left: 100%">
          <LanguageChoser></LanguageChoser>
        </div>
      </b-col>

      <b-col class="col-sm-5">
        <div v-if="player==null || player==undefined" class="form-inline" style="margin-top:3.5%; margin-left: 13vh">
          <input class="form-control form-control-sm" style="margin-right: 0.7%" type="text" v-model="username"
                 placeholder="username">
          <input class="form-control form-control-sm" style="margin-right: 0.7%" type="password" v-model="password"
                 placeholder="password">
          <button class="btn btn-sm btn-outline-success" style="" @click="login">Log in</button>
        </div>
        <div v-else style="margin-top:3.5%; margin-left: 25vh">
          <button class="btn btn-sm btn-outline-warning" type="submit" @click="showTickets">{{ $t('myTickets') }}</button>
          <a style="margin-right: 25px; margin-left: 25px; color: white">
<!--            {{ $t('Credits') }}:-->
            <button class="btn btn-sm btn-outline-light" @click="reloadPlayer"> {{ parseFloat(player.balance).toFixed(2)}}
              {{ player.currency }}</button>
          </a>
          <button class="btn btn-sm btn-outline-danger" type="submit" @click="logOut">{{ $t('logout') }}</button>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col class="col-sm-3">

      </b-col>
      <b-col class="col-sm-4">
        <div class="btn-group" role="group" aria-label="Basic example"
             style="position: absolute; left: 0px; bottom: 0px">
          <button type="button" class="btn dugme" @click="goToHomePage()" style="border-top-right-radius: 25px;">
            <div style="font-size: 0.8rem">
              <img src="../assets/ball.svg" alt="home" style="width: 18px; height: 18px">
              SPORT
            </div>
          </button>
          <button type="button" class="btn dugme" @click="goToLivePage()" style="border-top-right-radius: 25px;">
            <div style="font-size: 0.8rem">
              <img src="../assets/live.svg" alt="home" style="width: 18px; height: 18px">
              LIVE
            </div>
          </button>
          <button type="button" class="btn dugme" @click="goToCasinoPage" style="border-top-right-radius: 25px;">
            <div style="font-size: 0.8rem">
              <img src="../assets/casino.svg" alt="home" style="width: 18px; height: 18px">
              CASINO
            </div>
          </button>
        </div>
      </b-col>
    </b-row>

  </div>

  <!--  <nav class="navbar navbar-expand-lg navbar-light bg-light">-->
  <!--    <a v-if="isMobile" class="navbar-brand" @click="pushOnHome"><img class="imgMob" src="../assets/logors.png"-->
  <!--                                                                     alt="LocaBet"></a>-->
  <!--    <a v-else class="navbar-brand" @click="pushOnHome"><img class="imgDes" src="../assets/logors.png" alt="LocaBet"></a>-->
  <!--    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"-->
  <!--            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">-->
  <!--      <span class="navbar-toggler-icon"></span>-->
  <!--    </button>-->

  <!--    <div class="collapse navbar-collapse" id="navbarSupportedContent">-->

  <!--      <div v-if="player==null || player==undefined" class="form-inline">-->
  <!--        <input class="form-control form-control-sm" type="text" v-model="username" placeholder="username">-->
  <!--        <input class="form-control form-control-sm" type="password" v-model="password" placeholder="password">-->
  <!--        <button class="btn btn-sm btn-outline-success " @click="login">Log in</button>-->
  <!--      </div>-->
  <!--      <div v-else>-->
  <!--        <button class="btn btn-sm btn-outline-warning" type="submit" @click="showTickets">My tickets</button>-->
  <!--        <a style="margin-right: 25px; margin-left: 25px; color: white">-->
  <!--          Credits:-->
  <!--          <button class="btn btn-sm btn-outline-light"> {{ player.balance }}</button>-->
  <!--        </a>-->
  <!--        <button class="btn btn-sm btn-outline-danger" type="submit" @click="logOut">Log out</button>-->
  <!--      </div>-->

  <!--    </div>-->
  <!--  </nav>-->
</template>

<script>
import {mapState} from "vuex";
import router from "@/router/router";
import LanguageChoser from "@/components/LanguageChooser";

export default {
  name: "Navbar_Desktop",
  components: {LanguageChoser},
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      username: '',
      password: '',
      player: JSON.parse(localStorage.getItem('player')),
      componentKey: 0,
      isMobile: false,

    }
  },

  mounted() {
    if (JSON.parse(localStorage.getItem('player')) != null && JSON.parse(localStorage.getItem('player')) != undefined) {
      this.player = JSON.parse(localStorage.getItem('player'))
    }

    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

  },

  beforeDestroy() {
    if (typeof global === 'undefined') return
    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  methods: {

    goToHomePage() {
      if (!this.$route.fullPath.includes('home'))
        router.push('/home')
      this.$axios.post('getPlayer', {
        username: this.player.username,
        password: this.player.password
      }).then(response => {

        localStorage.setItem('player', JSON.stringify(response.data))
        this.$root.$emit('updateNavbar', 1);

      }).catch(error => {
        console.log(error);
      })
    },

    reloadPlayer() {
      this.$axios.post('getPlayer', {
        username: this.player.username,
        password: this.player.password
      }).then(response => {

        localStorage.setItem('player', JSON.stringify(response.data))
        this.$root.$emit('updateNavbar', 1);

      }).catch(error => {
        console.log(error);
      })
    },

    goToLivePage() {
      if (!this.$route.fullPath.includes('Live'))
        router.push('/LiveBetsView')
      this.$axios.post('getPlayer', {
        username: this.player.username,
        password: this.player.password
      }).then(response => {

        localStorage.setItem('player', JSON.stringify(response.data))
        this.$root.$emit('updateNavbar', 1);

      }).catch(error => {
        console.log(error);
      })
    },

    goToCasinoPage() {
      if(JSON.parse(localStorage.getItem('player')) != null && JSON.parse(localStorage.getItem('player')) != undefined){
        let p = JSON.parse(localStorage.getItem('player'));
        let pw = p.password;
        let password = pw.replaceAll('.','');
        location.href = 'http://casino.loca.bet/autologin/' + password.substr(8, 14) + '---' + p.username;
      }else{
        location.href = 'http://casino.loca.bet';
      }
    },

    onResize() {
      this.isMobile = global.innerWidth < 600

    },

    login: function () {

      if (this.username != null && this.username != undefined && this.password != null && this.password != undefined) {

        this.$axios.post('loginPlayer', {
          username: this.username,
          password: this.password
        }).then(response => {
          if (response.data.balance == undefined && (response.data.startsWith("Wrong") || response.data.startsWith("Please"))) {
            setTimeout(() => {
              this.$floatingAlert.error({
                title: this.$t('failed'),
                message: this.$t('badUorP') + '<i class="fa fa-fw fa-grin-wink"></i>',
                timeVisible: 3000,
                btnCloseName: this.$t('close')
              })
            }, 1000)
            return;
          }

          localStorage.setItem('player', JSON.stringify(response.data))
          this.$root.$emit('updateNavbar', 1);

        }).catch(error => {
          console.log(error);
        })

      }

    },

    logOut: function () {

      localStorage.setItem('player', null)
      this.$root.$emit('updateNavbar', 1);

    },

    showTickets: function () {

      if (this.player != null) {

        this.$axios.post('getTickets', {
          parentId: this.player.id
        }).then(response => {

          localStorage.setItem('playerTickets', JSON.stringify(response.data))

          this.pushOnTicketView()
          this.$root.$emit('updateNavbar', 1);
          // if(!this.$route.fullPath.includes('PlayerTicketsView'))
          //   router.push('/PlayerTicketsView')

        }).catch(error => {
          console.log(error);
        })

        //ovo dole je da bi se osvezio navbar ako je neki tiket prosao, jer player to vidi kada klikne ovo dugme myTickets
        this.$axios.post('getPlayer', {
          username: this.player.username,
          password: this.player.password
        }).then(response => {

          localStorage.setItem('player', JSON.stringify(response.data))
          this.$root.$emit('updateNavbar', 1);

        }).catch(error => {
          console.log(error);
        })

      }

    },

    async pushOnHome() {

      if (!this.$route.fullPath.includes('home'))
        await router.push('/home')
      this.$axios.post('getPlayer', {
        username: this.player.username,
        password: this.player.password
      }).then(response => {

        localStorage.setItem('player', JSON.stringify(response.data))
        this.$root.$emit('updateNavbar', 1);

      }).catch(error => {
        console.log(error);
      })

    },

    async pushOnTicketView() {

      if (!this.$route.fullPath.includes('PlayerTicketsView'))
        await router.push('/PlayerTicketsView')
      this.$axios.post('getPlayer', {
        username: this.player.username,
        password: this.player.password
      }).then(response => {

        localStorage.setItem('player', JSON.stringify(response.data))
        this.$root.$emit('updateNavbar', 1);

      }).catch(error => {
        console.log(error);
      })

    },


  },
}
</script>
}

<style scoped>

.navbar {
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-image: linear-gradient(to bottom, #00102e, #04619f);
  border: 1px solid black;
  /*border-radius: 100px;*/
}

.hand {
  cursor: pointer;
}

.imgMob {
  width: 45vw;
  height: 40px;
}

.imgDes {
  height: 55px;
}

.dugme {
  background-image: linear-gradient(to bottom, #00102e, #04619f);
  color: white;
  border-top-right-radius: 25px;
}


</style>