"use strict";

import Vue from 'vue';
import axios from "axios";
// import router from "@/router";
// import router from "../router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
//axios.defaults.headers.common['Authorization'] = localStorage.getItem('jwt');
// axios.defaults.headers.post['Content-Type'] = 'application/json';

let config = {
    baseURL: 'http://loca.bet:8081/api/',
    // timeout: 60 * 1000, // Timeout
    //withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        //const jwt = localStorage.getItem('jwt');
        //if(jwt)
           // config.headers.Authorization = jwt
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        // Do something with response error

        if (error && error.response && error.response.status === 401) {
            // router.push({name: 'Login'});
        }

        return Promise.reject(error);
    }
);

// eslint-disable-next-line no-unused-vars
Plugin.install = function (Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;