<template>
  <div class="tableList" style="margin-top: -35px">

    <pop-up-table ref="popUpTable"></pop-up-table>
    <PopUpTableForPairs_Desktop ref="PopUpTablePairs"></PopUpTableForPairs_Desktop>

    <div class="DESKTOP" style="margin-top: 4%">

      <div class="table-wrapper-scroll-y my-custom-scrollbar">
        <div class="col-12">

          <table class="table table-sm table-striped table-dark table-hover" style="">
            <thead>
            <tr class="firstrow">
              <th scope="col">ID</th>
              <th scope="col">Creation</th>
              <th scope="col">{{$t('deposit')}}</th>
              <th scope="col">{{$t('rate')}}</th>
              <th scope="col">{{$t('pWin')}}</th>
              <th scope="col">{{$t('withdraw')}}</th>
              <th scope="col">{{$t('status')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="ticket in this.tickets" :key="ticket.id" data-toggle="modal" data-target="#myModalForPairs"
                @click="clickBet(ticket)">

              <td class="align-middle" style="margin-right: 10px; margin-left: 10px">
                <div class="ap page-hero d-flex align-items-center justify-content-center"
                     style=" border-radius: 8px; margin-bottom: 4px">
                  #{{ ticket.id }}
                </div>
              </td>

              <td class="align-middle" style="margin-right: 10px; margin-left: 10px">

                <div class="ap page-hero d-flex align-items-center justify-content-center"
                     style=" border-radius: 8px; margin-bottom: 4px">
                  {{ getTime(ticket.creation) }}
                </div>
                <!--                <div class="ap page-hero d-flex align-items-center justify-content-center" style=" border-radius: 8px; margin-bottom: 4px">-->
                <!--                  {{ ticket.creation }}-->
                <!--                </div>-->

              </td>
              <td class="align-middle">

                <div class="ap page-hero d-flex align-items-center justify-content-center"
                     style=" border-radius: 8px; margin-bottom: 4px">
                  {{ ticket.deposit }}
                </div>

              </td>

              <td class="align-middle" style="margin-right: 10px; margin-left: 10px">

                <div class="ap page-hero d-flex align-items-center justify-content-center"
                     style=" border-radius: 8px; margin-bottom: 4px">
                  {{ ticket.multiplier }}
                </div>

              </td>

              <td class="align-middle" style="margin-right: 10px; margin-left: 10px">

                <div v-if="ticket.potentialWin!=null && ticket.potentialWin!=undefined"
                     class="ap page-hero d-flex align-items-center justify-content-center"
                     style=" border-radius: 8px; margin-bottom: 4px">
                  {{ (ticket.potentialWin).toFixed(2) }}
                </div>
                <div v-else class="ap page-hero d-flex align-items-center justify-content-center"
                     style=" border-radius: 8px; margin-bottom: 4px">
                  {{ (ticket.deposit * ticket.multiplier).toFixed(2) }}
                </div>

              </td>

              <td class="align-middle" style="margin-right: 10px; margin-left: 10px">

                <div v-if="ticket.withdraw!=null && ticket.withdraw!=undefined"
                     class="ap page-hero d-flex align-items-center justify-content-center"
                     style=" border-radius: 8px; margin-bottom: 4px">
                  {{ (ticket.withdraw).toFixed(2) }}
                </div>
                <div v-else class="ap page-hero d-flex align-items-center justify-content-center"
                     style=" border-radius: 8px; margin-bottom: 4px">
                  0
                </div>

              </td>

              <td class="align-middle" style="margin-right: 10px; margin-left: 10px">

                <div>
                  <b-row>
                    <div class="text-center" v-if="ticket.status==1">
                      <a style="color: white">{{$t('status')}}: </a>
                      <a style="color: orange">{{$t('notFinished')}}</a>
                    </div>
                    <div class="text-center" v-else-if="ticket.success==0">
                      <a style="color: white">{{$t('status')}}: </a>
                      <a style="color: red">{{$t('fail')}}</a>
                    </div>
                    <div class="text-center" v-else>
                      <a style="color: white">{{$t('status')}}: </a>
                      <a style="color: green">{{$t('win')}}</a>
                    </div>
                  </b-row>
<!--                  <b-row>-->
<!--                    <div class="text-center" v-if="ticket.approved==0">-->
<!--                      <a style="color: white">Approved: </a>-->
<!--                      <a style="color: green">Yes</a>-->
<!--                    </div>-->
<!--                    <div class="text-center" v-else>-->
<!--                      <a style="color: white">Approved: </a>-->
<!--                      <a style="color: red">Not yet...</a>-->
<!--                    </div>-->
<!--                  </b-row>-->
                </div>

              </td>

            </tr>
            </tbody>
          </table>


        </div>
      </div>

    </div>


  </div>
</template>

<script>
import PopUpTable from "@/components/PopUpTableOtherBets_Desktop";
import PopUpTableForPairs_Desktop from "@/components/PopUpTableForPairs_Desktop";

export default {
  name: "PlayerTicketsTable_Desktop",
  components: {PopUpTable, PopUpTableForPairs_Desktop},
  data() {
    return {
      tickets: JSON.parse(localStorage.getItem('playerTickets')),
      currentMarkets: '',
      isMobile: false,
    }
  },

  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted() {
    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})
    this.tickets = this.tickets.reverse()
  },

  methods: {

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    getTime: function (time){
      var date = new Date(time)
      return date.toLocaleString(['en-GB'], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute:'2-digit', second: '2-digit' });
    },


    clickBet: function (ticket) {
      // this.$root.$emit('clickTicket', ticket);
      this.$root.$emit('toggleModalPairs', ticket);
    },

    getTimeFormat: function (time) {

      if (time != null && time != 'undefined') {
        const formattedTime = time.split("T");
        return formattedTime
      }

    }
  }
}
</script>

<style scoped>

.oneMarket {
  margin: 6px;
}

.my-custom-scrollbar {
  position: relative;
  height: 82vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  /*background-image: linear-gradient(to right, rgba(19, 78, 94, 0.7), rgba(113, 178, 128, 0.7));*/
  background-color: #001523;
}

.scrmob {
  height: 100vh;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #001523;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #111e26;
}

</style>