import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home";
import Welcome from "@/views/Welcome";
import LiveBetsView from "@/views/LiveBetsView";
import PlayerTicketsView from "@/views/PlayerTicketsView";
import TicketMakerView from "@/views/TicketMakerView";
import AutoLoginView from "@/views/AutoLoginView";
import ChangePasswordView from "@/views/ChangePasswordView";

Vue.use(VueRouter)


const routes = [

    {
        path: '/welcome',
        name: 'welcome',
        component: Welcome
    },

    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/LiveBetsView',
        name: 'LiveBetsView',
        component: LiveBetsView
    },
    {
        path: '/PlayerTicketsView',
        name: 'PlayerTicketsView',
        component: PlayerTicketsView
    },
    {
        path: '/TicketMakerView',
        name: 'TicketMakerView',
        component: TicketMakerView
    },
    {
        path: '/autologin/:creds',
        name: 'AutoLoginView',
        component: AutoLoginView
    },
    {
        path: '/changePassword',
        name: 'ChangePasswordView',
        component: ChangePasswordView
    },

    { path: '*', redirect: '/welcome' },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router