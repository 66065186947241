<template>
<div>

</div>
</template>

<script>
export default {
name: "AutoLoginView",

  data() {
    return {
      player: JSON.parse(localStorage.getItem('player')),
    }
  },

  beforeCreate() {
    let path = this.$route.path;
    let creds = path.split('/');
    console.log(creds);
    let creddd = creds[2].split('----');
    let username = creddd[1];
    let password = creddd[0];
    this.$axios.post('userCheck', {
      username: username,
      password: password
    }).then(response => {

      localStorage.setItem('player', JSON.stringify(response.data))

      // if(!this.$route.fullPath.includes('PlayerTicketsView'))
      //   router.push('/PlayerTicketsView')
      this.$router.push('/welcome')
      this.$root.$emit('updateNavbar', 1);

    }).catch(error => {
      console.log(error)
      this.$router.push('/welcome')
    })

  }


}
</script>

<style scoped>

</style>