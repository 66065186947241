
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    components: {},
    state: {
        transactions: [],
        user: Object,
        data: '',
        list: [],
        tickets: [],
        payments: [],
        leagues: [],
        lang: null,
    },

    beforeCreate() {
        localStorage.setItem('eventIds', JSON.stringify([]))
        localStorage.setItem('marketIds', JSON.stringify([]))
        localStorage.setItem('betIds', JSON.stringify([]))
        localStorage.setItem('eventsHomes', JSON.stringify([]))
        localStorage.setItem('eventsAways', JSON.stringify([]))
        localStorage.setItem('marketsNames', JSON.stringify([]))
        localStorage.setItem('betNames', JSON.stringify([]))
        localStorage.setItem('betOdds', JSON.stringify([]))
    },

})
