<template>
  <div class="tableList">

    <PopUpTableOtherBets_Mobile ref="popUpTable" :key="componentKey"></PopUpTableOtherBets_Mobile>
    <FloatingAlert language="en"/>

    <div class="table-wrapper-scroll-y my-custom-scrollbar" style="height: 100vh; padding-bottom: 40vh">
      <table class="table table-sm table-hover table-striped table-dark">

        <thead>
        <tr class="firstrow" style=" font-size: 0.9rem">

          <th scope="col" v-if="this.events!=null && this.events!=undefined && this.events.length>0">
            {{ $t(this.events[0].league.sport.name.toLowerCase()) }}
          </th>
          <th scope="col">
            {{ $t('market') }}
          </th>

        </tr>
        </thead>

        <tbody>
        <tr v-for="event in preMatches" :key="event.id">

          <td v-if="loadCurrentMarkets(event.id).length > 0" style="width: 40vw;">

            <button class="btn btn-sm text-left" style="margin-left: 5px">

              <div style="font-size: 0.6rem; color: #cfb640">
                <a style="color: dodgerblue; margin-right: 4px">{{ $t(event.league.country) }}</a>
                <a style="color: greenyellow">{{ event.league.name }}</a>
              </div>
              <div style="margin-bottom: 4px;  font-size: 0.6rem; color: #cfb640">
                {{ getTime(event.startTime) }}
              </div>
              <div style="font-size: 0.8rem;color: white;">
                {{ event.home }}
              </div>
              <div style="font-size: 0.8rem;margin-bottom: 4px; color: white;">
                {{ event.away }}
              </div>

            </button>

          </td>

          <td v-if="loadCurrentMarkets(event.id).length > 0">

            <div
                v-if="loadCurrentMarkets(event.id)!=null && loadCurrentMarkets(event.id)!=undefined && loadCurrentMarkets(event.id)[0]!=undefined && loadCurrentMarkets(event.id).length>0"
                class=" oneMarket ap page-hero d-flex align-items-center justify-content-center"
                style="height: 100px; width: 60vw">

              <div v-for="bet in loadCurrentMarkets(event.id)[0].bets" :key="bet.id"
                   class="h-100 d-inline-block ap page-hero d-flex align-items-center justify-content-center">

                <div v-if="loadCurrentMarkets(event.id)[0].id == 1" style="height: 100px;">

                  <button v-if="selected(bet.id)"
                          @click="clickBet(event.id, loadCurrentMarkets(getEventId(event))[0].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                          class="btn btn-sm h-100 d-inline-block"
                          style="margin-right: 2px; height: 100%;  background-color: #f5cf08">

                    <div v-if="find(bet.name)" style="font-size: 0.7rem">{{ $t(spaces(bet.name)) }}</div>
                    <div v-else style="font-size: 0.7rem">{{ bet.name }}</div>
                    <div style="font-size: 0.7rem; color: black; font-weight: bold">{{
                        parseFloat(bet.odd).toFixed(2)
                      }}
                    </div>

                  </button>
                  <button v-else
                          @click="clickBet(event.id, loadCurrentMarkets(event.id)[0].id , bet.id, event.home, event.away, loadCurrentMarkets(event.id)[0].name, bet.name, bet.odd)"
                          class="btn btn-sm h-100 d-inline-block"
                          style="margin-right: 2px; height: 100%;  background-color: #d0e9ff ; border: 2px solid #38596f">

                    <div v-if="find(bet.name)" style="font-size: 0.7rem; color:black;">{{ $t(spaces(bet.name)) }}</div>
                    <div v-else style="font-size: 0.7rem; color:black;">{{ bet.name }}</div>
                    <div style="font-size: 0.7rem; color: black; font-weight: bold">{{
                        parseFloat(bet.odd).toFixed(2)
                      }}
                    </div>
                  </button>

                </div>

              </div>

              <div v-if="selectedOtherBet(event.id)" style="height: 100%;">
                <button
                    v-if="loadCurrentMarkets(event.id)!=null && loadCurrentMarkets(event.id)!=undefined && loadCurrentMarkets(event.id).length>0"
                    style="font-size: 0.7rem; height: 100%; "
                    data-toggle="modal" data-target="#myModal" class="btn btn-primary btn-sm"
                    type="submit" @click.prevent="toggleModal(event.id, event)">
                  <strong>+</strong>
                </button>
              </div>
              <div v-else style="height: 100%;">
                <button
                    v-if="loadCurrentMarkets(event.id)!=null && loadCurrentMarkets(event.id)!=undefined && loadCurrentMarkets(event.id).length>0"
                    style="font-size: 0.7rem; height: 100%; font-weight: bold"
                    data-toggle="modal" data-target="#myModal" class="btn btn-primary btn-sm"
                    type="submit" @click.prevent="toggleModal(event.id, event)">
                  <strong>+</strong>
                </button>
              </div>

            </div>
          </td>

        </tr>

        </tbody>

        <tfoot align="center" class="">


        <!--        <a><img class="" src="../assets/lbcasinologooo.svg"></a>-->

        </tfoot>

      </table>

      <div class="text-center container" style="background-color: #021130">

        <div style="">
          <a><img class="" src="../assets/locabetlogo.svg" style="height: 10vh; width: 50vw" align="center"></a>
        </div>
        <h3 style="color: red;">18+</h3>
        <div style="">
          <a><img class="" src="../assets/lbcasinologooo.svg" style="height: 10vh; width: 50vw" align="center"></a>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import PopUpTableOtherBets_Mobile from "@/components/PopUpTableOtherBets_Mobile";
import VueFloatingAlert from 'vue-floating-alert'
import Vue from 'vue'

Vue.use(VueFloatingAlert)

export default {
  name: "FutureBetsTable_Mobile",
  components: {PopUpTableOtherBets_Mobile},
  data() {
    return {
      events: JSON.parse(localStorage.getItem('events')),
      currentMarkets: '',
      componentKey: 0,
      componentKey1: 0,
      isMobile: false,
      dummy: ''
    }
  },

  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },


  computed: {

    preMatches: function () {
      let eventsToBeFiltered = []
      if (this.events) {
        return this.events.filter(e => e.status == 1)
      }
      return eventsToBeFiltered;
    },

    // preMatches: function () {
    //   let eventsToBeFiltered = []
    //   if(this.events) {
    //     eventsToBeFiltered = this.events
    //     return eventsToBeFiltered.filter(e => e.status == 1 || e.status == 5 || e.status == 6 || e.status == 7 || e.status == 8 || e.status == 9)
    //   }
    //   return eventsToBeFiltered;
    // }

  },

  methods: {

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    find(betName) {
      if (betName === '1X2' || ((betName.startsWith('1') || betName.startsWith('2')) && betName.length < 3)) {
        return false;
      }
      if (this.$te(this.spaces(betName))) {
        return true;
      }
      return false;
    },

    spaces(betName) {
      return betName.replaceAll(' ', '');
    },

    getTime: function (time) {
      var date = new Date(time)
      return date.toLocaleString(['en-GB'], {month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit',});
    },

    getTimeFormat: function (time) {

      if (time != null && time != undefined) {
        const formattedTime = time.split(",");
        return formattedTime
      }

    },

    getCurrLeague: function () {
      return JSON.parse(localStorage.getItem('currentLeague'))
    },

    selected: function (betId) {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId))
          return true
        else return false
      }

      return false
    },

    selectedOtherBet: function (eventId) {

      const markets = JSON.parse(localStorage.getItem('markets' + eventId))
      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (markets != null && markets != undefined && clickedBets != null && clickedBets != undefined) {
        for (let i = 1; i < markets.length; i++) {
          for (let j = 0; j < markets[i].bets.length; j++) {
            if (clickedBets.includes(markets[i].bets[j].id))
              return true
          }
        }
      }

      return false
    },

    getEventId: function (event) {
      if (event.event == null || event.event == undefined) {
        return event.id;
      }
      return event.event.id;
    },

    getEventHome: function (event) {
      if (event.event == null || event.event == undefined) {
        return event.home;
      }
      return event.event.home;
    },

    getEventAway: function (event) {
      if (event.event == null || event.event == undefined) {
        return event.away;
      }
      return event.event.away;
    },

    getEventStartTime: function (event) {
      if (event.event == null || event.event == undefined) {
        return event.startTime;
      }
      return event.event.startTime;
    },

    getEventEvent: function (event) {
      if (event.event == null || event.event == undefined) {
        return event;
      }
      return event.event;
    },


    getBetNumber: function (markets) {

      let count = 0;

      for (let i = 1; i < markets.length; i++) {
        for (let j = 0; j < markets[i].bets.length; j++) {
          count++;
        }
      }

      return count;

    },

    loadCurrentMarkets: function (eventId) {
      const markets = JSON.parse(localStorage.getItem('markets' + eventId));
      const sorted = ["Odd/Even", "Correct Score", "HT/FT", "Under/Over 1st Period", "1st Period Winner", "Both Teams To Score",
        "Asian Handicap", "Under/Over", "Double Chance", "1X2"];
      markets.sort((a, b) => (sorted.indexOf(a.name) < sorted.indexOf(b.name)) ? 1 : ((sorted.indexOf(b.name) < sorted.indexOf(a.name)) ? -1 : 0))
      return markets;
    },

    clickBet(eventId, marketId, betId, home, away, marketName, betName, betOdd) {

      var player = JSON.parse(localStorage.getItem('player'))
      var cnt = 0;
      if (player == null || player == undefined && cnt == 0) {
        cnt = 1
        setTimeout(() => {
          this.$floatingAlert.info({
            title: this.$t('Note'),
            message: this.$t('betClick') + '<i class="fa fa-fw fa-grin-wink"></i>',
            timeVisible: 3000,
          })
        }, 1)
        return;
      }

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))
      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId)) {
          this.$root.$emit('remotelyDeleteBet', eventId);
          return;
        }
      }

      this.$root.$emit('clickBet', {eventId, marketId, betId, home, away, marketName, betName, betOdd});
    },

    startLoading() {
      this.intervalThread = setInterval(() => {
        localStorage.setItem('dummy' + Math.random(), "[]");
      }, 450);
    },

    closeLoading() {
      Object.keys(localStorage).forEach(function (key) {
        if (key.includes('dummy')) {
          localStorage.removeItem(key)
        }
      });
      clearInterval(this.intervalThread);
    },

    toggleModal(eventId, event) {
      this.startLoading();
      this.$axios.post('loadBetsForEvent', {
        parentId: eventId
      }).then(response => {

        // let str1 = 'markets';
        // let str2 = eventId;
        // let combo = str1.concat(str2);


        // this.$root.$emit('updateLiveTable', 1);
        // this.$root.$emit('updateLiveTableMobile', 1);
        // // this.$root.$emit('popUpRefreshData', response.data);
        // // this.$refs.popUpTable.$forceUpdate();
        // localStorage.setItem(combo, JSON.stringify(response.data))
        // const eventIdToCheck = localStorage.getItem('EventInOtherBets');
        // if (eventIdToCheck !== undefined && eventId == eventIdToCheck) {
        //   this.$root.$emit('popUpRefreshData', response.data);
        // }
        localStorage.setItem('EventInOtherBets', eventId);
        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);
        localStorage.setItem(combo, JSON.stringify(response.data))
        const currMarks = response.data;
        this.$root.$emit('toggleModal', {currMarks, event});
        this.closeLoading();

      }).catch(error => {
        this.closeLoading();
        console.log(error);
      })


    },

    loadEvents: function (id) {
      this.$axios.post('getEvents', {
        parentId: id
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))
        this.dummy = response.data;
      }).catch(error => {
        console.log(error);
      })
    },
    loadMarkets: function (eventId) {
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = "markets";
        let str2 = eventId;
        let combo = str1.concat(str2);

        localStorage.setItem(combo, JSON.stringify(response.data))

      }).catch(error => {
        console.log(error);
      })
    }
    ,
  },
  mounted() {

    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

    this.$root.$on('updateTicketsPop', data => {
      this.dummy = data
      if (this.$refs.popUpTable != undefined && this.$refs.popUpTable != null) {
        this.$refs.popUpTable.$forceUpdate();
        this.$root.$emit('updateTickets', 1);
      }
      // this.$root.$emit('updateTicketsPop2', 1);
    });

  }

}
</script>

<style scoped>

.oneMarket {
  margin-right: 6px;
  margin-left: 6px;
}

.my-custom-scrollbar {
  position: relative;
  height: 38vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-color: #d0e9ff;
}

th {
  color: black;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #001523;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #111e26;
}


</style>
