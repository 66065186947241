<template>
  <div class="tableList">

    <div class="row table-wrapper-scroll-y my-custom-scrollbar" style="height: 82vh; width: 30vh">
      <div class="col-12">

        <table class="table table-sm table-dark" style="background-color: #001523">

          <!--          <thead>-->
          <!--          <tr class="firstrow">-->
          <!--            <th scope="col">Leagues</th>-->
          <!--          </tr>-->
          <!--          </thead>-->

          <tbody>

                    <tr class="text-left" v-for="league in this.leaguesN" :key="league.id" @click="loadEvents(league.id, league.name)">
          <!--            <div v-if="league.name.length >15 && formatTxt(league.name).length > 0"-->
          <!--                 class="row ap page-hero d-flex align-items-center justify-content-center"-->
          <!--                 style="margin-right: 10px; margin-left: 10px">-->

          <!--              <td style="color: white">-->
          <!--                <div>-->
          <!--                  {{ formatTxt(league.name)[0] }}-->
          <!--                </div>-->
          <!--                <div>-->
          <!--                  {{ formatTxt(league.name)[1] }}-->
          <!--                </div>-->
          <!--              </td>-->

          <!--            </div>-->
                      <div class="row ap page-hero d-flex"
                           style="margin-right: 1px; margin-left: 1px">

                        <td class="align-middle" style="color: white; font-size: 0.73rem"><img :src="loadImg(league)" style="width: 18px; height: 12px"> {{ league.name }}</td>

                      </div>
                    </tr>

<!--          <tr v-for="league in this.leaguesN" :key="league.id">-->
<!--            &lt;!&ndash;            <div v-if="league.name.length >15 && formatTxt(league.name).length > 0"&ndash;&gt;-->
<!--            &lt;!&ndash;                 class="row ap page-hero d-flex align-items-center justify-content-center"&ndash;&gt;-->
<!--            &lt;!&ndash;                 style="margin-right: 10px; margin-left: 10px">&ndash;&gt;-->

<!--            &lt;!&ndash;              <td style="color: white">&ndash;&gt;-->
<!--            &lt;!&ndash;                <div>&ndash;&gt;-->
<!--            &lt;!&ndash;                  {{ formatTxt(league.name)[0] }}&ndash;&gt;-->
<!--            &lt;!&ndash;                </div>&ndash;&gt;-->
<!--            &lt;!&ndash;                <div>&ndash;&gt;-->
<!--            &lt;!&ndash;                  {{ formatTxt(league.name)[1] }}&ndash;&gt;-->
<!--            &lt;!&ndash;                </div>&ndash;&gt;-->
<!--            &lt;!&ndash;              </td>&ndash;&gt;-->

<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            <div class="row ap page-hero d-flex"-->
<!--                 style="margin-right: 1px; margin-left: 1px">-->

<!--              <td class="align-middle" style="color: white; font-size: 0.8rem"><img :src="loadImg(league)"-->
<!--                                                                                    style="width: 18px; height: 12px">-->
<!--                <ul>-->
<!--                  <li @click="toggleLeague(league.leagues)">{{ league.country }}-->
<!--                    <ul :id="sinL.name" v-for="sinL in league.leagues" :key="sinL.id" @click="loadEvents(sinL.id, sinL.name)"  class="closed">-->
<!--                      <img :src="loadImg(sinL)"-->
<!--                           style="width: 18px; height: 12px">-->
<!--                        {{ sinL.name }}-->
<!--                    </ul>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </td>-->

<!--            </div>-->
<!--          </tr>-->

          </tbody>
        </table>


      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "LeaugesTable_Desktop",
  data() {
    return {
      leaguesN: JSON.parse(localStorage.getItem('leagues'))
    }
  },
  methods: {

    formatTxt: function (txt) {

      if (txt.length > 15) {
        var s = txt;

        var middle = Math.floor(s.length / 2);
        var before = s.lastIndexOf(' ', middle);
        var after = s.indexOf(' ', middle + 1);

        if (middle - before < after - middle) {
          middle = before;
        } else {
          middle = after;
        }

        var s1 = s.substr(0, middle);
        var s2 = s.substr(middle + 1);
        var arr = [s1, s2];
        return arr
      }

      return txt

    },

    loadEvents: function (leagueId, league) {
      localStorage.setItem('leagueId', leagueId)
      localStorage.setItem('currentLeague', league)
      this.$axios.post('getEvents', {
        parentId: leagueId
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))

        for (let i = 0; i < response.data.length; i++) {
          this.loadMarkets(response.data[i].id)
        }
      }).catch(error => {
        console.log(error);
      })
    },

    toggleLeague(league){
      for(var i = 0; i < league.length; i++){
        const el = document.getElementById(league[i].name)
        if(el){
          if(el.className == "closed"){
            el.className = "open"
          }else if(el.className == "open"){
            el.className = "closed"
          }
        }
      }
    },

    loadImg(league) {
      const leagues = ["Sweden","Albania", "Italy", "Germany", "Austria", "England", "Spain", "France", "Switzerland", "Portugal", "Poland", "Serbia", "Slovenia", "Belgium",
        "Netherlands", "Denmark", "Russia", "Ukraine", "Turkey", "Greece", "Argentina", "Brazil", "Japan", "United States",
        "Bosnia-Herz", "Bulgaria", "Canada", "Chile", "China", "Colombia", "Croatia", "Cyprus", "Czech Republic", "Ecuador", "Egypt",
        "Estonia", "Finland", "Georgia", "Mexico", "Hungary", "Iceland", "India", "International", "Iran", "Iraq", "Ireland", "Israel", "Kuwait", "Lithuania", "Latvia", "Luxembourg","Macedonia",
        "Malta", "Moldova", "Montenegro", "Morocco", "New Zealand", "Norway","Peru", "Philippines", "Qatar", "Romania", "Saudi Arabia", "Scotland", "Slovakia", "South Africa", "South " +
        "Korea", "UAE", "Uruguay", "Wales", "Belarus", "Australia", "Algeria", "Bahrain", "Botswana", "Ecuador", "El Salvador", "Gambia", "Guatemala", "Indonesia", "Kazakhstan",
        "Malaysia", "Thailand", "Tunisia", "Uganda", "Venezuela"]
      const specialLeagues = ["UEFA Europa League","UEFA Champions League",  "UEFA Conference League"];
      if (specialLeagues.includes(league.name)) {
        return require('../assets/flags/' + league.name + ".png") || null;
      } else if (leagues.includes(league.country)) {
        return require('../assets/flags/' + league.country + ".png") || null;
      }
    },

    loadMarkets: function (eventId) {
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);


        localStorage.setItem(combo, JSON.stringify(response.data))

        const data = 1;
        this.$root.$emit('updateLiveTable', data);
        this.$root.$emit('updatePreMatchTable', data);

      }).catch(error => {
        console.log(error);
      })
    }



    // leagueClick: function () {
    //
    // },

  }
}
</script>

<style scoped>

.my-custom-scrollbar {
  position: relative;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-image: linear-gradient(to right, rgba(19, 78, 94, 0.7), rgba(113, 178, 128, 0.7));
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #001523;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #111e26;
}

/*ul li ul {*/
/*  display: none;*/
/*}*/

/*ul li:hover ul {*/
/*  display: block;*/
/*}*/

.open {
  display: block;
}
.closed {
  display: none;
}

</style>