<template>

  <div>

    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    <PopUpTableForLeagues_Mobile ref="PopUpTableLeagues" :key="componentKey"></PopUpTableForLeagues_Mobile>

    <!--   DOLE SU DESKTOP/MOBILE VERZIJE   -->

    <div v-if="isMobile" class="MOBILE">

      <b-row style="margin-bottom: -10px">
        <b-col sm="12">
          <Navbar :key="componentKeyN"></Navbar>
        </b-col>
      </b-row>

      <div class="navbar1" style="margin-bottom: -15px">

        <div class="row mt-1" style="margin-bottom: -10px">

          <div class="col-2">
            <div>
            </div>
          </div>

          <div class="col-4"></div>

          <div class="col-2">
            <div>
              <a class="navbar-brand hand "><img class="uppers" src="../assets/search.png"></a>
            </div>
          </div>

          <div class="col-2">
            <div v-if="this.currentSport!=null">
              <a class="navbar-brand hand " data-toggle="modal" data-target="#myModalForLeagues" @click="loadLeaguesToggle('Football')">
                <img class="uppers" src="../assets/trophy.png"></a>
            </div>
            <div v-else>
              <a class="navbar-brand hand "><img class="uppers" src="../assets/trophy.png"></a>
            </div>
          </div>

          <div class="col-2">
            <div>
              <a class="navbar-brand hand "><img class="uppers" src="../assets/timedate.png"></a>
            </div>
          </div>

        </div>

        <div class="btn-group container-fluid" role="group" aria-label="Basic example">
          <button class="btn  btn-sm" data-toggle="modal" data-target="#myModalForLeagues" @click="loadLeaguesToggle('Football')">
            <div>
              <a class="navbar-brand hand">
                <img class="nav1" src="../assets/ball.svg"></a>
            </div>
            <div>
              <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">SPORT</a>
            </div>
          </button>
          <button class="btn  btn-sm" @click="pushOnLiveBets">
            <div>
              <a class="navbar-brand hand">
                <img class="nav1" src="../assets/live.svg"></a>
            </div>
            <div>
              <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">LIVE</a>
            </div>
          </button>
          <button class="btn  btn-sm" @click="goToCasinoPage()">
            <div>
              <a class="navbar-brand hand">
                <img class="nav1" src="../assets/casino.svg"></a>
            </div>
            <div>
              <a class="navbar-brand hand" style="color: white; font-size: 0.6rem">CASINO</a>
            </div>
          </button>
        </div>

      </div>

      <div class="navbar2Mob" style="margin-bottom: -35px" >

        <div class="scrolling-wrapper row flex-row flex-nowrap mt-2 pb-1" >

          <div v-if="currentSport === 'Football'" class="col-3" style="background-image: linear-gradient(to bottom, #04619f, #00102e)" >
            <a class="navbar-brand hand" data-toggle="modal" data-target="#myModalForLeagues"
               @click="loadLeaguesToggle('Football')"><img src="../assets/footballrs.png"></a> <!-- data-target="#myModalForLeagues" -->
          </div>

          <div v-else class="col-3">
            <a class="navbar-brand hand" data-toggle="modal" data-target="#myModalForLeagues"
               @click="loadLeaguesToggle('Football')"><img src="../assets/footballrs.png"></a> <!-- data-target="#myModalForLeagues" -->
          </div>

          <div v-if="currentSport === 'Basketball'" class="col-3" style="background-image: linear-gradient(to bottom, #04619f, #00102e)" >
            <a class="navbar-brand hand" data-toggle="modal" data-target="#myModalForLeagues"
               @click="loadLeaguesToggle('Basketball')"><img src="../assets/basketball.svg"></a> <!-- data-target="#myModalForLeagues" -->
          </div>

          <div v-else class="col-3" >
            <a class="navbar-brand hand" data-toggle="modal" data-target="#myModalForLeagues"
               @click="loadLeaguesToggle('Basketball')"><img src="../assets/basketball.svg"></a> <!-- data-target="#myModalForLeagues" -->
          </div>

          <div v-if="currentSport === 'Ice Hockey'" class="col-3" style="background-image: linear-gradient(to bottom, #04619f, #00102e)" >
            <a class="navbar-brand hand" data-toggle="modal" data-target="#myModalForLeagues"
               @click="loadLeaguesToggle('Ice Hockey')"><img src="../assets/icehockey.svg"></a>
          </div>

          <div v-else class="col-3" >
            <a class="navbar-brand hand" data-toggle="modal" data-target="#myModalForLeagues"
               @click="loadLeaguesToggle('Ice Hockey')"><img src="../assets/icehockey.svg"></a>
          </div>

          <div v-if="currentSport === 'Handball'" class="col-3" style="background-image: linear-gradient(to bottom, #04619f, #00102e)" >
            <a class="navbar-brand hand" data-toggle="modal" data-target="#myModalForLeagues"
               @click="loadLeaguesToggle('Handball')"><img src="../assets/handball.png"></a>
          </div>

          <div v-else class="col-3" >
            <a class="navbar-brand hand" data-toggle="modal" data-target="#myModalForLeagues"
               @click="loadLeaguesToggle('Handball')"><img src="../assets/handball.png"></a>
          </div>

        </div>

      </div>

      <b-row class="mt-2 ap page-hero d-flex align-items-center justify-content-center">
        <b-row class="mt-4 ap page-hero d-flex align-items-center justify-content-center">
          <b-col sm="12">
            <FutureBetsTable_Mobile ref="future" :key="componentKey3" ></FutureBetsTable_Mobile>
          </b-col>
        </b-row>
      </b-row>

      <div :key="componentKey4">

        <div v-if="ticketsCount()>0"
             class="navbar fixed-bottom float-left ap page-hero d-flex align-items-center justify-content-center" >
          <div class="text-center p-2 " style="background-color: rgba(0, 0, 0, 0.6); border-radius: 7px;">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button type="button" class="btn btn-sm btn-dark"
                      style="margin-left: 2px; margin-right: 2px" @click="pushOnTicketMaker">
                Betting
                <button type="button" class="btn btn-sm "
                        style="background-color: #f5cf08; font-weight: bold; border-radius: 50%; font-size: 0.6rem">
                  {{ ticketsCount() }}
                </button>
              </button>
            </div>
          </div>
        </div>

      </div>


    </div>


    <!--   DOLE JE DESKTOP VERZIJA GORE JE MOBILE VERZIJA   -->

    <div v-else class="DESKTOP" style="margin-left: 6%; margin-right: 6%">

      <div class="navbar2" style="padding-bottom: 5px">

        <b-row>
          <b-col sm="12">
            <Navbar_Desktop :key="componentKeyN"></Navbar_Desktop>
          </b-col>
        </b-row>

        <b-row class="mt-0 sports ap page-hero d-flex align-items-center justify-content-center sports-navbar" >

          <b-col sm="1" v-if="currentSport === 'Football'" style="background-image: linear-gradient(to bottom, #00102e, #04619f, #04619f)" >
            <a class="navbar-brand hand" @click="loadLeaguesInitially('Football')"><img src="../assets/footballrs.png" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('football')}}</strong></a>
          </b-col>

          <b-col sm="1" v-else class="">
            <a class="navbar-brand hand" @click="loadLeaguesInitially('Football')"><img src="../assets/footballrs.png" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('football')}}</strong></a>
          </b-col>

          <b-col sm="1" v-if="currentSport === 'Basketball'" class="" style="background-image: linear-gradient(to bottom, #00102e, #04619f, #04619f)" >
            <a class="navbar-brand hand" @click="loadLeaguesInitially('Basketball')"><img src="../assets/basketball.svg" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('basketball')}}</strong></a>
          </b-col>

          <b-col sm="1" v-else class="" >
            <a class="navbar-brand hand" @click="loadLeaguesInitially('Basketball')"><img src="../assets/basketball.svg" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('basketball')}}</strong></a>
          </b-col>

          <b-col sm="1" v-if="currentSport === 'Ice Hockey'" class="" style="background-image: linear-gradient(to bottom, #00102e, #04619f, #04619f)" >
            <a class="navbar-brand hand" @click="loadLeaguesInitially('Ice Hockey')"><img src="../assets/icehockey.svg" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('tennis')}}</strong></a>
          </b-col>

          <b-col sm="1" v-else class="" >
            <a class="navbar-brand hand" @click="loadLeaguesInitially('Ice Hockey')"><img src="../assets/icehockey.svg" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('tennis')}}</strong></a>
          </b-col>

          <b-col sm="1" v-if="currentSport === 'Handball'" class="" style="background-image: linear-gradient(to bottom, #00102e, #04619f, #04619f)" >
            <a class="navbar-brand hand" @click="loadLeaguesInitially('Handball')"><img src="../assets/handball.png" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('volleyball')}}</strong></a>
          </b-col>

          <b-col sm="1" v-else class="" >
            <a class="navbar-brand hand" @click="loadLeaguesInitially('Handball')"><img src="../assets/handball.png" class="image"><strong style="font-size: 0.8rem; color: white; ">{{$t('volleyball')}}</strong></a>
          </b-col>

        </b-row>
      </div>

      <b-row class="mt-0 ap page-hero d-flex align-items-center justify-content-center">
        <b-col sm="2">
          <LeaguesTable_Desktop :key="componentKey"></LeaguesTable_Desktop>
        </b-col>
        <b-col sm="7">
          <b-row class="mt-0 ap page-hero d-flex align-items-center justify-content-center">
            <b-col sm="12">
              <Carousel></Carousel>
            </b-col>
          </b-row>
<!--          <b-row class="mt-4 ap page-hero d-flex align-items-center justify-content-center">-->
<!--            <b-col sm="12">-->
<!--              <LiveBetsTable_Desktop ref="live" :key="componentKey2"></LiveBetsTable_Desktop>-->
<!--            </b-col>-->
<!--          </b-row>-->
          <b-row class="mt-1 ap page-hero d-flex align-items-center justify-content-center" style="height: 50vh;">
            <b-col sm="12">
              <FutureBetsTable_Desktop ref="future" :key="componentKey3"></FutureBetsTable_Desktop>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="3">
          <b-row>
            <b-col>
              <TicketTable_Desktop :key="componentKey1"></TicketTable_Desktop>
            </b-col>
          </b-row>
<!--          <div v-else>-->
<!--            <div class="transparent ap page-hero d-flex align-items-center justify-content-center">-->
<!--              <h3>There are no tickets yet</h3>-->
<!--            </div>-->
<!--          </div>-->
          <b-row v-if="this.ticketsCount() == 0">
            <b-col>
              <div style="margin-top: -80%; margin-bottom: 5%">
                <img style="width: 35vh; height: 35vh" src="../assets/banner.png" alt="banner">
              </div>
            </b-col>
          </b-row>
          <b-row v-if="this.ticketsCount() == 0">
            <b-col>
              <div>
                <img style="width: 35vh; height: 35vh" src="../assets/DesktopAd1.jpg" alt="banner">
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>


    </div>


  </div>


</template>

<script>
import Navbar from "@/components/Navbar";
import FutureBetsTable_Desktop from "@/components/FutureBetsTable_Desktop";
import FutureBetsTable_Mobile from "@/components/FutureBetsTable_Mobile";
import LeaguesTable_Desktop from "@/components/LeaguesTable_Desktop";
import TicketTable_Desktop from "@/components/TicketTable_Desktop";
import ConfirmDialogue from '../components/ConfirmDialogue.vue'
import router from "@/router/router";
import PopUpTableForLeagues_Mobile from "@/components/PopUpTableForLeagues_Mobile";
import Navbar_Desktop from "@/components/Navbar_Desktop";
import Carousel from "@/components/Carousel";

export default {
  name: "Home",
  components: {
    Carousel,
    Navbar_Desktop,
    Navbar, FutureBetsTable_Desktop, FutureBetsTable_Mobile, LeaguesTable_Desktop, TicketTable_Desktop, ConfirmDialogue, PopUpTableForLeagues_Mobile},

  data() {
    return {
      leagues: [],
      componentKeyN: 0,
      componentKey: 0,
      componentKey1: 0,
      componentKey2: 0,
      componentKey3: 0,
      componentKey4: 0,
      timer: '',
      isMobile: false,
      player: JSON.parse(localStorage.getItem('player')),
      dummy: '',
      eventIds: JSON.parse(localStorage.getItem('eventIds')),
      currentSport: ''
    }
  },

  // created() {
  //   this.timer = setInterval(this.forceUpdate, 5000);
  // },

  beforeMount() {
    if(localStorage.getItem('currentSport') === null || localStorage.getItem('currentSport') === undefined){
      let sportName = 'Football';
      this.currentSport = sportName;
      localStorage.setItem('currentSport',sportName);
    }
    else{
      this.currentSport = localStorage.getItem('currentSport');
    }
  },

  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted() {

    localStorage.removeItem('events')
    if(localStorage.getItem('currentLeague') == undefined || localStorage.getItem('currentLeague') == null) {
      this.loadInitials()
    }else{
      this.loadInitials()
      localStorage.removeItem('currentLeague');
    }

    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

    this.$root.$on('updateNavbar', data => {
      this.componentKeyN += data;
    });

    this.$root.$on('updateLeagues', data => {
      this.componentKey += data;
    });

    this.$root.$on('updateTickets', data => {
      if(this.$refs.future!=undefined && this.$refs.future!=null){
        this.$refs.future.$forceUpdate()
      }
      if(this.$refs.ticketTable!=undefined && this.$refs.ticketTable!=null){
        this.$refs.ticketTable.$forceUpdate()
      }
      // if(this.$refs.live!=undefined && this.$refs.live!=null){
      //   this.$refs.live.$forceUpdate()
      // }
      this.componentKey1 += data;
      // this.componentKey2 += data;
      this.componentKey4 += data;

    });

    this.$root.$on('updateOthers', data => {
      if(this.$refs.future!=undefined && this.$refs.future!=null){
        this.$refs.future.$forceUpdate()
      }
      if(this.$refs.live!=undefined && this.$refs.live!=null){
        this.$refs.live.$forceUpdate()
      }
      this.dummy = data
    });


    // this.$root.$on('updateLiveTable', data => {
    //   if(this.$refs.live!=undefined && this.$refs.live!=null){
    //     this.$refs.live.$forceUpdate()
    //   }
    //   this.componentKey2 += data;
    // });


    this.$root.$on('updatePreMatchTable', data => {
      if(this.$refs.future!=undefined && this.$refs.future!=null){
        this.$refs.future.$forceUpdate()
      }
      this.componentKey3 += data;
    });

    this.$root.$on('componentKeyChanged', data => {
      this.dummy = data
      if(this.$refs.future!=undefined && this.$refs.future!=null){
        this.$refs.future.$forceUpdate()
      }
      if(this.$refs.live!=undefined && this.$refs.live!=null){
        this.$refs.live.$forceUpdate()
      }
    });

    this.$root.$on('updateFooter', data => {
      this.componentKey4 += data;
    });

    this.$root.$on('updateSportsClick', data => {
      this.sportsnav += data;
    });

  },

  beforeUpdate() {
    if(localStorage.getItem('currentSport') === null || localStorage.getItem('currentSport') === undefined){
      let sportName = 'Football';
      this.currentSport = sportName;
      localStorage.setItem('currentSport',sportName);
    }
    else{
      this.currentSport = localStorage.getItem('currentSport');
    }
  },

  methods: {

    onResize() {
      this.isMobile = global.innerWidth <= 1024
    },


    //...mapActions({'loadLeagues'}),

    async showMsg(title, msg) {


      await this.$refs.confirmDialogue.showNotice({
        title: title,
        message: msg,
        okButton2: 'Ok',
      })

    },

    getCurrentSportName: function (){
      const events = JSON.parse(localStorage.getItem('events'))

      if(events!=null && events!=undefined && events.length>0 &&
          events[0]!=null && events[0]!=undefined &&
          events[0].league!=null && events[0].league!=undefined &&
          events[0].league.sport!=null && events[0].league.sport!=undefined &&
          events[0].league.sport.name!=null && events[0].league.sport.name!=undefined){

        return events[0].league.sport.name

      }

      return null

    },

    goToCasinoPage() {
      if(JSON.parse(localStorage.getItem('player')) != null && JSON.parse(localStorage.getItem('player')) != undefined){
        let p = JSON.parse(localStorage.getItem('player'));
        let pw = p.password;
        let password = pw.replaceAll('.','');
        location.href = 'http://casino.loca.bet/autologin/' + password.substr(8, 14) + '---' + p.username;
      }else{
        location.href = 'http://casino.loca.bet';
      }
    },

    ticketsCount: function () {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        return clickedBets.length
      }

      return 0
    },

    async pushOnLiveBets() {

      if (!this.$route.fullPath.includes('LiveBetsView'))
        await router.push('/LiveBetsView')
      this.$root.$emit('updateNavbar', 1);

    },

    async pushOnHome() {

      if (!this.$route.fullPath.includes('home'))
        await router.push('/home')
      this.$root.$emit('updateNavbar', 1);

    },


    showTickets: async function () {

      if (this.player != null) {

        this.$axios.post('getTickets', {
          parentId: this.player.id
        }).then(response => {

          localStorage.setItem('playerTickets', JSON.stringify(response.data))

          // if(!this.$route.fullPath.includes('PlayerTicketsView'))
          //   router.push('/PlayerTicketsView')
          this.pushOnTicketView()
          this.$root.$emit('updateNavbar', 1);

        }).catch(error => {
          console.log(error);
        })

      }

    },

    pushOnTicketMaker: async function () {

      if (!this.$route.fullPath.includes('TicketMakerView'))
        await router.push('/TicketMakerView')

    },

    async pushOnTicketView() {

      if (!this.$route.fullPath.includes('PlayerTicketsView'))
        await router.push('/PlayerTicketsView')

    },

    async loadLeaguesToggle(name) {

      //this.currentSport = name;
      //localStorage.setItem('currentSport', name);

      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {

        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.$root.$emit('toggleModalLeagues', 1);

        // if(response.data==null || response.data==undefined || response.data.length==0){
        //   this.showMsg('Notice','We currently do not have support for '+name+', please try again later.')
        // }

      }).catch(error => {
        console.log(error);
        var lgs = []
        localStorage.setItem('leagues', JSON.stringify(lgs))
        this.$root.$emit('toggleModalLeagues', -1);
        // this.showMsg('Notice','We currently do not have support for '+name+', please try again later.')
      })
    },

    async loadLeagues(name) {

      // this.currentSport = name;
      // localStorage.setItem('currentSport',name);

      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {

        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.componentKey += 1;

        if(response.data==null || response.data==undefined || response.data.length==0){
          this.showMsg('Notice','We currently do not have support for '+name+', please try again later.')
        }

      }).catch(error => {
        console.log(error);
      })

      // await this.loadLiveEvents(name);
    },

    loadSoonEvents: function (name){
      // this.currentSport = name;
      // localStorage.setItem('currentSport',name);
      this.$axios.post('getSoonEvents', {
        parentId: name
      }).then(response => {

        if(response.data.length>0){
          localStorage.setItem('events', JSON.stringify(response.data))
          //this.$root.$emit('toggleModalLeagues', 1);
          for (let i = 0; i < response.data.length; i++) {
            this.loadMarkets(response.data[i].id);
          }
          this.componentKey3 += 1;
        }
        else{
          this.showMsg('Notice', 'There are no events for the next 24h.')
        }

      }).catch(error => {
        console.log(error);
      })
    },

    loadEvents: function (id) {
      this.$axios.post('getEvents', {
        parentId: id
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))

        // this.eventss = response.data;
      }).catch(error => {
        console.log(error);
      })

    },

    loadMarkets: function (eventId){
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);

        localStorage.setItem(combo , JSON.stringify(response.data))

        const data = 1;
        this.$root.$emit('updateLiveTable', data);
        this.$root.$emit('updatePreMatchTable', data);

      }).catch(error => {
        console.log(error);
      })
    },

    // -------ovo dole je za inicijalno stvaranje eventova da ne bude prazno prvi put
    loadEventsInitial: function (leagueId, league){
      localStorage.setItem('leagueId', leagueId)
      localStorage.setItem('currentLeague', league)
      this.$axios.post('getEvents', {
        parentId: leagueId
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))
        this.$root.$emit('updateNavbar', 1);


        for (let i = 0; i < response.data.length; i++) {
          let str1 = 'markets';
          let str2 = response.data[i].id;
          let combo = str1.concat(str2);

          localStorage.setItem(combo , JSON.stringify(response.data[i].marketHelpers));
        }

        const data = 1;
        this.$root.$emit('updateLiveTable', data);
        this.$root.$emit('updatePreMatchTable', data);

      }).catch(error => {
        console.log(error);
      })
    },

    loadMarketsInitial: function (eventId){
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);

        localStorage.setItem(combo , JSON.stringify(response.data))

        const data = 1;
        this.$root.$emit('updateLiveTable', data);
        this.$root.$emit('updatePreMatchTable', data);

      }).catch(error => {
        console.log(error);
      })
    },

    async loadLeaguesInitially(name) {

      // this.currentSport = name;
      // localStorage.setItem('currentSport',name);

      this.$axios.post('getLeagues', {
        parentId: name
      }).then(response => {

        localStorage.setItem('leagues', JSON.stringify(response.data))
        this.componentKey += 1;

        let leagues = JSON.parse(localStorage.getItem('leagues'))

        if(leagues!=null && leagues!=undefined && leagues.length>0){
          let leagueId = leagues[0].id
          let league = leagues[0].name
          this.loadEventsInitial(leagueId, league)
        }else{

          this.loadLeaguesInitially('Basketball')
          let leagues = JSON.parse(localStorage.getItem('leagues'))
          if(leagues!=null && leagues!=undefined && leagues.length>0){
            let leagueId = leagues[0].id
            let league = leagues[0].name
            this.loadEventsInitial(leagueId, league)
          }else{

            this.loadLeaguesInitially('Tennis')
            let leagues = JSON.parse(localStorage.getItem('leagues'))
            if(leagues!=null && leagues!=undefined && leagues.length>0){
              let leagueId = leagues[0].id
              let league = leagues[0].name
              this.loadEventsInitial(leagueId, league)
            }else{

              this.loadLeaguesInitially('Volleyball')
              let leagues = JSON.parse(localStorage.getItem('leagues'))
              if(leagues!=null && leagues!=undefined && leagues.length>0){
                let leagueId = leagues[0].id
                let league = leagues[0].name
                this.loadEventsInitial(leagueId, league)
              }else{

                this.showMsg('Notice','We currently do not have any leagues, please try again later.')

              }

            }

          }

        }

      }).catch(error => {
        console.log(error);
      })
    },

    loadInitials: function (){

      let currentSport = localStorage.getItem('currentSport');
      // let eventss = JSON.parse(localStorage.getItem('events'))
      // if(eventss==null || eventss==undefined || eventss.length==0){
      if(currentSport != undefined || currentSport != null){
        this.loadLeaguesInitially(currentSport);
      }else{
        localStorage.setItem('currentSport', 'Football');
        this.loadLeaguesInitially('Football');
      }

      this.componentKey2+=1
      this.componentKey3+=1

    }
    // -------ovo gore je za inicijalno stvaranje eventova da ne bude prazno prvi put

  }

}
</script>

<style scoped>

.betTypes {
  /*background-image: linear-gradient(to bottom,#eb5757, #b82e1f);*/
}

.sports {
  /*background-image: linear-gradient(to bottom,#eb5757, #b82e1f);*/
  border-top: 1px solid black;
}

.navbar2 {
  background-color: transparent;
}

.sports-navbar{
  /*background-color: lightblue; !*#07112d*!*/
  background-image: linear-gradient(to bottom, #00102e, #04619f);
  border-radius: 2px;
}

.navbar1 {
  background-image: linear-gradient(to bottom, #00102e, #04619f);
}

.navbar2Mob {
  background-color: #00102e;
}

label:hover,
label:focus {
  box-shadow: 0 0.5em 0.5em -0.4em black;
  transform: translateY(-0.25em);
}

.fixed-bottom {
  font-size: 0.8rem;
}

.icon {
  background-size: cover;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.home {
  background-image: url(../assets/homebg.png);
}

.live {
  background-image: url(../assets/livebg.png);
}

.oneTicket {
  background-image: url(../assets/oneTicketbg.png);
}

.twoTickets {
  background-image: url(../assets/twoTicketsBg.png);
}

.scrolling-wrapper {
  overflow-x: hidden;
}

img {
  width: 21px;
  height: 21px
}

.uppers {
  width: 17px;
  height: 17px
}

.nav1 {
  width: 25px;
  height: 25px;
}

.transparent {
  background-color: rgba(0,0,0,0.5);
  height: 36vh;
  width: 35.5vh;
  color: lightgrey;
}

.hand{
  width: 100%;
  display:block;
  text-align: center;
}

img{
  margin: 0px 5px 0px 5px;
}


</style>
