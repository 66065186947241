<template>

  <div class="modal" id="myModalForPairs">

    <pop-up-table ref="popUpTable"></pop-up-table>

    <div class="modal-dialog ">
      <div class="modal-content">

        <!-- Modal body -->
        <div class="modal-body ">

          <div class="row table-wrapper-scroll-y my-custom-scrollbar">
            <div class="col-12">

              <div class="mb-2 text-right">
                <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal">X</button>
              </div>

              <table class="table table-sm table-hover table-dark" style="background-color: transparent">
                <thead>
                <tr class="firstrow">
                  <th scope="col">Teams</th>
                  <th scope="col">Bet</th>
                </tr>
                </thead>
                <tbody v-if="ticket!=null && ticket!=undefined">
                <tr v-for="pair in ticket.pairs" :key="pair.id">

                  <td class="align-middle" style="margin-right: 10px; margin-left: 10px">

                    <div class="ap page-hero d-flex align-items-center justify-content-center"
                         style=" border-radius: 8px; margin-bottom: 4px; ">
                      {{ pair.home }}
                    </div>
                    <div class="ap page-hero d-flex align-items-center justify-content-center"
                         style=" border-radius: 8px; margin-bottom: 4px; ">
                      {{ pair.away }}
                    </div>

                  </td>
                  <td class="align-middle">

                    <div v-if="find(spaces(pair.marketName))" class="row ap page-hero d-flex align-items-center justify-content-center" style=" border-radius: 8px; margin-bottom: 4px">
                      {{ $t(spaces(pair.marketName)) }}
                    </div>
                    <div v-else class="row ap page-hero d-flex align-items-center justify-content-center" style=" border-radius: 8px; margin-bottom: 4px">
                      {{ pair.marketName }}
                    </div>
                    <div class="oneMarket ap page-hero d-flex align-items-center justify-content-center">

                      <button v-if="pair.settlement == 0" class="btn btn-secondary btn-sm">

                        <div v-if="find(spaces(pair.betName))">{{ $t(spaces(pair.betName)) }} {{ margin(pair.betName)}}</div>
                        <div v-else>{{ pair.betName }} </div>
                        <div>{{ pair.betOdd }}</div>

                      </button>

                      <button v-if="pair.settlement == 2" class="btn btn-success btn-sm">

                        <div v-if="find(spaces(pair.betName))">{{ $t(spaces(pair.betName)) }} {{ margin(pair.betName)}}</div>
                        <div v-else>{{ pair.betName }} </div>
                        <div>{{ pair.betOdd }}</div>

                      </button>

                      <button v-if="pair.settlement == 1" class="btn btn-danger btn-sm">

                        <div v-if="find(spaces(pair.betName))">{{ $t(spaces(pair.betName)) }} {{ margin(pair.betName)}}</div>
                        <div v-else>{{ pair.betName }} </div>
                        <div>{{ pair.betOdd }}</div>

                      </button>

                    </div>

                  </td>

                </tr>
                </tbody>
              </table>

              <div class="btn-group container-fluid footer" role="group" aria-label="Basic example" style="z-index: 999">

                <button class="btn  btn-sm btn" style="font-size: 0.9rem; font-weight: bold; color: white">
                  <div style="border: 1px solid white">
                    <div class="text-center">
                      Deposit
                    </div>
                    <div class="text-center">
                      {{ ticket.deposit }}
                    </div>
                  </div>

                  <div class="mt-2" style="border: 1px solid white">
                    <div class="text-center">
                      Rate
                    </div>
                    <div v-if="ticket.multiplier!=null && ticket.multiplier!=undefined" class="text-center">
                      {{ (ticket.multiplier).toFixed(2) }}
                    </div>
                  </div>
                </button>

                <button class="btn  btn-sm" style="font-size: 0.9rem; font-weight: bold; color: white">
                  <div style="border: 1px solid white">
                    <div class="text-center">
                      Potential Win
                    </div>
                    <div v-if="ticket.potentialWin!=null && ticket.potentialWin!=undefined" class="text-center">
                      {{ (ticket.potentialWin).toFixed(2) }}
                    </div>
                    <div v-else class="text-center">
                      {{ (ticket.deposit * ticket.multiplier).toFixed(2) }}
                    </div>
                  </div>

                  <div class="mt-2" style="border: 1px solid white">
                    <div class="text-center">
                      Withdraw
                    </div>
                    <div v-if="ticket.withdraw!=null && ticket.withdraw!=undefined" class="text-center">
                      {{ (ticket.withdraw).toFixed(2) }}
                    </div>
                    <div v-else class="text-center">
                      0
                    </div>
                  </div>
                </button>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>

  </div>

</template>

<script>

import PopUpTable from "@/components/PopUpTableOtherBets_Desktop";
import router from "@/router/router";

export default {
  name: "PopUpTableForPairs_Desktop",
  components: {PopUpTable},
  data() {
    return {
      ticket: '',
      isMobile: false,
    }
  },


  beforeDestroy() {
    if (typeof global === 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted() {
    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})
  },

  methods: {

    getTimeFormat: function (time) {

      if (time != null && time != 'undefined') {
        const formattedTime = time.split(",");
        return formattedTime
      }

    },

    find(betName){
      if(betName === '1X2' || ((betName.startsWith('1') || betName.startsWith('2')) && betName.length < 3)){
        return false;
      }
      if(this.$te(this.spaces(betName))){
        return true;
      }
      return false;
    },

    spaces(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(!split[i].includes('.')){
            betName += split[i];
          }
        }
      }
      return betName.replaceAll(' ', '');
    },

    margin(betName){
      if(betName.includes('Over') || betName.includes('Under')){
        let split = betName.split(' ');
        betName = '';
        for(let i = 0; i < split.length; i++){
          if(split[i].includes('.')){
            return split[i];
          }
        }
      }
      return '';
    },

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    ticketsCount: function () {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        return clickedBets.length
      }

      return 0
    },

    pushOnTicketMaker: async function () {

      if (!this.$route.fullPath.includes('TicketMakerView'))
        await router.push('/TicketMakerView')

    },

  },

  created() {

    const self = this;

    this.$root.$on('toggleModalPairs', data => {

      if (data != null && data != undefined) {
        self.ticket = data;
      }

    });

  },

}
</script>

<style scoped>

.oneMarket {
  margin: 6px;
}

.footer {
  position: sticky;
  background-color: #111e26;
}

.modal {
  text-align: center;
}

.modal-content {
  background: url(../assets/secondbg.jpg) center center no-repeat;
}

.modal-dialog {
  text-align: left; /* you'll likely want this */
  min-width: 95%;
  /*width: auto !important;*/
  display: inline-block;
}

.my-custom-scrollbar {
  position: relative;
  height: 85vh;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.firstrow {
  position: sticky;
  top: 0;
  background-color: #222325;
  text-align: center;
}

html, body {
  overflow-x: auto;
  overflow-y: auto;
}

</style>