<template>
  <div class="tableList">

    <PopUpTableOtherBets_Mobile ref="popUpTable" :key="componentKey"></PopUpTableOtherBets_Mobile>
    <FloatingAlert language="en"/>

    <div class="table-wrapper-scroll-y my-custom-scrollbar example" style="height: 100vh; padding-bottom: 40vh">
      <table class="table table-sm table-hover table-striped table-dark">

        <thead>
        <tr class="firstrow" style=" font-size: 0.9rem">

          <th colspan="2" v-if="this.events!=null && this.events!=undefined && this.events.length>0">
            <div class="row ap page-hero d-flex align-items-center justify-content-center">
              <div class="col-2" style="border-right: 1px solid #111e26;">
                NT 1
              </div>
              <div class="col-2 text-center" style="border-right: 1px solid #111e26; padding: 0 !important;">
                {{'Kein Tor'}}
              </div>
              <div class="col-2" style="border-right: 1px solid #111e26;">
                NT 2
              </div>
              <div class="col-2"></div>
              <div class="col-2" style="border-right: 1px solid #111e26;">
                unter
              </div>
              <div class="col-2" style="border-right: 1px solid #111e26;">
                über
              </div>
            </div>
          </th>

        </tr>
        </thead>

        <tbody>
        <tr v-for="event in liveMatches" :key="event.id">
          <td v-if="getMarketByName(event.id, 'Under/Over', 'Next Goal') != undefined" style="width: 100vw;">

            <div class="row">
              <div class="col-10">
                <div class="text-left" style="font-size: 0.9rem">
                  <a style="color: yellow; font-size: 0.9rem; margin-right: 3px">{{ event.time }}</a>
                  <img :src="loadImg(event.league)" style="width: 18px; height: 10px; margin-right: 3px">
                  <a style="color: greenyellow">{{ event.league.name }}</a>
                </div>

                <div class="text-left" style="font-size: 0.9rem">
                  <a style="opacity: 0;">{{ event.time }}</a>
                  <!--dummy, da bi bilo taman toliko odvojeno od levog zida, da bude u nivou sa zastavicom-->
                  {{ event.home }}
                  <a style=" font-size: 0.9rem; color: limegreen; float: right">{{ homeResult(event.result) }}</a>
                </div>

                <div class="text-left" style="font-size: 0.9rem">
                  <a style="opacity: 0;">{{ event.time }}</a>
                  <!--dummy, da bi bilo taman toliko odvojeno od levog zida, da bude u nivou sa zastavicom-->
                  {{ event.away }}
                  <a style=" font-size: 0.9rem; color: limegreen; float: right">{{ awayResult(event.result) }}</a>
                </div>
              </div>

              <div class="col-2">
                <button
                    v-if="loadCurrentMarkets(event.id)!=null && loadCurrentMarkets(event.id)!=undefined && loadCurrentMarkets(event.id).length>0"
                    style="background-color: dodgerblue; color: white"
                    data-toggle="modal"
                    data-target="#myModal"
                    class="btn w-100 h-100"
                    type="button"
                    @click.prevent="toggleModal(event.id, event)">
                  <strong>+</strong>
                </button>
              </div>
            </div>

             <!--betovi-->
              <div class="row" v-if="getMarketByName(event.id, 'Under/Over', 'Next Goal').length == 3 ||
                         getMarketByName(event.id, 'Under/Over', 'Next Goal').length == 6">
                <div class="col-2 mt-2 mb-1 d-flex justify-content-center"
                     v-for="bet in getMarketByName(event.id, 'Under/Over', 'Next Goal')" :key="bet.id">

                    <!--                    NEXT GOAL 1-->
                      <div v-if="bet.market == 59 && bet.name.split(' ')[0] == '1'" class="ml-3">

                        <div v-if="bet.status == 1">

                          <div v-if="bet.flag == 1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else>
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                        </div>

                        <div v-else-if="bet.status == 2">

                          <div v-if="bet.flag == 1">
                            <button class="btn btn-sm blink_me_green disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">X</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button class="btn btn-sm blink_me_red disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">X</div>
                            </button>
                          </div>

                          <div v-else>
                            <button class="btn btn-sm disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold"><img src="../assets/kvoteZ.svg" style="width: 20px; height: 20px"></div>
                            </button>
                          </div>

                        </div>

                      </div>

                    <!--                  NEXT GOAL NO-->
                      <div v-if="bet.market == 59 && bet.name.split(' ')[0] == 'No'" >

                        <div v-if="bet.status == 1">

                          <div v-if="bet.flag == 1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else>
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                        </div>

                        <div v-else-if="bet.status == 2">

                          <div v-if="bet.flag == 1">
                            <button class="btn btn-sm blink_me_green disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black">X</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button class="btn btn-sm blink_me_red disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black">X</div>
                            </button>
                          </div>

                          <div v-else>
                            <button class="btn btn-sm disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold"><img src="../assets/kvoteZ.svg" style="width: 20px; height: 20px"></div>
                            </button>
                          </div>

                        </div>

                      </div>

                    <!--                  NEXT GOAL 2-->
                      <div v-if="bet.market == 59 && bet.name.split(' ')[0] == '2'" >

                        <div v-if="bet.status == 1">

                          <div v-if="bet.flag == 1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else>
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Next Goal', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                        </div>

                        <div v-else-if="bet.status == 2">

                          <div v-if="bet.flag == 1">
                            <button class="btn btn-sm blink_me_green disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black">X</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button class="btn btn-sm blink_me_red disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black">X</div>
                            </button>
                          </div>

                          <div v-else>
                            <button class="btn btn-sm disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f;">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold"><img src="../assets/kvoteZ.svg" style="width: 20px; height: 20px"></div>
                            </button>
                          </div>

                        </div>

                      </div>

                    <!--                  Under Over Value-->
                      <div v-if="bet.market == null || bet.market == undefined" >
                        <button class="btn btn-sm"
                                style="background-color: transparent">
                          <div style="font-size: 0.9rem; color: white">{{bet}}</div>
                        </button>
                      </div>

                    <!--                  Under-->
                      <div v-if="bet.market == 2 && bet.name.split(' ')[0] == 'Under'" >

                        <div v-if="bet.status == 1">

                          <div v-if="bet.flag == 1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else>
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                        </div>

                        <div v-else-if="bet.status == 2">

                          <div v-if="bet.flag == 1">
                            <button class="btn btn-sm blink_me_green disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black">X</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button class="btn btn-sm blink_me_red disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black">X</div>
                            </button>
                          </div>

                          <div v-else>
                            <button class="btn btn-sm disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold"><img src="../assets/kvoteZ.svg" style="width: 20px; height: 20px"></div>
                            </button>
                          </div>

                        </div>

                      </div>

                    <!--                  Over-->
                      <div v-if="bet.market == 2 && bet.name.split(' ')[0] == 'Over'" class="mr-3">

                        <div v-if="bet.status == 1">

                          <div v-if="bet.flag == 1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_green"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm blink_me_red"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                          <div v-else>
                            <button v-if="selected(bet.id)"
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #f5cf08">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>

                            <button v-else
                                    @click="clickBet(event.id, bet.market , bet.id, event.home, event.away, 'Under/Over', bet.name, bet.odd)"
                                    class="btn btn-sm"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold">{{ parseFloat(bet.odd).toFixed(2) }}</div>
                            </button>
                          </div>

                        </div>

                        <div v-else-if="bet.status == 2">

                          <div v-if="bet.flag == 1">
                            <button class="btn btn-sm blink_me_green disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black">X</div>
                            </button>
                          </div>

                          <div v-else-if="bet.flag == -1">
                            <button class="btn btn-sm blink_me_red disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black">X</div>
                            </button>
                          </div>

                          <div v-else>
                            <button class="btn btn-sm disabled"
                                    style="background-color: #d0e9ff ; border: 2px solid #38596f">
                              <div style="font-size: 0.9rem; color: black; font-weight: bold"><img src="../assets/kvoteZ.svg" style="width: 20px; height: 20px"></div>
                            </button>
                          </div>

                        </div>

                      </div>

                </div>
              </div>

          </td>
        </tr>
        </tbody>
      </table>

      <div>
        <b-row>
          <b-col sm = 4></b-col>
          <b-col sm = 4>
            <button style="border-radius: 20px; width: 20vw" class="btn btn-sm btn-primary" @click="loadLiveEvents()">+</button>
          </b-col>
          <b-col sm = 4></b-col>
        </b-row>
      </div>

      <div class="text-center container" style="background-color: #021130">

        <div style="">
          <a><img class="" src="../assets/locabetlogo.svg" style="height: 10vh; width: 50vw" align="center"></a>
        </div>
        <h3 style="color: red;">18+</h3>
        <div style="">
          <a><img class="" src="../assets/lbcasinologooo.svg" style="height: 10vh; width: 50vw" align="center"></a>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import PopUpTableOtherBets_Mobile from "@/components/PopUpTableOtherBets_Mobile";
import VueFloatingAlert from 'vue-floating-alert'
import Vue from 'vue'

Vue.use(VueFloatingAlert)

export default {
  name: "LiveBetsTable_Mobile",
  components: {PopUpTableOtherBets_Mobile},
  data() {
    return {
      events: JSON.parse(localStorage.getItem('liveEvents')),
      eventss: [],
      currentMarkets: '',
      componentKey: 0,
      isMobile: false,
      dummy: '',
      limitUp: localStorage.getItem('loadedInt'),
      limitDown: '',
      liveInterval: ''
    }
  },


  beforeDestroy() {
    if (typeof global == 'undefined') return

    global.removeEventListener('resize', this.onResize, {passive: true})

    this.stopLiveThread();
  },

  computed: {

    liveMatches: function () {
      let eventsToBeFiltered = []
      if (this.events) {
        // return this.events.filter(e => e.status == 2)
        return this.events;
      }
      return eventsToBeFiltered
    }

    // liveMatches: function () {
    //   let eventsToBeFiltered = []
    //   if(this.events) {
    //     eventsToBeFiltered = this.events;
    //     return eventsToBeFiltered.filter(e => e.status == 1)
    //   }
    //   return eventsToBeFiltered
    // }

  },
  methods: {

    loadImg(league) {
      const leagues = ["Sweden", "Albania", "Italy", "Germany", "Austria", "England", "Spain", "France", "Switzerland", "Portugal", "Poland", "Serbia", "Slovenia", "Belgium",
        "Netherlands", "Denmark", "Russia", "Ukraine", "Turkey", "Greece", "Argentina", "Brazil", "Japan", "United States",
        "Bosnia-Herz", "Bulgaria", "Canada", "Chile", "China", "Colombia", "Croatia", "Cyprus", "Czech Republic", "Ecuador", "Egypt",
        "Estonia", "Finland", "Georgia", "Mexico", "Hungary", "Iceland", "India", "International", "Iran", "Iraq", "Ireland", "Israel", "Kuwait", "Lithuania", "Latvia", "Luxembourg", "Macedonia",
        "Malta", "Moldova", "Montenegro", "Morocco", "New Zealand", "Norway", "Peru", "Philippines", "Qatar", "Romania", "Saudi Arabia", "Scotland", "Slovakia", "South Africa", "South " +
        "Korea", "UAE", "Uruguay", "Wales", "Belarus", "Australia", "Algeria", "Bahrain", "Botswana", "Ecuador", "El Salvador", "Gambia", "Guatemala", "Indonesia", "Kazakhstan",
        "Malaysia", "Thailand", "Tunisia", "Uganda", "Venezuela"]
      const specialLeagues = ["UEFA Europa League", "UEFA Champions League", "UEFA Conference League"];
      if (specialLeagues.includes(league.name)) {
        return require('../assets/flags/' + league.name + ".png") || null;
      } else if (leagues.includes(league.country)) {
        return require('../assets/flags/' + league.country + ".png") || null;
      }
    },

    startLiveThread() {
      this.liveInterval = setInterval(() => {

        this.$axios.post('getLiveEvents', {
          parentId: localStorage.getItem('currentSport'),
          limitDown: 0,
          limitUp: localStorage.getItem('loadedInt')
        }).then(response => {

          this.events = this.sortByTime(response.data);
          localStorage.setItem('liveEvents', JSON.stringify(this.sortByTime(response.data)))


          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].marketHelpers.length > 0) {
              let event = response.data[i];
              let str1 = 'markets';
              let str2 = event.id;
              let combo = str1.concat(str2);
              let markets = JSON.parse(localStorage.getItem(combo));
              let newMarkets = event.marketHelpers;
              for (let j = 0; j < newMarkets.length; j++) {
                let flag2 = false;
                for (let c = 0; c < markets.length; c++) {
                  if (markets[c].id == newMarkets[j].id) {
                    flag2 = true;
                    for (let k = 0; k < newMarkets[j].bets.length; k++) {
                      let flag = false;
                      for (let p = 0; p < markets[c].bets.length; p++) {
                        if (markets[c].bets[p].id == newMarkets[j].bets[k].id) {
                          flag = true;
                          markets[c].bets[p].odd = newMarkets[j].bets[k].odd;
                          markets[c].bets[p].flag = newMarkets[j].bets[k].flag;
                          markets[c].bets[p].status = newMarkets[j].bets[k].status;
                        }
                      }
                      if (flag == false) {
                        markets[c].bets.push(newMarkets[j].bets);
                      }

                    }
                  }

                }
                if (flag2 == false) {
                  markets.push(newMarkets[j]);
                }
              }
              localStorage.setItem(combo, JSON.stringify(markets));
              this.$root.$emit('updateLiveTable', 1);
              // this.$root.$emit('updateLiveTableMobile', 1);
              const eventIdToCheck = localStorage.getItem('EventInOtherBets');
              if (eventIdToCheck !== undefined && response.data[i].id == eventIdToCheck) {
                this.$root.$emit('popUpRefreshData', markets);
              }

            }

          }

        }).catch(error => {
          // this.events = [];
          // localStorage.setItem('liveEvents', this.events);
          console.log(error);
        })

      }, 6500)
    },

    loadLiveEvents() {
      this.limitDown = parseInt(this.limitUp);
      localStorage.setItem("loadedInt", this.limitDown + 8);
      this.limitUp = this.limitDown + 8;
      this.startLoading()
      this.$axios.post('getLiveEventsInitially2', {
        parentId: localStorage.getItem('currentSport'),
        limitDown: this.limitDown,
        limitUp: this.limitUp
      }).then(response => {
        let liveEvents = JSON.parse(localStorage.getItem('liveEvents'));
        for(let i = 0; i < response.data.length; i++){
          liveEvents.push(response.data[i])
        }
        this.events = liveEvents;

        localStorage.setItem('liveEvents', JSON.stringify(liveEvents))
        for (let i = 0; i < response.data.length; i++) {
          let str1 = 'markets';
          let str2 = response.data[i].id;
          let combo = str1.concat(str2);

          localStorage.setItem(combo, JSON.stringify(response.data[i].marketHelpers))
          this.$root.$emit('updateLiveTable', 1);
          // this.$refs.live.$forceUpdate();
          // this.$refs.lives.$forceUpdate();
          this.$root.$emit('updateLiveTableMobile', 1);
          this.componentKey5 += 1;
          // this.$root.$emit('popUpRefreshData', response.data);
          // this.$refs.popUpTable.$forceUpdate();
          // const eventIdToCheck = localStorage.getItem('EventInOtherBets');
          // if(eventIdToCheck !== undefined && response.data[i].id == eventIdToCheck){
          //   this.$root.$emit('popUpRefreshData', response.data);
          // }
        }
        this.closeLoading()


      }).catch(error => {
        this.closeLoading()
        this.events = [];
        localStorage.setItem('liveEvents', this.events);
        console.log(error);
      })
    },


    sortByTime(events) {
      if(events!=null)
      return events.sort((a, b) => (parseInt(a.time) * 60 < parseInt(b.time) * 60) ? 1 : ((parseInt(b.time) * 60 < parseInt(a.time) * 60) ? -1 : 0))
    },

    loadMarketsInitial: function (eventId) {
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);


        this.$root.$emit('updateLiveTable', 1);
        this.$root.$emit('updateLiveTableMobile', 1);
        // this.$root.$emit('popUpRefreshData', response.data);
        // this.$refs.popUpTable.$forceUpdate();
        localStorage.setItem(combo, JSON.stringify(response.data))
        const eventIdToCheck = localStorage.getItem('EventInOtherBets');
        if (eventIdToCheck !== undefined && eventId == eventIdToCheck) {
          this.$root.$emit('popUpRefreshData', response.data);
        }


      }).catch(error => {
        console.log(error);
      })
    },

    stopLiveThread() {
      clearInterval(this.liveInterval);
    },

    onResize() {
      this.isMobile = global.innerWidth < 600
    },

    getBetNumber: function (markets) {
      let count = 0;
      for (let i = 1; i < markets.length; i++) {
        for (let j = 0; j < markets[i].bets.length; j++) {
          count++;
        }
      }
      return count;
    },
    homeResult(res) {
      return res?.split(':')[0];
    },
    awayResult(res) {
      return res?.split(':')[1];
    },

    find(betName) {
      if (betName == '1X2' || ((betName.startsWith('1') || betName.startsWith('2')) && betName.length < 3)) {
        return false;
      }
      if (this.$te(this.spaces(betName))) {
        return true;
      }
      return false;
    },

    spaces(betName) {
      return betName.replaceAll(' ', '');
    },

    selected: function (betId) {

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))

      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId))
          return true
        else return false
      }

      return false
    },


    clickBet(eventId, marketId, betId, home, away, marketName, betName, betOdd) {

      var player = JSON.parse(localStorage.getItem('player'))
      if (player == null || player == undefined) {
        setTimeout(() => {
          this.$floatingAlert.info({
            title: this.$t('Note'),
            message: this.$t('betClick') + '<i class="fa fa-fw fa-grin-wink"></i>',
            timeVisible: 3000,
          })
        }, 1)
        return;
      }

      const clickedBets = JSON.parse(localStorage.getItem('betIds'))
      if (clickedBets != null && clickedBets != undefined) {
        if (clickedBets.includes(betId)) {
          this.$root.$emit('remotelyDeleteBet', eventId);
          return;
        }
      }

      this.$root.$emit('clickBet', {eventId, marketId, betId, home, away, marketName, betName, betOdd});
    },

    loadCurrentMarkets: function (eventId) {
      const markets = JSON.parse(localStorage.getItem('markets' + eventId));
      const sorted = ["Will Be An Own Goal?", "Will A Red Card Be Shown?", "Will A Penalty Be Awarded?", "Under/Over 1st Period - Away Team", "Under/Over 1st Period - Home Team", "To Win Both Halves",
        "To Score In Both Halves", "Both Teams To Score In Both Halves", "Both Teams To Score 2nd Half", "Away Team To Win From Behind", "Home Team To Win From Behind", "Away Team To Win Both Halves",
        "Home Team To Win Both Halves", "Home Team Number Of Goals In 1st Half", "Home Team Number Of Goals", "Away Team to Score 1st Half", "Home Team to Score 1st Half", "Away Team to Score", "Home Team to Score",
        "HT/FT", "First Team To Score", "1st Period Odd/Even", "1st Period Winner And Both Teams To Score", "1st Half 1X2 And Under/Over", "Under/Over And Both Teams To Score", "1X2 And Under/Over", "Under/Over - Away Team",
        "Under/Over - Home Team", "1X2 And Both Teams To Score", "Odd/Even", "Number of Goals", "Asian Handicap 1st Period", "Asian Handicap", "Double Chance 2nd Period", "Double Chance 1st Period", "2nd Period Winner",
        "Correct Score 1st Period", "Correct Score", "Under/Over 1st Period", "1st Period Winner", "Under/Over", "Both Teams To Score 1st Half", "Both Teams To Score", "Next Goal 1st Period", "Next Goal", "Remaining 1st Half",
        "Remaining Match", "Double Chance", "1X2"];
      if(markets!=null)
      markets.sort((a, b) => (sorted.indexOf(a.name) < sorted.indexOf(b.name)) ? 1 : ((sorted.indexOf(b.name) < sorted.indexOf(a.name)) ? -1 : 0))
      return markets;
    },





    //"Next Goal" id=59
    //"Under/Over" id=2
    getMarketByName: function (eventId, underOverName, nextGoalName) {

      const markets = JSON.parse(localStorage.getItem('markets' + eventId));

      if(markets === null || markets === undefined) return;

      const underOverMarket = markets.filter(function (market) {return market.name == underOverName;})[0];
      const nextGoalMarket = markets.filter(function (market) {return market.name == nextGoalName;})[0];

      if(underOverMarket !== undefined && nextGoalMarket !== undefined) {

        const underOverMarketBets = underOverMarket.bets.filter(function(bet) {return bet.market == 2});
        const nextGoalMarketBets = nextGoalMarket.bets;

        underOverMarketBets.sort((a, b) => (a.status > b.status) ? 1 : ((a.status < b.status) ? -1 :
            ((parseInt(a.name.split(" ")[1]) > parseInt(b.name.split(" ")[1])) ? 1 :
                ((parseInt(a.name.split(" ")[1]) < parseInt(b.name.split(" ")[1])) ? -1 : 0) )))

        nextGoalMarketBets.sort((a, b) => (a.status > b.status) ? 1 : ((a.status < b.status) ? -1 : 0 ))

        const nextGoalOne = nextGoalMarketBets.find(function(bet) {return bet.name.split(' ')[0] == '1'});
        const nextGoalNo = nextGoalMarketBets.find(function(bet) {return bet.name.split(' ')[0] == 'No'});
        const nextGoalTwo = nextGoalMarketBets.find(function(bet) {return bet.name.split(' ')[0] == '2'});


        const underOverBetValue = underOverMarketBets[0].name.split(" ")[1];
        const underItem = underOverMarketBets.find(function(bet) {return bet.name.split(' ')[1] == underOverBetValue && bet.name.split(' ')[0] == 'Under'});
        const overItem = underOverMarketBets.find(function(bet) {return bet.name.split(' ')[1] == underOverBetValue && bet.name.split(' ')[0] == 'Over'});
        return [nextGoalOne, nextGoalNo, nextGoalTwo, underOverBetValue, underItem, overItem];
      }

      else if(underOverMarket !== undefined) {


        const underOverMarketBets = underOverMarket.bets;
        underOverMarketBets.sort((a, b) => (a.status > b.status) ? 1 : ((a.status < b.status) ? -1 :
            ((parseInt(a.name.split(" ")[1]) > parseInt(b.name.split(" ")[1])) ? 1 :
                ((parseInt(a.name.split(" ")[1]) < parseInt(b.name.split(" ")[1])) ? -1 : 0) )))


        const underOverBetValue = underOverMarketBets[0].name.split(" ")[1];
        const underItem = underOverMarketBets.find(function(bet) {return bet.name.split(' ')[1] == underOverBetValue && bet.name.split(' ')[0] == 'Under'});
        const overItem = underOverMarketBets.find(function(bet) {return bet.name.split(' ')[1] == underOverBetValue && bet.name.split(' ')[0] == 'Over'});
        return [{market: 59, name: '1 goal', status: 2, flag: 0}, {market: 59, name: 'No goal', status: 2, flag: 0}, {market: 59, name: '2 goal', status: 2, flag: 0}, underOverBetValue, underItem, overItem];
      }

      else if(nextGoalMarket !== undefined){

        const nextGoalMarketBets = nextGoalMarket.bets;
        nextGoalMarketBets.sort((a, b) => (a.status > b.status) ? 1 : ((a.status < b.status) ? -1 : 0))

        const nextGoalOne = nextGoalMarketBets.find(function(bet) {return bet.name.split(' ')[0] == '1'});
        const nextGoalNo = nextGoalMarketBets.find(function(bet) {return bet.name.split(' ')[0] == 'No'});
        const nextGoalTwo = nextGoalMarketBets.find(function(bet) {return bet.name.split(' ')[0] == '2'});
        return [nextGoalOne, nextGoalNo, nextGoalTwo, '0.0', {market: 2, name: 'Under goal', status: 2, flag: 0}, {market: 2, name: 'Over goal', status: 2, flag: 0}];
      }
    },




    getUnderOverBetValue(underOverBet){
      return underOverBet.name.split(" ")[1];
    },




    getNextGoalMarket: function (eventId) {
      const markets = JSON.parse(localStorage.getItem('markets' + eventId));

      return markets;
    },

    loadWantedEvents: function () {
      return this.events
    },

    startLoading() {
      this.intervalThread = setInterval(() => {
        localStorage.setItem('dummy' + Math.random(), "[]");
      }, 450);
    },

    closeLoading() {
      Object.keys(localStorage).forEach(function (key) {
        if (key.includes('dummy')) {
          localStorage.removeItem(key)
        }
      });
      clearInterval(this.intervalThread);
    },

    toggleModal(eventId, event) {
      this.startLoading();
      this.$axios.post('loadBetsForEvent', {
        parentId: eventId
      }).then(response => {

        // let str1 = 'markets';
        // let str2 = eventId;
        // let combo = str1.concat(str2);


        // this.$root.$emit('updateLiveTable', 1);
        // this.$root.$emit('updateLiveTableMobile', 1);
        // // this.$root.$emit('popUpRefreshData', response.data);
        // // this.$refs.popUpTable.$forceUpdate();
        // localStorage.setItem(combo, JSON.stringify(response.data))
        // const eventIdToCheck = localStorage.getItem('EventInOtherBets');
        // if (eventIdToCheck !== undefined && eventId == eventIdToCheck) {
        //   this.$root.$emit('popUpRefreshData', response.data);
        // }
        localStorage.setItem('EventInOtherBets', eventId);
        let str1 = 'markets';
        let str2 = eventId;
        let combo = str1.concat(str2);
        localStorage.setItem(combo, JSON.stringify(response.data))
        const currMarks = response.data;
        this.$root.$emit('toggleModal', {currMarks, event});
        this.closeLoading();

      }).catch(error => {
        this.closeLoading();
        console.log(error);
      })


    },

    // async loadLiveEvents(name) {
    //   this.$axios.post('getLiveEvents', {
    //     parentId: name
    //   }).then(response => {
    //     localStorage.setItem('liveEvents', JSON.stringify(response.data))
    //     this.events = JSON.stringify(response.data);
    //   }).catch(error => {
    //     console.log(error);
    //   })
    // },

    loadEvents: function (id) {
      this.$axios.post('getEvents', {
        parentId: id
      }).then(response => {

        localStorage.setItem('events', JSON.stringify(response.data))
        this.eventss = response.data;
      }).catch(error => {
        console.log(error);
      })
    },
    loadMarkets: function (eventId) {
      this.$axios.post('getBets', {
        parentId: eventId
      }).then(response => {

        let str1 = "markets";
        let str2 = eventId;
        let combo = str1.concat(str2);

        localStorage.setItem(combo, JSON.stringify(response.data))

      }).catch(error => {
        console.log(error);
      })
    }
    ,
  },

  beforeMount() {
    // this.loadLiveEvents(localStorage.getItem('currentSport'));
  },


  mounted() {
    this.onResize()
    global.addEventListener('resize', this.onResize, {passive: true})

    // this.$root.$on('updateTicketsPop2', data => {
    //   this.dummy = data
    //   if (this.$refs.popUpTable != undefined && this.$refs.popUpTable != null) {
    //     this.$refs.popUpTable.$forceUpdate()
    //   }
    // });

    this.$root.$on('updateTicketsPop', data => {
      this.dummy = data
      if (this.$refs.popUpTable != undefined && this.$refs.popUpTable != null) {
        this.$refs.popUpTable.$forceUpdate();
        this.$root.$emit('updateTickets', 1);
      }
      // this.$root.$emit('updateTicketsPop2', 1);
    });

    localStorage.removeItem('EventInOtherBets')
    this.startLiveThread();

  }


}
</script>

<style scoped>

.my-custom-scrollbar {
  position: relative;
  /*height: 20vh;*/
  overflow: auto;
  overflow-x: hidden;
}

.table-wrapper-scroll-y {
  display: block;
}

/*thead{*/
/*  z-index: 1000;*/
/*  position: relative;*/
/*}*/

.firstrow {
  position: sticky;
  top: 0;
  /*background-image: linear-gradient(to right,#b82e1f, #eb5757, coral, lightcoral, navajowhite);*/
  background-color: #d0e9ff;
  z-index: 1000;
}

th {
  color: black;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #001523;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #111e26;
}

.blink_me_green {
  animation: blinker_green 1s linear;
}

@keyframes blinker_green {
  50% {
    /*opacity: 0;*/
    background-color: green;
  }
}

.blink_me_red {
  animation: blinker_red 1s linear;
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes blinker_red {
  50% {
    /*opacity: 0;*/
    background-color: red;
  }
}

</style>
